import React, { useState } from 'react';
import {
  Button, message, Row, Space,
} from 'antd';
import { CustomModal } from './InsufficientFundsModal';
import { axiosInstance } from '../../../utils/axios-instance';

type LeaveTournamentResponseData = {
  detail?: string;
};

type LeaveTournamentModalModalProps = {
  tournamentId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

export default function LeaveTournamentModal({
  tournamentId, onSuccess, onCancel,
}: LeaveTournamentModalModalProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const leaveTournament = () => {
    setLoading(true);
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/games/tournaments/${tournamentId}/leave_tournament/`,
    ).then(() => {
      setLoading(false);
      onSuccess();
    }).catch((e) => {
      const errorMessages = (e.response?.data as LeaveTournamentResponseData);
      if (errorMessages.detail) {
        message.error(errorMessages.detail);
      } else {
        message.error('Ocurrió un error, por favor intenta nuevamente');
      }
      setLoading(false);
      onCancel();
    });
  };
  return (
    <CustomModal
      open
      centered
      closable
      maskClosable={false}
      onOk={leaveTournament}
      onCancel={onCancel}
      mask={false}
      footer={null}
      primaryColor="#dee55d"
      secondaryColor="#b4ca34"
    >
      <div style={{
        paddingTop: 60,
        paddingBottom: 20,
      }}
      >
        <Row justify="center" style={{ marginBottom: 20 }}>
          ¿ESTAS SEGURO/A DE QUE TE QUIERES SALIR?
        </Row>
        <Row justify="center">
          <Space>
            <Button
              type="primary"
              danger
              shape="round"
              size="large"
              style={{ backgroundColor: '#df1d2a' }}
              onClick={() => {
                leaveTournament();
              }}
              loading={loading}
            >
              SALIR
            </Button>
            <Button
              type="primary"
              shape="round"
              size="large"
              style={{ backgroundColor: '#3b7d3b' }}
              onClick={() => {
                onCancel();
              }}
              disabled={loading}
            >
              SEGUIR JUGANDO
            </Button>
          </Space>
        </Row>
      </div>
    </CustomModal>
  );
}
