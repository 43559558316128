import styled from 'styled-components';
import React from 'react';
import logo from '../assets/logo.png';

const Container = styled.div`
  display: flex;
  background-color: var(--background-secondary);
  height: 100vh;
  width: 100vw;
`;

const Logo = styled.img`
  margin: auto;
  width: 180px;
`;

export default function Splash() {
  return (
    <Container>
      <Logo src={logo} />
    </Container>
  );
}
