import React, { useEffect } from 'react';
import {
  Col, Image, Row, Typography,
} from 'antd';
import CustomBackgroundModal, { ModalButton } from '../CustomBackgroundModal';
import orangeModalBg from '../../../../assets/modals/backgrounds/orange.png';
import orangeModalBgPortrait from '../../../../assets/modals/backgrounds/orange-mobile.png';
import purpleModalBg from '../../../../assets/modals/backgrounds/purple.png';
import purpleModalBgPortrait from '../../../../assets/modals/backgrounds/purple-mobile.png';
import titleA from '../../../../assets/modals/texts/uff-casi.png';
import titleB from '../../../../assets/modals/texts/pff-perdiste.png';
import angryBomb from '../../../../assets/bomb.png';
import { Tournament, TournamentParticipation, TournamentResult } from '../../../../types/base';

const { Title } = Typography;

type NoActionLoserModalProps = {
  tournament: Tournament;
  action: () => void;
};

function NoActionLoserModal({
  tournament,
  action,
}: NoActionLoserModalProps) {
  return (
    <CustomBackgroundModal
      onClose={action}
      backgroundImage={purpleModalBg}
      backgroundImagePortrait={purpleModalBgPortrait}
      className="the-number-loser-modal"
    >
      <Row justify="center" style={{ height: '100%' }}>
        <Col
          span={24}
          style={{
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Row justify="center">
            <Col span={24} style={{ marginTop: 10, marginBottom: 10 }}>
              <Image
                className="modal-title-img"
                preview={false}
                src={titleB}
              />
            </Col>
            {tournament?.result === TournamentResult.CLOSEST_NUMBER_WON ? (
              <Col span={24}>
                <Title
                  level={3}
                  style={{
                    color: 'white',
                    fontFamily: 'Pasti, serif',
                    fontWeight: 'normal',
                  }}
                >
                  {tournament?.result === TournamentResult.CLOSEST_NUMBER_WON ? (
                    <span>NO APRETASTE EL BOTÓN Y EL TIEMPO SE ACABÓ</span>
                  ) : (
                    <span>
                      LA BOMBA YA FUE DETONADA POR
                      {' '}
                      {
                        tournament.winners.reduce((accumulator, currentValue) => (
                          accumulator === '' ? `@${currentValue}` : `${accumulator}, @${currentValue}`
                        ), '')
                      }
                    </span>
                  )}
                </Title>
              </Col>
            ) : (
              <>
                <Col span={24}>
                  <Title
                    level={3}
                    style={{
                      color: 'white',
                      fontFamily: 'Pasti, serif',
                      fontWeight: 'normal',
                    }}
                  >
                    <span>LA BOMBA YA FUE DETONADA POR</span>
                  </Title>
                </Col>
                <Col span={24}>
                  <Title
                    level={1}
                    style={{
                      color: 'white',
                      fontFamily: 'Pasti, serif',
                      fontWeight: 'normal',
                      // fontSize
                    }}
                  >
                    <span>
                      {
                        tournament.winners.reduce((accumulator, currentValue) => (
                          accumulator === '' ? `@${currentValue}` : `${accumulator}, @${currentValue}`
                        ), '')
                      }
                    </span>
                  </Title>
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
          }}
        >
          <Row justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Image
                className="modal-img"
                preview={false}
                src={angryBomb}
              />
            </Col>
            <Col span={24}>
              <ModalButton
                type="primary"
                shape="round"
                size="large"
                backgroundColor="#f5ee30"
                color="#83301a"
                paddingHorizontal={20}
                onClick={action}
              >
                JUGAR DE NUEVO
              </ModalButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}

type LoserModalProps = {
  userParticipation?: TournamentParticipation;
  action: () => void;
};

function LoserModal({
  userParticipation, action,
}: LoserModalProps) {
  return (
    <CustomBackgroundModal
      onClose={action}
      backgroundImage={orangeModalBg}
      backgroundImagePortrait={orangeModalBgPortrait}
      className="the-number-loser-modal"
    >
      <Row justify="center" style={{ height: '100%' }}>
        <Col span={24}>
          <Row justify="center" gutter={[0, 8]}>
            <Col span={24}>
              <Image
                className="modal-title-img"
                preview={false}
                src={titleA}
              />
            </Col>
            <Col span={24}>
              <Title
                level={3}
                style={{
                  color: 'white',
                  fontFamily: 'Pasti, serif',
                  fontWeight: 'normal',
                }}
              >
                TE APURASTE! QUEDASTE EN EL NÚMERO
              </Title>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              color: 'white',
              fontFamily: 'Pasti, serif',
              fontWeight: 'normal',
            }}
            className="the-number-position"
          >
            {userParticipation?.properties.position}
          </span>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <ModalButton
            type="primary"
            shape="round"
            size="large"
            backgroundColor="#f5ee30"
            color="#83301a"
            paddingHorizontal={20}
            onClick={action}
          >
            JUGAR DE NUEVO
          </ModalButton>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}

type TheNumberLoserModalProps = {
  tournament: Tournament;
  userParticipation?: TournamentParticipation;
  action: () => void;
};

export default function TheNumberLoserModal({ ...props }: TheNumberLoserModalProps) {
  const { userParticipation, action } = props;

  useEffect(() => {
    // Automatically perform action after 30 seconds
    const timerId = setTimeout(() => {
      action();
    }, 30000);
    return () => clearTimeout(timerId);
  }, []);

  return userParticipation?.properties?.position ? (
    <LoserModal {...props} />
  ) : (
    <NoActionLoserModal {...props} />
  );
}
