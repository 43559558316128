import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 200000,
});

export const noAuthAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/o/token/`,
  timeout: 200000,
});
