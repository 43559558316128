import React, { createContext, useContext } from 'react';
import { IServerDateTimeContext, useServerDateTime } from './hooks/useServerDateTime';

const ServerDateTimeContext = createContext<IServerDateTimeContext>({
  serverTimeDifference: null,
  getDatetime: () => new Date(),
});
export const useServerDateTimeContext = () => useContext(ServerDateTimeContext);

type ServerDateTimeProviderProps = {
  children: JSX.Element;
};

export function ServerDateTimeProvider({ children }: ServerDateTimeProviderProps) {
  const serverDateTime = useServerDateTime();
  return (
    <ServerDateTimeContext.Provider value={serverDateTime}>
      {children}
    </ServerDateTimeContext.Provider>
  );
}
