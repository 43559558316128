import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row, Tooltip, Typography,
} from 'antd';
import moment from 'moment';
import Lottie from 'react-lottie';
import styled from 'styled-components';

import Loading from '../../components/Loading';
import { Match, MatchResult, Tournament } from '../../types/base';
import { CountdownTimer } from '../../components/games/CountdownTimer';
import button from '../../assets/animations/button.json';
import { PreCountdownTimer } from '../../components/games/PreCountdownTimer';
import { axiosInstance } from '../../utils/axios-instance';
import gradientImg from '../../assets/gradient.png';
import playersNumberContainer from '../../assets/players-number-container.png';
import tablePrizeContainer from '../../assets/table-prize-container.png';
import WaitingForOthersToPlayModal from '../../components/games/modals/WaitingForOthersToPlayModal';
import { menuHeight } from '../../constants/ui';
import { useServerDateTimeContext } from '../../ServerDateTimeProvider';
import explosion from '../../assets/sounds/explosion.mp3';
import { useSoundContext } from '../../components/SoundContext';

const { Title } = Typography;

const GameContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 10px 0;
  height: calc(100vh - ${menuHeight}px);

  @supports (height: 100svh) {
    height: calc(100svh - ${menuHeight}px);
  }

  @media only screen and (max-height: 700px) and (orientation: landscape) {
    overflow: auto;
    .ant-row {
      row-gap: 30px !important;
    }
  }

  @media only screen and (min-height: 800px) and (orientation: portrait) {
    .ant-row {
      row-gap: 30px !important;
    }
  }
  
  .centered-col {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .centered-col-xs-sm-md {
    @media only screen and (max-width: 991px) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 0 !important;
  
  @media only screen and (max-height: 700px) and (orientation: landscape) {
    top: 0;
    transform: translateX(-50%) translateY(0%);
  }

  @media only screen and (max-height: 630px) and (orientation: portrait) {
    row-gap: 10px !important;
  }
`;

const NumberContainer = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
  
  @media only screen and (max-height: 800px) and (orientation: portrait) {  
    width: 220px;
    height: 220px;
  }
  
  @media only screen and (max-height: 630px) and (orientation: portrait) {
    width: 190px;
    height: 190px;
  }
`;

const WinningNumber = styled.div`  
  font-family: "Pasti", serif;
  font-size: 20px;
  background-color: var(--white);
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  border-top: 3px solid var(--text-primary);
  border-bottom: 3px solid var(--text-primary);
  border-radius: 28px;
  line-height: 1.87;
  box-shadow: 0 24px 0 0 #81817c, 0 27px 0 0 var(--text-primary);
  padding-top: 30px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  h1.ant-typography {
    font-size: 200px;
    margin: 0;
  }

  .ant-row {
    row-gap: 0 !important;
  }
  
  @media only screen and (max-height: 800px) and (orientation: portrait) {
    font-size: 18px;
    padding-top: 20px;
    box-shadow: 0 20px 0 0 #81817c, 0 23px 0 0 var(--text-primary);
    
    .ant-row {
      row-gap: 0 !important;
    }
    
    h1.ant-typography {
      font-size: 150px;
    }
  }

  @media only screen and (max-height: 630px) and (orientation: portrait) {
    h1.ant-typography {
      font-size: 100px;
    }
  }
`;

const NumberShadow = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.8;
  mix-blend-mode: screen;
  width: 160%;
  height: 160%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const NumberOfPlayersContainer = styled.div`
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: transparent;
  background-image: url(${playersNumberContainer});
  color(--text-primary);
  
  span {
    width: 100px;
    height: 100px;
    font-size: 50px;
    line-height: 2.3;
    text-align: center;
    position: absolute;
  }
  
  @media only screen and (max-width: 768px) {
    width: 65px;
    height: 65px;
    span {
      width: 65px;
      height: 65px;
      font-size: 30px;
      line-height: 2.5;
      text-align: center;
      position: absolute;
    }
  }
`;

const TablePrize = styled.div`
  width: 160px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(${tablePrizeContainer});
  position: relative;

  span {
    width: 160px;
    height: 60px;
    font-size: 25px;
    color: var(--white);
    line-height: 3.1;
    text-align: center;
    position: absolute;
  }

  @media only screen and (max-width: 991px) {
    width: 80px;
    height: 40px;
    background: var(--primary-5);
    border: 2px solid var(--text-primary);
    border-radius: 28px;

    span {
      width: 80px;
      height: 40px;
      font-size: 20px;
      color: var(--white);
      line-height: 1.9;
      text-align: center;
      position: absolute;
    }
  }
`;

const AnimatedButton = styled(Button)`
  width: 200px; 
  height: 200px;
  margin: 0 auto;

  @media only screen and (max-width: 991px) and (orientation: portrait) {
    width: 150px;
    height: 150px;
  }
  
  @media only screen and (max-height: 630px) and (orientation: portrait) {
    width: 120px;
    height: 120px;
  }
`;

interface NumberProps {
  currentMatch: Match;
}

function Number({ currentMatch }: NumberProps) {
  return (
    <NumberContainer>
      <NumberShadow
        style={{
          backgroundImage: `url(${gradientImg})`,
        }}
      />
      <WinningNumber>
        <Row justify="center">
          <Col span={24}>
            Número
          </Col>
          <Col span={24}>
            <Title>
              {currentMatch.starts_at ? currentMatch.properties?.winning_number || '?' : '?'}
            </Title>
          </Col>
        </Row>
      </WinningNumber>
    </NumberContainer>
  );
}

interface PressTheButtonProps {
  tournament: Tournament;
  currentMatch?: Match;
  showInitialTimer: boolean;
}

export default function PressTheButton({
  tournament, currentMatch, showInitialTimer,
}: PressTheButtonProps) {
  const [isButtonPressed, setIsButtonPressed] = useState<boolean>(false);
  const { getDatetime } = useServerDateTimeContext();
  const { playSound, stopSound } = useSoundContext();

  useEffect(() => {
    stopSound('gameMusic');
  }, []);

  const pressButton = () => {
    if (
      currentMatch && !isButtonPressed
      && getDatetime() > (new Date(currentMatch?.starts_at))
    ) {
      axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/games/matches/${currentMatch.id}/press_the_button/`,
      ).then(() => {
        playSound('explosion', explosion);
        setIsButtonPressed(true);
      }).catch(() => {
        Modal.error({
          title: 'Ocurrió un error',
          content: 'Por favor intenta nuevamente.',
          centered: true,
        });
      });
    }
  };

  if (!tournament || !currentMatch) {
    return <Loading />;
  }

  return (
    showInitialTimer ? (
      <PreCountdownTimer target={moment(currentMatch.starts_at)} />
    ) : (
      <GameContainer>
        { isButtonPressed && currentMatch.result !== MatchResult.COMPLETED && (
          <WaitingForOthersToPlayModal />
        )}
        <StyledRow justify="center" align="top" gutter={[24, { xs: 48, xl: 72 }]}>
          <Col
            className="centered-col"
            xs={{ span: 24, offset: 0, order: 2 }}
            lg={{ span: 8, offset: 8, order: 1 }}
          >
            <Number currentMatch={currentMatch} />
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 8, order: 2 }}>
            <Row gutter={[4, 24]} align="middle">
              <Col
                className="centered-col-xs-sm-md"
                xs={{ span: 10, order: 2 }}
                md={{ span: 6, order: 2 }}
                lg={{ span: 24, order: 1 }}
              >
                <CountdownTimer
                  target={showInitialTimer ? null : moment(currentMatch.ends_at)}
                />
              </Col>
              <Col
                className="centered-col-xs-sm-md"
                xs={{ span: 7, order: 3 }}
                md={{ span: 6, order: 3 }}
                lg={{ span: 24, order: 2 }}
              >
                <Tooltip
                  placement="right"
                  color="rgba(231, 231, 23, 0.85)"
                  title="Participantes de la mesa"
                  autoAdjustOverflow
                >
                  <NumberOfPlayersContainer>
                    <span>{tournament.number_of_participants}</span>
                  </NumberOfPlayersContainer>
                </Tooltip>
              </Col>
              <Col
                className="centered-col-xs-sm-md"
                xs={{ span: 7, offset: 0, order: 1 }}
                md={{ span: 6, offset: 3, order: 1 }}
                lg={{ span: 24, offset: 0, order: 3 }}
              >
                <TablePrize>
                  <span>
                    $
                    {tournament.total_prize}
                  </span>
                </TablePrize>
              </Col>
            </Row>
          </Col>
          <Col className="centered-col" span={24} order={3}>
            <AnimatedButton
              type="link"
              onClick={pressButton}
            >
              <Lottie
                options={{
                  loop: false,
                  autoplay: false,
                  animationData: button,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                isClickToPauseDisabled
                isStopped={!isButtonPressed}
              />
            </AnimatedButton>
          </Col>
        </StyledRow>
      </GameContainer>
    )
  );
}
