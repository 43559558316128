import React from 'react';
import styled from 'styled-components';
import { menuHeight } from '../../constants/ui';
import background from '../../assets/background-ppt-1.png';

export const GameBackgroundDiv = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: calc(100vh - ${menuHeight}px);
  width: 100vw;
  overflow-y: auto;

  @supports (height: 100svh) {
    height: calc(100svh - ${menuHeight}px);
  }
`;

type GameBackgroundProps = {
  backgroundImage?: string;
  children?: JSX.Element;
};

export default function GameBackground({
  backgroundImage, children, ...props
}: GameBackgroundProps) {
  return (
    <GameBackgroundDiv
      style={{
        backgroundImage: `url(${backgroundImage || background})`,
      }}
      {...props}
    >
      {children}
    </GameBackgroundDiv>
  );
}
