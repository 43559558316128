import { useEffect, useState } from 'react';

import { axiosInstance, noAuthAxios } from '../utils/axios-instance';
import type { Token, User } from '../types/base';

type SignInData = {
  username: string;
  password: string;
};

export type IAuthContext = {
  user?: User | null;
  setUser: (user: User) => void;
  token?: Token | null;
  setToken: (token: Token) => void;
  signIn?: (data: SignInData) => Promise<Token>;
  signOut: () => void;
};

export const useAuth: () => IAuthContext = () => {
  function getUserData(): User | null {
    const userDataString = localStorage.getItem('userData');
    return userDataString ? JSON.parse(userDataString) : null;
  }

  function getToken(): Token | null {
    const tokenString = localStorage.getItem('token');
    return tokenString ? JSON.parse(tokenString) : null;
  }

  const [user, setUser] = useState<User | null>(getUserData());
  const [token, setToken] = useState<Token | null>(getToken());

  const saveToken = (userToken: Token) => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  const saveUser = (data: User) => {
    localStorage.setItem('userData', JSON.stringify(data));
    setUser(data);
  };

  const signIn = async (data: SignInData) => new Promise(
    (resolve: (token: Token) => void, reject) => {
      noAuthAxios.post(
        `${process.env.REACT_APP_API_URL}/jwt/create/`,
        data,
      ).then((response) => {
        const userToken: Token = response.data;
        saveToken(userToken);
        resolve(userToken);
      }).catch((e) => {
        reject(e);
      });
    },
  );

  const signOut = () => {
    setUser(null);
    setToken(null);
    localStorage.clear();
    window.location.href = process.env.REACT_APP_ROUTER_BASENAME || '/';
  };

  // Interceptor for expired access token
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (error.response?.status === 401 && token?.refresh) {
        const { refresh } = token;
        if (refresh) {
          const data = {
            refresh,
          };
          return noAuthAxios
            .post(
              `${process.env.REACT_APP_API_URL}/jwt/refresh/`,
              data,
            )
            .then((response) => response.data)
            .then((newToken: Token) => {
              const { access } = newToken;
              saveToken(newToken);
              return axiosInstance({
                ...prevRequest,
                headers: { ...prevRequest.headers, Authorization: `JWT ${access}` },
                sent: true,
              });
            })
            .catch((err) => {
              signOut();
              return Promise.reject(err);
            });
        }
        signOut();
      }

      return Promise.reject(error);
    },
  );

  useEffect(() => {
    if (token) {
      const { access } = token;
      // Update headers
      axiosInstance.defaults.headers.common.Authorization = `JWT ${access}`;
      // Fetch user data
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/users/me/`,
      ).then((response) => {
        saveUser(response.data);
      });
    }
  }, [token]);

  return {
    user, setUser, token, setToken: saveToken, signIn, signOut,
  };
};
