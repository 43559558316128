import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/es_ES';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './AuthProvider';
import { ServerDateTimeProvider } from './ServerDateTimeProvider';
import { SoundProvider } from './components/SoundContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const routerBasename = process.env.REACT_APP_ROUTER_BASENAME || '/';

root.render(
  <React.StrictMode>
    <BrowserRouter basename={routerBasename}>
      <AuthProvider>
        <ServerDateTimeProvider>
          <ConfigProvider locale={locale}>
            <SoundProvider>
              <App />
            </SoundProvider>
          </ConfigProvider>
        </ServerDateTimeProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
