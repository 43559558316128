import React, { useState } from 'react';
import {
  Form, Input, Button, message, Typography, Modal,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import { noAuthAxios } from '../../utils/axios-instance';
import { FormContainer } from './SignUp';

const { Text, Title } = Typography;

interface RequestPasswordResetFormValues {
  email: string;
}

function RequestPasswordReset() {
  const [loading, setLoading] = useState<boolean>(false);
  const [sentPasswordReset, setSentPasswordReset] = useState<boolean>(false);

  const onFinish = (values: RequestPasswordResetFormValues) => {
    setLoading(true);
    noAuthAxios.post(
      `${process.env.REACT_APP_API_URL}/users/reset_password/`,
      values,
    ).then(() => {
      setLoading(false);
      setSentPasswordReset(true);
    }).catch((e: AxiosError) => {
      if (e.response?.status === 400) {
        Modal.error({
          title: 'Correo electrónico inválido',
          content: 'El correo electrónico ingresado no está registrado.',
        });
      } else {
        message.error('Ocurrió un error, por favor intenta nuevamente.');
      }
      setLoading(false);
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <FormContainer>
        <Title level={4}>Restablecer contraseña</Title>
        {sentPasswordReset ? (
          <Text type="secondary">
            Te enviamos un correo con un enlace para restablecer tu contraseña.
          </Text>
        ) : (
          <>
            <Text type="secondary">
              Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu
              contraseña.
            </Text>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
              style={{ paddingTop: 18 }}
            >
              <Form.Item
                label="Correo electrónico"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Correo electrónico inválido',
                  },
                  {
                    required: true,
                    message: 'Por favor ingresa un correo electrónico',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item style={{ marginBottom: 8 }}>
                <Button type="primary" htmlType="submit" block>
                  {loading ? (
                    <LoadingOutlined />
                  ) : 'CONFIRMAR'}
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </FormContainer>
    </div>
  );
}

export default RequestPasswordReset;
