import React, { useEffect } from 'react';
import {
  Col, Image, Row, Typography,
} from 'antd';
import styled from 'styled-components';
import CustomBackgroundModal from '../CustomBackgroundModal';
import modalBackground from '../../../../assets/modals/backgrounds/purple.png';
import modalBackgroundPortrait from '../../../../assets/modals/backgrounds/purple-mobile.png';
import title from '../../../../assets/modals/texts/esto-es-increible.png';
import { TournamentParticipation } from '../../../../types/base';
import { PrizeContainer } from '../number/TheNumberWinnerModal';

const { Title } = Typography;

const TitleImage = styled(Image)`
  width: 60%;

  @media only screen and (orientation: portrait) {
    width: 100%;
  }

  @media only screen and (max-height: 576px) and (orientation: landscape) {
    width: 60%;
  }
`;

type PPTMatchModalProps = {
  userParticipation: TournamentParticipation;
  action: () => void;
};

export default function PPTDrawModal({
  userParticipation, action,
}: PPTMatchModalProps) {
  useEffect(() => {
    // Automatically perform action after 30 seconds
    const timerId = setTimeout(() => {
      action();
    }, 30000);
    return () => clearTimeout(timerId);
  }, []);

  return (
    <CustomBackgroundModal
      onClose={action}
      backgroundImage={modalBackground}
      backgroundImagePortrait={modalBackgroundPortrait}
    >
      <Row justify="center" gutter={[0, { xs: 24, md: 8 }]}>
        <Col span={24}>
          <TitleImage
            preview={false}
            src={title}
          />
        </Col>
        <Col
          span={24}
          style={{
            maxWidth: 420,
          }}
        >
          <Title
            level={3}
            style={{
              color: 'white',
            }}
          >
            <span>
              AMBOS SON DEMASIADO BUENOS Y EMPATARON
              {' '}
              {userParticipation.properties.latest_match_total_attempts}
              {' '}
              VECES
            </span>
          </Title>
        </Col>
        <Col span={24}>
          <Title
            level={5}
            style={{
              color: 'white',
            }}
          >
            <span>
              SE DIVIDE EL POZO ENTRE AMBOS
            </span>
          </Title>
        </Col>
        <Col span={24}>
          <PrizeContainer style={{ top: 0 }}>
            <span>
              $
              {userParticipation?.amount_earned}
            </span>
          </PrizeContainer>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}
