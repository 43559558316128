import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import {
  Avatar, Button, Col, Drawer, Image, Modal, Row, Space,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../../components/Loading';
import {
  Match,
  MatchParticipation,
  MatchResult,
  RockPaperScissorsOption,
  Tournament,
} from '../../types/base';
import closedHandLeft from '../../assets/animations/closed-hand-left.json';
import closedHandRight from '../../assets/animations/closed-hand-right.json';
import paperHandLeft from '../../assets/animations/paper-hand-left.json';
import paperHandRight from '../../assets/animations/paper-hand-right.json';
import rockHandLeft from '../../assets/animations/rock-hand-left.json';
import rockHandRight from '../../assets/animations/rock-hand-right.json';
import scissorsHandLeft from '../../assets/animations/scissors-hand-left.json';
import scissorsHandRight from '../../assets/animations/scissors-hand-right.json';
import bombImage from '../../assets/bomb.png';
import vsImage from '../../assets/vs.png';
import { DefaultAvatarImage, getDefaultAvatar } from '../account/Profile';
import { CountdownTimer } from '../../components/games/CountdownTimer';
import paper from '../../assets/animations/paper.json';
import rock from '../../assets/animations/rock.json';
import scissors from '../../assets/animations/scissors.json';
import { PreCountdownTimer } from '../../components/games/PreCountdownTimer';
import { axiosInstance } from '../../utils/axios-instance';
import { menuHeight, OptionImages } from '../../constants/ui';
import { TournamentBracket } from '../../components/games/TournamentBracket';
import useWindowSize from '../../hooks/useWindowSize';
import { useAuthContext } from '../../AuthProvider';
import PPTMatchWinnerModal from '../../components/games/modals/ppt/PPTMatchWinnerModal';
import PPTMatchDrawModal from '../../components/games/modals/ppt/PPTMatchDrawModal';
import RoundsHeader from '../../components/games/RoundsHeader';
import { useServerDateTimeContext } from '../../ServerDateTimeProvider';
import { useSoundContext } from '../../components/SoundContext';

function getUserOptionHandAnimation(option?: RockPaperScissorsOption | null): object {
  if (option === 'paper') {
    return paperHandLeft;
  } if (option === 'rock') {
    return rockHandLeft;
  } if (option === 'scissors') {
    return scissorsHandLeft;
  }
  return closedHandLeft;
}
function getOpponentOptionHandAnimation(option?: RockPaperScissorsOption | null): object {
  if (option === 'paper') {
    return paperHandRight;
  } if (option === 'rock') {
    return rockHandRight;
  } if (option === 'scissors') {
    return scissorsHandRight;
  }
  return closedHandRight;
}

const GameContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 15px 0;
  height: calc(100vh - ${menuHeight}px);

  @supports (height: 100svh) {
    height: calc(100svh - ${menuHeight}px);
  }
  
  .centered-col {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  .timer {
    @media only screen and (orientation: landscape) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @media only screen and (orientation: portrait) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

const ParticipantAvatar = styled(Avatar)`
  height: 90px;
  width: 90px;

  @media only screen and (max-width: 768px) {
    width: 50px;
    height: 50px;
  };

  @media only screen and (max-width: 992px) and (orientation: landscape) {
    width: 60px;
    height: 60px;
  };

  @media only screen and (max-height: 576px) and (orientation: landscape) {
    width: 50px;
    height: 50px;
  };
`;

const VsImage = styled.img`
  height: 80px;

  @media only screen and (max-width: 768px) {
    height: 50px;
  };
  
  @media only screen and (max-width: 992px) and (orientation: landscape) {
    height: 60px;
  };

  @media only screen and (max-height: 576px) and (orientation: landscape) {
    height: 50px;
  };
`;

interface BombProps {
  windowWidth: number;
  windowHeight: number;
}

const Bomb = styled.img<BombProps>`
  height: 40vh;
  width: 40vh;
  margin-top: 7vh;

  @media only screen and (min-width: 576px) and (orientation: portrait) {
    height: 28svh;
    width: 28svh;
    margin-top: 12svh;
  };

  @media only screen and (max-width: 576px) and (orientation: portrait) {
    height: 22svh;
    width: 22svh;
    margin-top: 16svh;
  };

  @media only screen and (max-height: 670px) and (orientation: portrait) {
    height: 22svh;
    width: 22svh;
    margin-top: 14svh;
  };

  @media only screen and (max-height: 700px) and (orientation: landscape) {
    margin-top: 8svh;
    height: 30svh;
    width: 30svh;
  };

  @media only screen and (max-height: 620px) and (orientation: landscape) {
    margin-top: 4svh;
    height: 22svh;
    width: 22svh;
  };
`;

const HandsContainer = styled.div`
  height: 100%;
  width: 100%;
`;

interface HandProps {
  side: 'left' | 'right';
}

const Hand = styled.div<HandProps>`
  height: auto;
  width: 40vw;
  position: absolute;
  top: 90px;
  left: ${(props) => (props.side === 'left' ? '0' : null)};
  right: ${(props) => (props.side === 'right' ? '0' : null)};
  z-index: 1;

  @media only screen and (orientation: portrait) {
    width: 50vw;
    top: 140px;
  }

  @media only screen and (max-width: 720px) and (orientation: portrait) {
    width: 60vw;
    top: 140px;
  }

  @media only screen and (max-height: 700px) and (orientation: landscape) {
    top: 40px;
  };

  @media only screen and (max-width: 992px) and (orientation: landscape) {
    max-width: 30%;
    top: 80px;
  };
`;

const GameButtonCol = styled(Col)`
  @media only screen and (max-width: 992px) and (orientation: landscape) {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
`;

const GameButton = styled(Button)`
  z-index: 2;
  width: auto;
  height: 130px;
  
  @media only screen and (max-width: 992px) and (orientation: landscape) {
    height: 80px
  };
  
  @media only screen and (max-height: 620px) and (orientation: landscape) {
    height: 70px
  };

  @media only screen and (max-width: 576px) and (orientation: portrait) {
    height: 100px
  };
`;

type AnimationStatus = {
  isStopped: boolean;
};

type AnimationButtonsStatus = {
  rock: AnimationStatus;
  paper: AnimationStatus;
  scissors: AnimationStatus;
};

const RoundInfoContainer = styled.div`
  height: 50px;
  width: 100%;
  font-family: "Pasti", serif;
  color: white;
  font-size: 20px;
  text-align: center;
  line-height: 50px;
`;

const initialStatus: AnimationButtonsStatus = {
  rock: {
    isStopped: true,
  },
  paper: {
    isStopped: true,
  },
  scissors: {
    isStopped: true,
  },
};

interface ParticipantProps {
  matchParticipation?: MatchParticipation;
  side: 'left' | 'right';
}

function Participant({ matchParticipation, side }: ParticipantProps) {
  const renderPastSelectedOptions = (pastSelectedOptions: RockPaperScissorsOption[] = []) => (
    <Space size={0}>
      {pastSelectedOptions.map((option, index) => (
        <Image
          key={`${matchParticipation?.id}-${index}`}
          preview={false}
          src={OptionImages[option]}
          width={40}
        />
      ))}
    </Space>
  );

  return (
    <Space direction="vertical" className={side === 'left' ? 'space-align-end' : 'space-align-start'} size={0}>
      <ParticipantAvatar
        src={matchParticipation?.user.profile_photo_url ? (
          <Image
            preview={false}
            src={matchParticipation?.user.profile_photo_url}
          />
        ) : (
          <DefaultAvatarImage
            src={getDefaultAvatar(matchParticipation?.user?.id)}
          />
        )}
        icon={<UserOutlined style={{ color: 'var(--text-secondary)' }} />}
        style={{ backgroundColor: 'var(--primary-4)' }}
      />

      <div style={{ minHeight: 40 }}>
        {renderPastSelectedOptions(matchParticipation?.past_selected_options)}
      </div>
    </Space>
  );
}

interface RockPaperScissorsProps {
  tournament: Tournament;
  currentMatch?: Match;
  showInitialTimer: boolean;
  showFinalAnimation: boolean;
  showMatchResults: boolean;
  remainingAttempts?: number;
}

export default function RockPaperScissors({
  tournament,
  currentMatch,
  showInitialTimer,
  showFinalAnimation,
  showMatchResults,
  remainingAttempts,
}: RockPaperScissorsProps) {
  const { windowWidth, windowHeight } = useWindowSize();
  const [animationsStatus, setAnimationsStatus] = useState<AnimationButtonsStatus>(initialStatus);
  const [userParticipation, setUserParticipation] = useState<MatchParticipation>();
  const [selectedOption, setSelectedOption] = useState<RockPaperScissorsOption>();
  const [showBracket, setShowBracket] = useState<boolean>(false);
  const [matchEnded, setMatchEnded] = useState<boolean>(false);
  const { user } = useAuthContext();
  const { getDatetime } = useServerDateTimeContext();
  const [opponentParticipation, setOpponentParticipation] = useState<MatchParticipation>();
  const { stopSound } = useSoundContext();

  const retrieveUserParticipation = (matchData: Match) => {
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/games/matches/${matchData.id}/participation/`,
    ).then((response) => {
      setUserParticipation(response.data);
      setSelectedOption(response.data.selected_option);
    }).catch(() => {
      Modal.error({
        title: 'Ha ocurrido un error',
        content: 'Por favor intenta refrescando la pagina.',
        centered: true,
      });
    });
  };

  useEffect(() => {
    let matchEndsAtTimeoutId: NodeJS.Timeout;
    if (currentMatch) {
      setOpponentParticipation(
        currentMatch?.match_participations.find((participation) => (
          participation.user.id !== user!.id
        )),
      );
      retrieveUserParticipation(currentMatch);

      if (currentMatch.ends_at) {
        const endsAt = new Date(currentMatch.ends_at);
        if (getDatetime() < endsAt) {
          setMatchEnded(false);
          const endTime = moment(currentMatch.ends_at);
          matchEndsAtTimeoutId = setTimeout(() => {
            setMatchEnded(true);
          }, moment.duration(endTime.diff(getDatetime())).asMilliseconds());
        } else {
          setMatchEnded(true);
        }
      } else {
        setMatchEnded(false);
      }
    }

    return () => clearTimeout(matchEndsAtTimeoutId);
  }, [currentMatch]);

  const startAnimation = (option: RockPaperScissorsOption) => {
    setAnimationsStatus({
      ...initialStatus,
      [option]: {
        ...animationsStatus[option],
        isStopped: false,
      },
      ...(selectedOption
        ? {
          [selectedOption]: {
            ...animationsStatus[selectedOption],
            isStopped: false,
          },
        } : {}),
    });
  };

  const stopAnimation = (option: RockPaperScissorsOption) => {
    if (option !== selectedOption) {
      setAnimationsStatus({
        ...animationsStatus,
        [option]: {
          ...animationsStatus[option],
          isStopped: true,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedOption) {
      startAnimation(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    stopSound('gameMusic');
  }, []);

  const selectOption = (option: RockPaperScissorsOption) => {
    if (
      currentMatch && selectedOption !== option
      && getDatetime() > (new Date(currentMatch?.starts_at))
    ) {
      axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/games/matches/${currentMatch.id}/select_paper_rock_scissors_option/`,
        {
          selected_option: option,
        },
      ).then((response) => {
        setUserParticipation(response.data);
        setSelectedOption(response.data.selected_option);
      }).catch(() => {
        stopAnimation(option);
        Modal.error({
          title: 'Ocurrió un error',
          content: 'Por favor intenta nuevamente.',
          centered: true,
        });
      });
    }
  };

  if (
    currentMatch?.result === MatchResult.COMPLETED
    && userParticipation?.is_winner
    && showMatchResults
  ) {
    return (
      <PPTMatchWinnerModal tournament={tournament} match={currentMatch} />
    );
  }

  if (!tournament || !currentMatch || !userParticipation) {
    return <Loading />;
  }

  return (
    <GameContainer>
      {
        currentMatch.result === MatchResult.REPEAT_MATCH
        && showMatchResults && remainingAttempts
        && (
          <PPTMatchDrawModal remainingAttempts={remainingAttempts} />
        )
      }
      {showInitialTimer && !showFinalAnimation && (
        <PreCountdownTimer
          target={moment(currentMatch.starts_at)}
        />
      )}

      {windowHeight < 576 && (
        <RoundsHeader tournament={tournament} match={currentMatch} />
      )}

      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 12, offset: 6 }}
          xl={{ span: 8, offset: 8 }}
          className="players"
        >
          <Row justify={{ xs: 'center', sm: 'space-between' }} align="top">
            <Col
              className="centered-col"
              xs={{ span: 8 }}
              sm={{ span: 8 }}
            >
              <Participant matchParticipation={userParticipation} side="left" />
            </Col>
            <Col
              className="centered-col"
              xs={{ span: 6 }}
              sm={{ span: 8 }}
            >
              <VsImage src={vsImage} />
            </Col>
            <Col
              className="centered-col"
              xs={{ span: 8 }}
              sm={{ span: 8 }}
            >
              <Participant matchParticipation={opponentParticipation} side="right" />
            </Col>
          </Row>
        </Col>
        <Col
          className="centered-col timer"
          xs={{ span: 24 }}
          sm={{ span: 6 }}
          xl={{ span: 8 }}
        >
          <CountdownTimer
            target={showInitialTimer ? null : moment(currentMatch.ends_at)}
            className="rock-paper-scissors-timer"
          />
        </Col>
      </Row>
      <Row>
        <Col
          className="centered-col"
          span={24}
        >
          <Bomb src={bombImage} windowWidth={windowWidth} windowHeight={windowHeight} />
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: 20 }}>
        <GameButtonCol
          className="centered-col"
          xs={{ span: 8 }}
          lg={{ span: 4 }}
          xxl={{ span: 3 }}
        >
          <GameButton
            type="link"
            onMouseEnter={() => startAnimation('rock')}
            onMouseLeave={() => stopAnimation('rock')}
            onClick={() => selectOption('rock')}
            className={
              selectedOption && selectedOption !== 'rock' ? 'darken' : 'lighten'
            }
          >
            <Lottie
              options={{
                loop: true,
                autoplay: false,
                animationData: rock,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              isClickToPauseDisabled
              isStopped={animationsStatus.rock.isStopped}
            />
          </GameButton>
        </GameButtonCol>
        <GameButtonCol
          className="centered-col"
          xs={{ span: 8 }}
          lg={{ span: 4 }}
          xxl={{ span: 3 }}
        >
          <GameButton
            type="link"
            onMouseEnter={() => startAnimation('paper')}
            onMouseLeave={() => stopAnimation('paper')}
            onClick={() => selectOption('paper')}
            className={
              selectedOption && selectedOption !== 'paper' ? 'darken' : undefined
            }
          >
            <Lottie
              options={{
                loop: true,
                autoplay: false,
                animationData: paper,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              isClickToPauseDisabled
              isStopped={animationsStatus.paper.isStopped}
            />
          </GameButton>
        </GameButtonCol>
        <GameButtonCol
          className="centered-col"
          xs={{ span: 8 }}
          lg={{ span: 4 }}
          xxl={{ span: 3 }}
        >
          <GameButton
            type="link"
            onMouseEnter={() => startAnimation('scissors')}
            onMouseLeave={() => stopAnimation('scissors')}
            onClick={() => selectOption('scissors')}
            className={
              selectedOption && selectedOption !== 'scissors' ? 'darken' : 'lighten'
            }
          >
            <Lottie
              options={{
                loop: true,
                autoplay: false,
                animationData: scissors,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              isClickToPauseDisabled
              isStopped={animationsStatus.scissors.isStopped}
            />
          </GameButton>
        </GameButtonCol>
      </Row>
      <HandsContainer>
        <Hand side="left">
          { showFinalAnimation ? (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: (
                  userParticipation?.selected_option
                    ? getUserOptionHandAnimation(userParticipation.selected_option)
                    : closedHandLeft
                ),
                rendererSettings: {
                  preserveAspectRatio: 'xMinYMin meet',
                },
              }}
              isClickToPauseDisabled
              width="auto"
              style={{ marginTop: -20 }}
              isStopped={!userParticipation?.selected_option}
            />
          ) : (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: (
                  showInitialTimer
                    ? closedHandLeft
                    : getUserOptionHandAnimation(userParticipation.selected_option)
                ),
                rendererSettings: {
                  preserveAspectRatio: 'xMinYMin meet',
                },
              }}
              isClickToPauseDisabled
              width="auto"
              style={{ marginTop: -20 }}
              isStopped={
                matchEnded || (!showInitialTimer && !userParticipation.selected_option)
              }
            />
          )}
        </Hand>
        <Hand side="right">
          { showFinalAnimation ? (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: (
                  getOpponentOptionHandAnimation(opponentParticipation?.selected_option)
                ),
                rendererSettings: {
                  preserveAspectRatio: 'xMinYMin meet',
                },
              }}
              isClickToPauseDisabled
              width="auto"
              style={{ marginTop: -20 }}
              isStopped={!opponentParticipation?.selected_option}
            />
          ) : (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: closedHandRight,
                rendererSettings: {
                  preserveAspectRatio: 'xMaxYMin meet',
                },
              }}
              isClickToPauseDisabled
              width="auto"
              style={{ marginTop: -20 }}
              isStopped={
                matchEnded
                || (!showInitialTimer && !opponentParticipation?.player_selected_option)
              }
            />
          )}
        </Hand>
      </HandsContainer>

      {!showInitialTimer && !showMatchResults && windowHeight >= 576 && (
        tournament.bracket && windowWidth > 1200 && windowHeight >= 650 ? (
          <Drawer
            placement="bottom"
            open={showBracket}
            forceRender
            closable
            onClose={() => setShowBracket(!showBracket)}
            closeIcon={
              showBracket
                ? <FontAwesomeIcon icon={faChevronDown} />
                : <FontAwesomeIcon icon={faChevronUp} />
            }
            className={showBracket ? 'open-drawer' : 'closed-drawer'}
            height={620}
            zIndex={2000}
            contentWrapperStyle={{ display: 'revert', transform: showBracket ? '' : 'translateY(calc(100% - 20px))' }}
          >
            <TournamentBracket tournament={tournament} />
          </Drawer>
        ) : (
          <Drawer
            placement="bottom"
            open
            closable={false}
            height={50}
            zIndex={2000}
            mask={false}
          >
            <RoundInfoContainer>
              <span>
                VAS EN LA
                {' '}
                {currentMatch.round}
                {' '}
                DE
                {' '}
                {tournament.rounds}
              </span>
            </RoundInfoContainer>
          </Drawer>
        )
      )}
    </GameContainer>
  );
}
