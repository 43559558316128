import React from 'react';
import {
  Col, Image, Row, Typography,
} from 'antd';
import styled from 'styled-components';
import CustomBackgroundModal from '../CustomBackgroundModal';
import greenModalBg from '../../../../assets/modals/backgrounds/green.png';
import greenModalBgPortrait from '../../../../assets/modals/backgrounds/green-mobile.png';
import redModalBg from '../../../../assets/modals/backgrounds/red.png';
import redModalBgPortrait from '../../../../assets/modals/backgrounds/red-mobile.png';
import titleDefault from '../../../../assets/modals/texts/dale-dale.png';
import titleFinalRounds from '../../../../assets/modals/texts/ganaste-esta-ronda.png';
import titleWinnerByDefault from '../../../../assets/modals/texts/parece-que-te-tinene-miedo.png';
import { Match, MatchStatus, Tournament } from '../../../../types/base';
import smilingBomb from '../../../../assets/smiling-bomb.png';

const { Title } = Typography;

const TitleDefaultRoundsImage = styled(Image)`
  width: 50%;

  @media only screen and (orientation: portrait) {
    width: 80%;
  }
`;

const TitleFinalRoundsImage = styled(Image)`
  width: 100%;

  @media only screen and (orientation: portrait) {
    width: 80%;
  }
`;

const FinalRoundsModalImage = styled(Image)`
  width: 100%;

  @media only screen and (orientation: portrait) {
    width: 60%;
  }
`;

const TitleCol = styled(Col)`
  @media only screen and (orientation: landscape) {
    flex: 0 0 70%;
    max-width: 70%;
  }
  @media only screen and (orientation: portrait) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const TitleImageCol = styled(Col)`
  @media only screen and (orientation: landscape) {
    flex: 0 0 30%;
    max-width: 30%;
  }
  @media only screen and (orientation: portrait) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

function PPTMatchWinnerDefaultModal() {
  return (
    <CustomBackgroundModal
      backgroundImage={greenModalBg}
      backgroundImagePortrait={greenModalBgPortrait}
      closable={false}
    >
      <Row justify="center" gutter={[0, { xs: 24, md: 8 }]}>
        <Col span={24}>
          <TitleDefaultRoundsImage
            preview={false}
            src={titleDefault}
          />
        </Col>
        <Col
          span={24}
          style={{
            marginBottom: 10,
          }}
        >
          <Image
            className="modal-img"
            preview={false}
            src={smilingBomb}
          />
        </Col>
        <Col>
          <Title
            level={3}
            style={{
              fontFamily: 'Pasti, serif',
            }}
          >
            PASASTE A LA SIGUIENTE RONDA
          </Title>
        </Col>
        <Col span={24}>
          <Title level={5}>
            ESPERANDO A TU PRÓXIMO RIVAL
          </Title>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}

function PPTMatchWinnerFinalRoundsModal() {
  return (
    <CustomBackgroundModal
      backgroundImage={greenModalBg}
      backgroundImagePortrait={greenModalBgPortrait}
      closable={false}
    >
      <Row justify="center" gutter={[0, { xs: 24, sm: 16 }]}>
        <Col xs={24} sm={19}>
          <Row align="bottom" gutter={16}>
            <TitleCol>
              <TitleFinalRoundsImage
                preview={false}
                src={titleFinalRounds}
              />
            </TitleCol>
            <TitleImageCol>
              <FinalRoundsModalImage
                preview={false}
                src={smilingBomb}
              />
            </TitleImageCol>
          </Row>
        </Col>
        <Col span={14} style={{ maxWidth: 300 }}>
          <Title
            level={3}
            style={{
              fontFamily: 'Pasti, serif',
              fontWeight: 'normal',
            }}
          >
            ESTÁS A UN PASO MÁS CERCA DE DETONAR LA BOMBA
          </Title>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}

function PPTMatchWinnerByDefaultRoundsModal() {
  return (
    <CustomBackgroundModal
      backgroundImage={redModalBg}
      backgroundImagePortrait={redModalBgPortrait}
      closable={false}
    >
      <Row justify="center" gutter={[0, { xs: 24, sm: 16 }]}>
        <Col xs={24} sm={19}>
          <Row align="bottom" gutter={16}>
            <TitleCol>
              <TitleFinalRoundsImage
                preview={false}
                src={titleWinnerByDefault}
              />
            </TitleCol>
            <TitleImageCol>
              <FinalRoundsModalImage
                preview={false}
                src={smilingBomb}
              />
            </TitleImageCol>
          </Row>
        </Col>
        <Col span={24}>
          <Title
            level={3}
            style={{
              fontFamily: 'Pasti, serif',
              fontWeight: 'normal',
              color: 'white',
              margin: 0,
            }}
          >
            ¡TU CONTRINCANTE SE HA SALIDO!
          </Title>
        </Col>
        <Col span={24} style={{ maxWidth: 300 }}>
          <Title level={5}>
            ESPERANDO A TU SIGUIENTE OPONENTE (A VER SI SE ATREVE)
          </Title>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}

type PPTMatchWinnerModalProps = {
  tournament: Tournament;
  match: Match;
};

export default function PPTMatchWinnerModal({
  tournament, match,
}: PPTMatchWinnerModalProps) {
  if (match?.properties.status === MatchStatus.WALKOVER) {
    return (
      <PPTMatchWinnerByDefaultRoundsModal />
    );
  }

  return match.round >= tournament.rounds - 2 ? (
    <PPTMatchWinnerFinalRoundsModal />
  ) : (
    <PPTMatchWinnerDefaultModal />
  );
}
