import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Menu } from 'antd';
import styled from 'styled-components';
import useWindowSize from '../../hooks/useWindowSize';
import Profile from './Profile';
import Loading from '../../components/Loading';
import Wallet from './Wallet';

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 650px;
  padding: 20px 50px;

  @media only screen and (max-width: 991px) and (min-width: 701px) {
    width: calc(100% - 2 * 140px);
  }

  @media only screen and (max-width: 700px) and (orientation: landscape) {
    width: calc(100% - 2 * 110px);
  }

  @media only screen and (max-width: 575px) {
    width: 100vw;
    padding: 20px 30px;
  }
`;

export default function Account() {
  const { windowWidth } = useWindowSize();
  const [selectedTab, setSelectedTab] = useState<string>('profile');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const currentTab = searchParams.get('tab');
    if (currentTab) {
      setSelectedTab(currentTab);
    } else {
      setSelectedTab('profile');
    }
  }, [searchParams]);

  return !windowWidth ? <Loading /> : (
    <div
      className="site-layout-content"
      style={{
        position: 'relative',
        maxWidth: 1000,
      }}
    >
      <Menu
        className="page-menu"
        selectedKeys={[selectedTab]}
        onClick={({ key }) => {
          setSelectedTab(key);
          setSearchParams({ ...searchParams, tab: key });
        }}
        mode={windowWidth && windowWidth < 701 ? 'horizontal' : 'vertical'}
        items={[
          { label: <div>PERFIL</div>, key: 'profile' },
          { label: <div>BILLETERA</div>, key: 'wallet' },
        ]}
      />
      <Content>
        {selectedTab === 'profile' && (
          <Profile />
        )}
        {selectedTab === 'wallet' && (
          <Wallet />
        )}
      </Content>
    </div>
  );
}
