import React from 'react';
import cancelledGameBg from '../../../assets/modals/backgrounds/cancelled-game.png';
import { SmallBackgroundModal } from './CustomBackgroundModal';

type CancelledMatchModalProps = {
  onClose: () => void;
};

export default function CancelledTournamentModal({ onClose }: CancelledMatchModalProps) {
  return (
    <SmallBackgroundModal
      onClose={onClose}
      backgroundImage={cancelledGameBg}
    />
  );
}
