import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  List,
  Modal,
  Row,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import styled from 'styled-components';
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  LoadingOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { RcFile } from 'rc-upload/es/interface';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';

import ImgCrop from 'antd-img-crop';
import { RuleObject } from 'rc-field-form/lib/interface';
import avatar1 from '../../assets/avatars/avatar-1.png';
import avatar2 from '../../assets/avatars/avatar-2.png';
import avatar3 from '../../assets/avatars/avatar-3.png';
import avatar4 from '../../assets/avatars/avatar-4.png';
import avatarUnknown from '../../assets/avatars/avatar-unknown.png';
import { axiosInstance } from '../../utils/axios-instance';
import validateFileSize from '../../utils/validateFileSize';
import Loading from '../../components/Loading';
import { useAuthContext } from '../../AuthProvider';
import { renderDate } from '../../utils/dates';

const { Paragraph, Text, Title } = Typography;

const Content = styled.div`
  @media only screen and (min-width: 992px) {
    margin: 0 50px;
  }
`;

export const DefaultAvatarImage = styled.img`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const AvatarContainer = styled.div`
  position: relative;
  margin-bottom: 40px;
  cursor: pointer;
`;

const EditButton = styled(Button)`
  position: absolute;
  bottom: 6px;
  right: 6px;
`;

export function getDefaultAvatar(userId?: number) {
  if (userId) {
    switch (userId % 4) {
      case 1:
        return avatar1;
      case 2:
        return avatar2;
      case 3:
        return avatar3;
      default:
        return avatar4;
    }
  } else {
    return avatarUnknown;
  }
}

export const handleUsernameValidation = async (
  _: RuleObject,
  username: string,
): Promise<boolean> => {
  let response;
  try {
    response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/users/check_username/?username=${username}`,
    );
  } catch (e) {
    return Promise.reject(new Error('Ocurrió un error al verificar el nombre de usuario, por favor intenta nuevamente'));
  }
  if (response.data.username) {
    return Promise.reject(new Error(response.data.username));
  }
  return Promise.resolve(true);
};

export const handleEmailValidation = async (
  _: RuleObject,
  email: string,
): Promise<boolean> => {
  let response;
  try {
    response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/users/check_email/?email=${email}`,
    );
  } catch (e) {
    return Promise.reject(new Error('Ocurrió un error al verificar el correo, por favor intenta nuevamente'));
  }
  if (response.data.email) {
    return Promise.reject(new Error(response.data.email));
  }
  return Promise.resolve(true);
};

interface UserFormValues {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

function Profile() {
  const [form] = Form.useForm();
  const { user, setUser, signOut } = useAuthContext();
  const [saving, setSaving] = useState<boolean>(false);
  const [showProfilePhotoOptions, setShowProfilePhotoOptions] = useState<boolean>(false);
  const [uploadingPhoto, setUploadingPhoto] = useState<boolean>(false);
  const [deletingPhoto, setDeletingPhoto] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deactivatingAccount, setDeactivatingAccount] = useState<boolean>(false);
  const [emailVerificationStatusIcon, setEmailVerificationStatusIcon] = useState<JSX.Element>();
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState<boolean>(false);
  const [sendingEmailVerification, setSendingEmailVerification] = useState<boolean>(false);

  const onUpdate = (values: UserFormValues) => {
    setSaving(true);
    axiosInstance.put(
      `${process.env.REACT_APP_API_URL}/users/me/`,
      values,
    ).then((response) => {
      setSaving(false);
      setUser(response.data);
      message.success('Se guardaron los cambios');
    }).catch(() => {
      message.error('Ocurrió un error, por favor intenta nuevamente.');
      setSaving(false);
    });
  };

  const uploadProfilePhoto = ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
    setUploadingPhoto(true);
    const filename = (file as RcFile)?.name;
    const formData = new FormData();
    formData.append('file', file, filename);
    formData.append('name', filename || 'file');
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/users/me/upload_profile_photo/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    ).then((response) => {
      setUser(response.data);
      if (onSuccess) {
        onSuccess(response.data);
      }
      setShowProfilePhotoOptions(false);
      setUploadingPhoto(false);
    }).catch((e) => {
      if (onError) {
        onError(e);
      }
    });
  };

  const deleteProfilePhoto = () => {
    setDeletingPhoto(true);
    axiosInstance.delete(
      `${process.env.REACT_APP_API_URL}/users/me/delete_profile_photo/`,
    ).then((response) => {
      setUser(response.data);
      setShowProfilePhotoOptions(false);
      setDeletingPhoto(false);
    });
  };

  const deleteAccount = () => {
    if (user!.wallet.balance !== 0) {
      message.error('El balance en su billetera debe ser cero. Retire su saldo e intente nuevamente.');
    } else {
      setDeactivatingAccount(true);
      axiosInstance.post(`${process.env.REACT_APP_API_URL}/users/me/deactivate/`).then(() => {
        signOut();
      }).catch(() => {
        setDeactivatingAccount(false);
        message.error('Ocurrió un error, por favor intenta nuevamente.');
      });
    }
  };

  const resendEmailVerificationMessage = () => {
    setSendingEmailVerification(true);
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/users/me/resend_email_verification_message/`,
    ).then(() => {
      setSendingEmailVerification(false);
      setShowEmailVerificationModal(false);
      message.success('Se envió el correo de verificación');
    }).catch(() => {
      setSendingEmailVerification(false);
      message.error('Ocurrió un error, por favor intenta nuevamente.');
    });
  };

  const updateEmailVerificationStatusIcon = (currentValue?: string) => {
    if (!currentValue || currentValue === user!.email) {
      if (user!.email_verified) {
        setEmailVerificationStatusIcon(
          <Tooltip
            placement="right"
            title="Verificado"
            color="#52c41a"
          >
            <CheckCircleFilled
              style={{ color: '#52c41a', padding: 0 }}
            />
          </Tooltip>,
        );
      } else {
        setEmailVerificationStatusIcon(
          <ExclamationCircleFilled
            onClick={() => setShowEmailVerificationModal(true)}
            style={{ color: '#faad14', padding: 0 }}
          />,
        );
      }
    } else {
      setEmailVerificationStatusIcon(undefined);
    }
  };

  useEffect(() => {
    updateEmailVerificationStatusIcon();
  }, [user]);

  return !user ? <Loading height="60px" /> : (
    <Content>
      <Form
        form={form}
        initialValues={user}
        onFinish={onUpdate}
        size="small"
      >
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
          <Row justify="center">
            <Col>
              <AvatarContainer onClick={() => setShowProfilePhotoOptions(true)}>
                <Avatar
                  size={120}
                  src={user?.profile_photo_url ? (
                    <Image
                      preview={false}
                      src={user?.profile_photo_url}
                    />
                  ) : <DefaultAvatarImage src={getDefaultAvatar(user.id)} />}
                />
                <EditButton shape="circle" icon={<FontAwesomeIcon icon={faPen} />} />
              </AvatarContainer>
            </Col>
          </Row>

          <Row gutter={[8, 32]} align="top">
            <Col span={10}>
              <Text strong>Nombre de usuario:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="username"
                style={{ margin: 0 }}
                rules={[
                  {
                    validator: handleUsernameValidation,
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input autoCapitalize="off" />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Text strong>Nombre:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="first_name"
                style={{ margin: 0 }}
              >
                <Input placeholder="Ingresa un nombre" />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Text strong>Apellidos:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="last_name"
                style={{ margin: 0 }}
              >
                <Input placeholder="Ingresa un apellido" />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Text strong>Correo electrónico:</Text>
            </Col>
            <Col span={14}>
              <Form.Item
                name="email"
                style={{ margin: 0 }}
                rules={[
                  {
                    validator: handleEmailValidation,
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input
                  placeholder="Ingresa un correo"
                  autoCapitalize="off"
                  type="email"
                  suffix={emailVerificationStatusIcon}
                  onBlur={(e) => updateEmailVerificationStatusIcon(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Text strong>Fecha de nacimiento:</Text>
            </Col>
            <Col span={14}>
              <span className="ant-form-text">
                {user.birth_date ? renderDate(user.birth_date) : '-'}
              </span>
            </Col>

            <Col span={24}>
              <Button type="primary" htmlType="submit" block>
                {saving ? (
                  <LoadingOutlined />
                ) : 'GUARDAR CAMBIOS'}
              </Button>
            </Col>

            <Col span={24}>
              <Button
                type="primary"
                style={{ backgroundColor: '#b4262d' }}
                onClick={() => {
                  setShowDeleteModal(true);
                }}
                block
              >
                {saving ? (
                  <LoadingOutlined />
                ) : 'ELIMINAR CUENTA'}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <Modal
        open={showProfilePhotoOptions}
        footer={null}
        onCancel={() => setShowProfilePhotoOptions(false)}
      >
        <List
          dataSource={[
            <ImgCrop rotate>
              <Upload
                accept="image/*"
                customRequest={uploadProfilePhoto}
                maxCount={1}
                showUploadList={false}
                beforeUpload={validateFileSize}
              >
                <Button type="link" block style={{ color: 'var(--text-ok)' }}>
                  Subir foto
                  {' '}
                  {uploadingPhoto && <LoadingOutlined />}
                </Button>
              </Upload>
            </ImgCrop>,
            <Button danger type="link" block disabled={!user.profile_photo_url} onClick={deleteProfilePhoto}>
              Eliminar foto actual
              {' '}
              {deletingPhoto && <LoadingOutlined />}
            </Button>,
            <Button type="link" block style={{ color: 'var(--text-primary)' }} onClick={() => setShowProfilePhotoOptions(false)}>
              Cancelar
            </Button>,
          ]}
          renderItem={(item) => (
            <List.Item>
              {item}
            </List.Item>
          )}
        />
      </Modal>

      <Modal
        open={showDeleteModal}
        footer={null}
        onCancel={() => setShowDeleteModal(false)}
        width={400}
      >
        <div style={{ margin: 10 }}>
          <Row gutter={[8, 8]} align="middle">
            <Col span={24}>
              <Title level={5}>
                Eliminar Cuenta
              </Title>
            </Col>
            <Col span={24}>
              <Paragraph>
                Al Presionar &ldquo;ELIMINAR CUENTA&rdquo; se eliminará
                su cuenta y no podrá volver acceder a ella.
              </Paragraph>
              <Paragraph>¿Está seguro de eliminar su cuenta?</Paragraph>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                onClick={() => deleteAccount()}
                style={{ backgroundColor: '#b4262d' }}
                loading={deactivatingAccount}
                block
              >
                ELIMINAR CUENTA
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={showEmailVerificationModal}
        onCancel={() => setShowEmailVerificationModal(false)}
        footer={[
          <Button
            type="primary"
            loading={sendingEmailVerification}
            onClick={resendEmailVerificationMessage}
          >
            Reenviar correo de confirmación
          </Button>,
        ]}
      >
        <Title level={5} style={{ paddingBottom: 15 }}>
          El correo electrónico no ha sido verificado
        </Title>
        <Text>
          Te enviamos un correo electrónico para confirmar que la dirección de correo que
          ingresaste es correcta. Si no recibiste el correo o ya expiró haz clic en &quot;Reenviar
          correo de confirmación&quot; para que te lo enviemos nuevamente.
        </Text>
      </Modal>
    </Content>
  );
}

export default Profile;
