import rockImage from '../assets/rock.png';
import paperImage from '../assets/paper.png';
import scissorsImage from '../assets/scissors.png';

export const menuHeight = 90;

export const OptionImages = {
  rock: rockImage,
  paper: paperImage,
  scissors: scissorsImage,
};
