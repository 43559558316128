import React from 'react';
import { Modal } from 'antd';
import {
  CloseCircleOutlined,
} from '@ant-design/icons';

/**
 * Muestra un modal de error o confirmación para informar al usuario sobre la desconexión.
 *
 * @param {string} [okText] - Texto para el botón de confirmación (opcional).
 * @param {string} [cancelText] - Texto para el botón de cancelación (opcional).
 * @param {Function} [onOk] - Función al hacer clic en el botón de confirmación (opcional).
 * @param {Function} [onCancel] - Función al hacer clic en el botón de cancelación (opcional).
 */
export function showDataFetchError(
  okText?: string,
  cancelText?: string,
  onOk?: () => void,
  onCancel?: () => void,
) {
  if (cancelText && onCancel) {
    Modal.confirm({
      title: '¡Oops! Tu sesión se desconectó.',
      content: 'Para tu seguridad, cerramos la sesión después de un tiempo de inactividad. Vuelve a iniciar sesión y sigue jugando. '
          + '¡Gracias por tu comprensión y esperamos verte pronto de nuevo!',
      okText,
      cancelText: cancelText || null,
      onOk,
      onCancel,
      icon: <CloseCircleOutlined style={{ width: '1em', color: '#ff4d4f' }} />,
    });
  } else {
    Modal.error({
      title: '¡Oops! Tu sesión se desconectó.',
      content: 'Para tu seguridad, cerramos la sesión después de un tiempo de inactividad. Vuelve a iniciar sesión y sigue jugando. '
              + '¡Gracias por tu comprensión y esperamos verte pronto de nuevo!',
      okText,
      onOk,
    });
  }
}
