import React, { useEffect, useState } from 'react';
import {
  Avatar, Image, message, Table, Typography,
} from 'antd';
import styled from 'styled-components';
import { UserOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table/interface';
import useWindowSize from '../../hooks/useWindowSize';
import Loading from '../../components/Loading';
import { axiosInstance } from '../../utils/axios-instance';
import { GameStats, UserRank } from '../../types/base';
import { menuHeight } from '../../constants/ui';
import { DefaultAvatarImage, getDefaultAvatar } from '../account/Profile';
import { useAuthContext } from '../../AuthProvider';

const { Paragraph, Text, Title } = Typography;

const HighlightedTitle = styled(Title)`
  background: #efda7e;
  padding: 4px 10px;
  margin-bottom: 20px !important;
`;

const CustomLayout = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const CustomHeader = styled.div`
  flex: 0 0 auto;
  text-align: center;
  padding: 10px;
`;

const CustomContent = styled.div`
  flex: 1 1 auto;
`;

const DividedRow = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;

  @media only screen and (min-width : 577px) {
    > * {
      &:first-child {
        border-right: 2px solid black;
        padding-right: 40px;
      }
    }

    > * {
      &:last-child {
        border-left: 2px solid black;
        padding-left: 40px;
      }
    }
  }

  @media only screen and (max-width : 576px) {
    flex-direction: column;
    > * {
      &:first-child {
        border-bottom: 2px solid black;
        padding-bottom: 40px;
      }
    }
    > * {
      &:last-child {
        border-top: 2px solid black;
        padding-top: 40px;
      }
    }
  }
`;

const Side = styled.div`
  flex: 1;
`;

export default function Ranking() {
  const { windowHeight } = useWindowSize();
  const { user } = useAuthContext();
  const [userRank, setUserRank] = useState<UserRank>();
  const [top10Ranks, setTop10Ranks] = useState<UserRank[]>([]);

  useEffect(() => {
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/users/me/my_rank/`,
    ).then((myRankResponse) => {
      setUserRank(myRankResponse.data);
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/users/user_ranks/`,
      ).then((top10RanksResponse) => {
        setTop10Ranks(top10RanksResponse.data);
      }).catch(() => {
        message.error('Ocurrió un error, por favor intenta nuevamente.');
      });
    }).catch(() => {
      message.error('Ocurrió un error, por favor intenta nuevamente.');
    });
  }, []);

  const userStatsColumns: ColumnType<GameStats>[] = [
    {
      title: 'category',
      dataIndex: 'category',
      key: 'category',
      render: (value: string) => value.toUpperCase(),
    },
    {
      dataIndex: 'played',
      key: 'played',
      align: 'center',
    },
    {
      title: 'won_title',
      dataIndex: 'category',
      key: 'won_title',
      render: (value: string) => (value === 'Total jugado' ? 'Ganado' : ''),
    },
    {
      title: 'won',
      dataIndex: 'won',
      key: 'won',
      align: 'center',
    },
  ];

  const rankingColumns: ColumnType<UserRank>[] = [
    {
      title: 'profile_photo',
      dataIndex: ['user', 'profile_photo_url'],
      key: 'profile_photo',
      render: (profilePhotoUrl: string, record: UserRank) => (
        <Avatar
          src={profilePhotoUrl ? (
            <Image
              preview={false}
              src={profilePhotoUrl}
            />
          ) : <DefaultAvatarImage src={getDefaultAvatar(record.user.id)} />}
          icon={<UserOutlined style={{ color: 'var(--text-secondary)' }} />}
          style={{ backgroundColor: 'var(--primary-4)' }}
          size={18}
        />
      ),
    },
    {
      title: 'position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'username',
      dataIndex: ['user', 'username'],
      key: 'username',
    },
    {
      title: 'score',
      dataIndex: 'score',
      key: 'score',
      render: (value: number) => `${value} pts`,
    },
    {
      title: 'tournaments_played',
      dataIndex: ['user', 'game_stats'],
      key: 'tournaments_played',
      render: (value: GameStats[]) => `${
        value.find((stats) => stats.category === 'Total jugado')?.played || 0
      } competencias jugadas`,
    },
  ];

  return !userRank || !user ? <Loading /> : (
    <div
      className="site-layout-content"
      style={{
        position: 'relative',
        height: windowHeight - menuHeight,
      }}
    >
      <CustomLayout>
        <CustomHeader>
          <Title level={4}>RANKING</Title>
          <Paragraph style={{ marginBottom: 0 }}>
            Tus puntos:
            {' '}
            {userRank.score}
            {' '}
            pts
          </Paragraph>
          <Paragraph>
            Tu posición:
            {' '}
            {userRank.position}
          </Paragraph>
        </CustomHeader>
        <CustomContent>
          <DividedRow>
            <Side className="left">
              <HighlightedTitle level={5}>RANKING PERSONAL</HighlightedTitle>
              <Table
                className="user-stats"
                size="small"
                showHeader={false}
                pagination={false}
                columns={userStatsColumns}
                dataSource={user.game_stats}
              />
            </Side>
            <Side>
              <HighlightedTitle level={5}>RANKING CASHBOOM</HighlightedTitle>
              <Text>TOP 10 DE JUGADORES</Text>
              <Table
                className="ranking-table"
                size="small"
                showHeader={false}
                pagination={false}
                columns={rankingColumns}
                dataSource={top10Ranks}
                scroll={{ x: '100%' }}
              />
            </Side>
          </DividedRow>
        </CustomContent>
      </CustomLayout>
    </div>
  );
}
