import React, { createContext, useContext } from 'react';
import type { IAuthContext } from './hooks/useAuth';
import { useAuth } from './hooks/useAuth';

const AuthContext = createContext<IAuthContext>({
  setUser: () => {},
  setToken: () => {},
  signOut: () => {},
});
export const useAuthContext = () => useContext(AuthContext);

type AuthProviderProps = {
  children: JSX.Element;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const auth = useAuth();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}
