import React, { useEffect, useRef, useState } from 'react';
import {
  List, Menu,
} from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import backgroundPPT from '../../assets/background-ppt-1.png';
import backgroundTheNumber from '../../assets/background-thenumber.png';
import headerPPT from '../../assets/header-ppt.png';
import headerTheNumber from '../../assets/header-thenumber.png';
import Loading from '../../components/Loading';
import GameBackground from '../../components/games/GameBackground';
import GameHeader from '../../components/games/GameHeader';
import TableSelector, { TableGroup } from '../../components/games/TableSelector';
import useWindowSize from '../../hooks/useWindowSize';
import {
  Game,
  GameTable, Tournament,
} from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { showDataFetchError } from '../../utils/showDataFetchError';
import { useAuthContext } from '../../AuthProvider';

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 0;
  width: 500px;

  @media only screen and (max-width: 991px) and (min-width: 576px) {
    width: calc(100% - 2 * 140px);
  }

  @media only screen and (max-width: 575px) {
    width: 100vw;
  }

  .ant-list-grid .ant-col > .ant-list-item {
    margin-bottom: 0 !important;
  }
`;

export default function Lobby() {
  const navigate = useNavigate();
  const { windowWidth, windowHeight } = useWindowSize();
  const { gameCode } = useParams();
  const [game, setGame] = useState<Game>();
  const [tableGroups, setTableGroups] = useState<TableGroup[]>();
  const { token } = useAuthContext();
  const ws = useRef<WebSocket>();
  const [connected, setConnected] = useState<boolean>(false);
  const [showConnectionError, setShowConnectionError] = useState(false);

  useEffect(() => {
    if (showConnectionError) {
      showDataFetchError(
        'Reintentar',
        'Volver al inicio',
        () => {
          setShowConnectionError(false);
          window.location.reload();
        },
        () => navigate('/', { replace: true }),
      );
    }
  }, [showConnectionError]);

  const onUpdateGameData = (gameData: Game) => {
    setGame(gameData);
    setTableGroups(_.reduce(
      _.groupBy(gameData.tables, (table: GameTable) => table.entry_fee),
      (result: TableGroup[], value: GameTable[], key: string) => {
        if (value.length > 0) {
          return [
            ...result,
            {
              entry_fee: Number(key),
              tables: value,
            },
          ];
        }
        return result;
      },
      [],
    ));
  };

  const onWSMessage = (event: MessageEvent) => {
    const json = JSON.parse(event.data);
    if (json.action === 'subscribe_to_tournament_participation') {
      if (json.response_status === 200 && json.data) {
        onUpdateGameData(json.data);
      } else {
        setShowConnectionError(true);
      }
    }
  };

  useEffect(() => {
    const webSocket = new WebSocket(
      `${process.env.REACT_APP_WS_API_URL}/games/?token=${token!.access}`,
    );

    if (!connected && webSocket) {
      webSocket.onopen = () => {
        webSocket!.send(JSON.stringify({
          action: 'subscribe_to_tournament_participation',
          code: gameCode,
          request_id: new Date().getTime(),
        }));
        webSocket!.onclose = () => {
          window.location.reload();
        };
      };
      webSocket.onmessage = onWSMessage;
      webSocket.onerror = () => {
        setShowConnectionError(true);
      };
      setConnected(true);
    }
    ws.current = webSocket;

    setTimeout(() => {
      // If stuck on connecting state
      if (ws.current?.readyState === WebSocket.CONNECTING) {
        setShowConnectionError(true);
      }
    }, 3000);

    return () => {
      if (ws.current?.readyState === WebSocket.OPEN) {
        ws.current?.close();
      }
    };
  }, [gameCode, token]);

  useEffect(() => {
    if (gameCode && !game) {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/games/${gameCode}/`,
      ).then((response) => {
        onUpdateGameData(response.data);
      }).catch(() => {
        setShowConnectionError(true);
      });
    }
  }, [gameCode]);

  const onJoinTournament = (tournament: Tournament) => {
    navigate(`/games/${gameCode}/play/${tournament.id}`);
  };

  if (showConnectionError) {
    return (
      <GameBackground backgroundImage={gameCode === 'PPT' ? backgroundPPT : backgroundTheNumber} />
    );
  }

  return !windowWidth || !windowHeight || !game || !tableGroups ? <Loading /> : (
    <GameBackground backgroundImage={gameCode === 'PPT' ? backgroundPPT : backgroundTheNumber}>
      <div
        className="site-layout-content"
        style={{
          position: 'relative',
          maxWidth: 950,
        }}
      >
        <Menu
          className="page-menu"
          selectedKeys={[]}
          onClick={({ key }) => {
            if (key === 'wallet') {
              navigate('/account?tab=wallet');
            }
          }}
          mode={windowWidth && windowWidth < 576 ? 'horizontal' : 'vertical'}
          items={[
            { label: <div>BILLETERA</div>, key: 'wallet' },
            { label: <div>CÓMO FUNCIONA</div>, key: 'howToPlay' },
          ]}
        />
        {windowWidth && windowWidth >= 576 && (
          <GameHeader headerImage={gameCode === 'PPT' ? headerPPT : headerTheNumber} />
        )}
        <Content>
          <List
            grid={{ column: 1 }}
            dataSource={tableGroups}
            renderItem={(tableGroup) => (
              <List.Item>
                <TableSelector
                  tableGroup={tableGroup}
                  onJoinTournament={onJoinTournament}
                />
              </List.Item>
            )}
          />
        </Content>
      </div>
    </GameBackground>
  );
}
