import React, { useEffect } from 'react';
import { message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../../components/Loading';
import { axiosInstance } from '../../utils/axios-instance';
import { fetchUserData } from '../../hooks/useUserData';
import { useAuthContext } from '../../AuthProvider';

function VerifyEmail() {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const { setUser } = useAuthContext();

  useEffect(() => {
    if (uid && token && setUser) {
      axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/users/verify_email/`,
        { uid, token },
      ).then(() => {
        message.success('Tu correo electrónico fue confirmado');
        fetchUserData().then((newUserData) => {
          setUser(newUserData);
          navigate('/account');
        });
        navigate('/account');
      }).catch((e) => {
        if (e.response.status === 403) {
          message.error('El enlace ha expirado');
        } else if (e.response.status === 400) {
          message.error('Enlace inválido');
        }
        navigate('/account');
      });
    }
  }, []);

  return (
    <Loading />
  );
}

export default VerifyEmail;
