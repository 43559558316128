import React, { useState } from 'react';
import {
  Form, Input, Button, Checkbox, message, Typography, DatePicker, Tooltip,
} from 'antd';
import styled from 'styled-components';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Moment } from 'moment/moment';
import moment from 'moment';
import { noAuthAxios } from '../../utils/axios-instance';
import { handleEmailValidation, handleUsernameValidation } from '../account/Profile';

const { Text, Title } = Typography;

function isOverMinAge(birthDate: Moment, minAge: number) {
  const limitDate = moment().subtract(minAge, 'years');
  return birthDate.isBefore(limitDate);
}

export const FormContainer = styled.div`
  margin: 20px auto;
  background-color: var(--white);
  width: 400px;
  height: fit-content;
  border-radius: 16px;
  padding: 20px 40px;
  text-align: center;
  
  @media only screen and (max-device-width : 500px) {
    width: calc(100vw - 80px);
  }
`;

type SignUpFormValues = {
  first_name?: string;
  last_name?: string;
  username: string;
  email: string;
  birth_date: Moment;
  password: string;
  re_password: string;
  coupon: string;
  accept_terms_and_conditions: string;
};

type SignUpFormResponseData = {
  username?: string[];
  email?: string[];
  password?: string[];
  birth_date?: string[];
  detail?: string;
};

function SignUp() {
  const [saving, setSaving] = useState<boolean>(false);
  const [signUpComplete, setSignUpComplete] = useState<boolean>(false);

  const onFinish = (values: SignUpFormValues) => {
    setSaving(true);
    noAuthAxios.post(
      `${process.env.REACT_APP_API_URL}/users/`,
      {
        ...values,
        birth_date: values.birth_date.format('YYYY-MM-DD'),
      },
    ).then(() => {
      setSignUpComplete(true);
      setSaving(false);
    }).catch((e) => {
      const errorMessages = (e.response?.data as SignUpFormResponseData);
      if ((errorMessages.username || []).join(', ').includes('A user with that username already exists')) {
        message.error('El nombre de usuario ya está registrado');
      } else if ((errorMessages.email || []).join(', ').includes('user with this email address already exists')) {
        message.error('El correo ingresado ya está registrado');
      } else if (errorMessages.password) {
        errorMessages.password.forEach((errorMessage) => {
          message.error(errorMessage);
        });
      } else if (errorMessages.birth_date) {
        errorMessages.birth_date.forEach((errorMessage) => {
          message.error(errorMessage);
        });
      } else if (errorMessages.detail) {
        message.error(errorMessages.detail);
      } else {
        message.error('Ocurrió un error al crear la cuenta, por favor intenta nuevamente');
      }
      setSaving(false);
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <FormContainer>
        <Title level={4} style={{ paddingBottom: 15 }}>Registro</Title>
        {signUpComplete ? (
          <Text type="secondary">
            Para completar el registro te enviamos un correo de validación con un enlace para
            activar tu cuenta.
          </Text>
        ) : (
          <>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
              size="small"
            >
              <Form.Item
                name="first_name"
              >
                <Input placeholder="Nombre" />
              </Form.Item>

              <Form.Item
                name="last_name"
              >
                <Input placeholder="Apellidos" />
              </Form.Item>

              <Form.Item
                name="username"
                rules={[
                  {
                    validator: handleUsernameValidation,
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder="Nombre de usuario" autoCapitalize="off" />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  {
                    validator: handleEmailValidation,
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input placeholder="Correo electrónico" autoCapitalize="off" type="email" />
              </Form.Item>

              <Form.Item
                name="birth_date"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa tu fecha de nacimiento',
                  },
                  () => ({
                    validator(_, value) {
                      if (value && !isOverMinAge(value, 18)) {
                        return Promise.reject(
                          new Error('Debes tener al menos 18 años para registrarte'),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  placeholder="Fecha de nacimiento"
                  format="DD/MM/YYYY"
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Por favor ingresa una contraseña' },
                  () => ({
                    validator(_, value) {
                      if (value.length < 8) {
                        return Promise.reject(
                          new Error('La contraseña debe tener al menos 8 caracteres'),
                        );
                      } if (!/\d/.test(value)) {
                        return Promise.reject(
                          new Error('La contraseña debe tener al menos un número'),
                        );
                      } if (!/[a-zA-Z]/.test(value)) {
                        return Promise.reject(
                          new Error('La contraseña debe tener al menos una letra'),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Contraseña" />
              </Form.Item>

              <Form.Item
                name="re_password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Por favor confirma la contraseña',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Las contraseñas no coinciden'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirmar contraseña" />
              </Form.Item>

              <Form.Item
                name="coupon"
              >
                <Input
                  placeholder="Código promocional"
                  suffix={(
                    <Tooltip
                      title="Si tienes un código promocional ingrésalo acá"
                      color="rgba(231, 231, 23, 0.85)"
                    >
                      <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  )}
                />
              </Form.Item>

              <Form.Item
                name="accept_terms_and_conditions"
                valuePropName="checked"
                hasFeedback
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(new Error('Debes aceptar los términos y condiciones para continuar'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                style={{ textAlign: 'left' }}
              >
                <Checkbox>
                  He leído y acepto los
                  {' '}
                  <Link
                    to="/terms-and-conditions"
                    target="_blank"
                  >
                    términos y condiciones
                  </Link>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {saving ? (
                    <LoadingOutlined />
                  ) : 'CREAR CUENTA'}
                </Button>
              </Form.Item>
            </Form>

            <div>
              ¿Ya tienes una cuenta?
              {' '}
              <Link to="/signin">
                Ingresa
              </Link>
            </div>
          </>
        )}
      </FormContainer>
    </div>
  );
}

export default SignUp;
