import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  NavigationType, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import styled from 'styled-components';
import { Modal } from 'antd';

import moment from 'moment/moment';
import RockPaperScissors from './RockPaperScissors';
import PressTheButton from './PressTheButton';
import backgroundPPT from '../../assets/background-ppt-2.png';
import backgroundTheNumber from '../../assets/background-thenumber.png';
import Loading from '../../components/Loading';
import GameBackground from '../../components/games/GameBackground';
import CancelledTournamentModal from '../../components/games/modals/CancelledTournamentModal';
import {
  Match,
  MatchResult,
  MatchStatus,
  Tournament,
  TournamentParticipation,
  TournamentResult,
} from '../../types/base';
import { axiosInstance } from '../../utils/axios-instance';
import { showDataFetchError } from '../../utils/showDataFetchError';
import InsufficientFundsModal from '../../components/games/modals/InsufficientFundsModal';
import LeaveTournamentModal from '../../components/games/modals/LeaveTournamentModal';
import TheNumberWinnerModal from '../../components/games/modals/number/TheNumberWinnerModal';
import TheNumberLoserModal from '../../components/games/modals/number/TheNumberLoserModal';
import WaitingForPlayersModal from '../../components/games/modals/WaitingForPlayersModal';
import PPTDrawModal from '../../components/games/modals/ppt/PPTDrawModal';
import PPTWinnerModal from '../../components/games/modals/ppt/PPTWinnerModal';
import PPTLoserModal from '../../components/games/modals/ppt/PPTLoserModal';
import { useServerDateTimeContext } from '../../ServerDateTimeProvider';
import { useAuthContext } from '../../AuthProvider';
import { useLocationListener } from '../../hooks/useLocationListener';
import { useSoundContext } from '../../components/SoundContext';
import explosion from '../../assets/sounds/explosion.mp3';

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  font-family: "Pasti", serif;
`;

export default function Game() {
  const navigate = useNavigate();
  const location = useLocation();
  const { gameCode, tournamentId } = useParams();
  const { token } = useAuthContext();
  const { getDatetime } = useServerDateTimeContext();
  const [tournament, setTournament] = useState<Tournament>();
  const [userParticipation, setUserParticipation] = useState<TournamentParticipation>();
  const [currentMatch, setCurrentMatch] = useState<Match>();
  const [showInitialTimer, setShowInitialTimer] = useState<boolean>(false);
  const [showFinalAnimation, setShowFinalAnimation] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [remainingAttempts, setRemainingAttempts] = useState<number>();
  const ws = useRef<WebSocket>();
  const [connected, setConnected] = useState<boolean>(false);
  const [confirmLeaveTournament, setConfirmLeaveTournament] = useState<boolean>(false);
  const [confirmedBackAction, setConfirmedBackAction] = useState<boolean>(false);
  const [showConnectionError, setShowConnectionError] = useState(false);
  const { playSound, stopSound } = useSoundContext();

  const returnToLobby = () => {
    navigate(`/games/${gameCode}`, { replace: true });
  };

  useEffect(() => {
    if (showConnectionError) {
      showDataFetchError(
        'Reintentar',
        'Volver al lobby',
        () => {
          setShowConnectionError(false);
          window.location.reload();
        },
        () => returnToLobby(),
      );
    }
  }, [showConnectionError]);

  const handleLocationChange = useCallback(() => {
    if (
      tournament?.number_of_participants
      && tournament?.number_of_participants < tournament?.required_players
      && !tournament?.started_at
    ) {
      navigate(location.pathname);
      setConfirmLeaveTournament(true);
    } else if (!showResults) {
      navigate(location.pathname);
      Modal.confirm({
        title: '¿Seguro que quieres salir del juego?',
        onOk: () => {
          setConfirmedBackAction(true);
          navigate(-1);
        },
        onCancel: () => {
          setConfirmedBackAction(false);
        },
      });
    }
  }, [tournament, setConfirmLeaveTournament, showResults]);

  useLocationListener((update) => {
    if (update.action === NavigationType.Pop && !confirmedBackAction) {
      handleLocationChange();
    }
  });

  const retrieveUserParticipation = () => {
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/games/tournaments/${tournamentId}/participation/`,
    ).then((response) => {
      setUserParticipation(response.data);
    }).catch(() => {
      Modal.error({
        title: 'Ha ocurrido un error',
        content: 'Por favor intenta refrescando la pagina.',
        centered: true,
      });
    });
  };

  const retrieveTournament = () => {
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/games/tournaments/${tournamentId}/`,
    ).then((response) => {
      setTournament(response.data);
    }).catch(() => {
      Modal.error({
        title: 'Ha ocurrido un error',
        content: 'Por favor intenta refrescando la pagina.',
        centered: true,
      });
    });
  };

  const onTournamentWSMessage = (event: MessageEvent) => {
    const json = JSON.parse(event.data);
    if (json.action === 'subscribe_to_tournament_participation') {
      if (json.response_status === 200 && json.data && json.data.game_code === gameCode) {
        setTournament(json.data);
        retrieveUserParticipation();
      } else if (json.response_status === 403) {
        returnToLobby();
      } else {
        setShowConnectionError(true);
      }
    } else if (json.action === 'subscribe_to_tournament_results') {
      if (json.response_status === 200 && json.data && json.data.game_code === gameCode) {
        setTournament(json.data);
        retrieveUserParticipation();
      }
    } else if (json.action === 'subscribe_to_match_designation') {
      if (json.response_status === 200 && json.data) {
        setCurrentMatch(json.data);
        retrieveTournament();
        ws.current!.send(JSON.stringify({
          action: 'subscribe_to_match_updates',
          pk: tournamentId,
          match_id: json.data.id,
          request_id: new Date().getTime(),
        }));
        ws.current!.send(JSON.stringify({
          action: 'subscribe_to_match_participations',
          pk: tournamentId,
          match_id: json.data.id,
          request_id: new Date().getTime(),
        }));
      }
    } else if (json.action === 'subscribe_to_match_updates') {
      if (json.response_status === 200 && json.data) {
        const newMatchData = json.data;
        setCurrentMatch(newMatchData);
        retrieveUserParticipation();
      }
    } else if (json.action === 'subscribe_to_match_participations') {
      if (json.response_status === 200 && json.data) {
        const newMatchData = json.data;
        setCurrentMatch(newMatchData);
        retrieveUserParticipation();
      }
    }
  };

  useEffect(() => {
    ws.current = new WebSocket(
      `${process.env.REACT_APP_WS_API_URL}/games/tournaments/?token=${token!.access}`,
    );

    if (!connected && ws.current) {
      ws.current.onopen = () => {
        ws.current!.send(JSON.stringify({
          action: 'subscribe_to_tournament_participation',
          pk: tournamentId,
          request_id: new Date().getTime(),
        }));
        ws.current!.send(JSON.stringify({
          action: 'subscribe_to_tournament_results',
          pk: tournamentId,
          request_id: new Date().getTime(),
        }));
        ws.current!.send(JSON.stringify({
          action: 'subscribe_to_match_designation',
          pk: tournamentId,
          request_id: new Date().getTime(),
        }));
        ws.current!.onclose = () => {
          window.location.reload();
        };
      };
      ws.current.onmessage = onTournamentWSMessage;
      ws.current.onerror = () => {
        setShowConnectionError(true);
      };
      setConnected(true);
    }

    setTimeout(() => {
      // If stuck on connecting state
      if (ws.current?.readyState === WebSocket.CONNECTING) {
        setShowConnectionError(true);
      }
    }, 3000);

    return () => {
      if (ws.current?.readyState === WebSocket.OPEN) {
        ws.current!.close();
      }
    };
  }, [tournamentId, token]);

  const updateMatch = () => new Promise(
    (resolve: (value: Match) => void, reject) => {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/games/matches/current/`,
        {
          params: { tournament_id: tournamentId },
        },
      ).then((matchResponse) => {
        setCurrentMatch(matchResponse.data);
        ws.current!.send(JSON.stringify({
          action: 'subscribe_to_match_updates',
          pk: tournamentId,
          match_id: matchResponse.data.id,
          request_id: new Date().getTime(),
        }));
        resolve(matchResponse.data);
      }).catch((e) => reject(e));
    },
  );

  useEffect(() => {
    if (tournamentId && !tournament) {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/games/tournaments/${tournamentId}/`,
      ).then((response) => {
        setTournament(response.data);
        retrieveUserParticipation();
        updateMatch();
      }).catch((e) => {
        if (e.response?.status === 403) {
          returnToLobby();
        } else {
          setShowConnectionError(true);
        }
      });
    }
  }, [tournamentId]);

  useEffect(() => {
    if (tournament?.ended_at && !currentMatch) {
      setShowResults(true);
    }
  }, [tournament]);

  useEffect(() => {
    let showInitialTimerTimeoutId: NodeJS.Timeout;
    let showResultsTimeoutId: NodeJS.Timeout;

    if (currentMatch) {
      if (getDatetime().getTime() < (new Date(currentMatch.starts_at)).getTime()) {
        setShowInitialTimer(true);
        const startTime = moment(currentMatch.starts_at);
        showInitialTimerTimeoutId = setTimeout(() => {
          updateMatch().then(() => setShowInitialTimer(false));
        }, moment.duration(startTime.diff(getDatetime())).asMilliseconds());
      } else {
        setShowInitialTimer(false);
      }

      if (currentMatch.result === MatchResult.COMPLETED) {
        if (gameCode === 'PPT') {
          setShowFinalAnimation(true);
          playSound('explosion', explosion);
          showResultsTimeoutId = setTimeout(() => {
            setShowFinalAnimation(false);
            setShowResults(true);
          }, 3000);
        } else {
          stopSound('clock');
          playSound('explosion', explosion);
          setShowResults(true);
        }
      } else if (currentMatch.result === MatchResult.REPEAT_MATCH) {
        setShowFinalAnimation(true);
        playSound('explosion', explosion);
        setRemainingAttempts(currentMatch.properties.remaining_attempts);
        showResultsTimeoutId = setTimeout(() => {
          setShowFinalAnimation(false);
          setShowResults(true);
        }, 3000);
      } else {
        setShowFinalAnimation(false);
        setShowResults(false);
      }
    }
    return () => {
      clearTimeout(showInitialTimerTimeoutId);
      clearTimeout(showResultsTimeoutId);
    };
  }, [currentMatch]);

  const renderContent = () => {
    if (tournament) {
      if (
        tournament?.number_of_participants < tournament.required_players
        && !tournament.started_at
      ) {
        if (userParticipation?.status === 'saldo insuficiente') {
          return (
            <InsufficientFundsModal
              onClose={returnToLobby}
            />
          );
        } if (confirmLeaveTournament) {
          return (
            <LeaveTournamentModal
              tournamentId={tournament.id}
              onSuccess={returnToLobby}
              onCancel={() => setConfirmLeaveTournament(false)}
            />
          );
        }
        return (
          <WaitingForPlayersModal
            numberOfPlayers={tournament.number_of_participants}
            requiredPlayers={tournament.required_players}
            onClose={() => setConfirmLeaveTournament(true)}
          />
        );
      } if (tournament.result === TournamentResult.CANCELLED) {
        return (
          <CancelledTournamentModal
            onClose={returnToLobby}
          />
        );
      } if (showResults) {
        if (userParticipation?.is_winner) {
          if (tournament.game_code === 'NUMBER') {
            return (
              <TheNumberWinnerModal
                userParticipation={userParticipation}
                action={returnToLobby}
              />
            );
          }
          return userParticipation.properties.latest_match_status === MatchStatus.DRAW ? (
            <PPTDrawModal
              userParticipation={userParticipation}
              action={returnToLobby}
            />
          ) : (
            <PPTWinnerModal
              userParticipation={userParticipation}
              action={returnToLobby}
            />
          );
        }
        if (userParticipation?.status === 'eliminado' || userParticipation?.is_winner === false) {
          return tournament.game_code === 'NUMBER' ? (
            <TheNumberLoserModal
              tournament={tournament}
              userParticipation={userParticipation}
              action={returnToLobby}
            />
          ) : (
            <PPTLoserModal
              tournament={tournament}
              userParticipation={userParticipation}
              action={returnToLobby}
            />
          );
        }
      }
      // Render game
      return gameCode === 'PPT' ? (
        <RockPaperScissors
          tournament={tournament!}
          currentMatch={currentMatch}
          showInitialTimer={showInitialTimer}
          showFinalAnimation={showFinalAnimation}
          showMatchResults={
            showResults && (
              !currentMatch?.is_final_match || currentMatch.properties.status === MatchStatus.DRAW
            )
          }
          remainingAttempts={remainingAttempts}
        />
      ) : (
        <PressTheButton
          tournament={tournament!}
          currentMatch={currentMatch}
          showInitialTimer={showInitialTimer}
        />
      );
    }
    return <Loading />;
  };

  if (showConnectionError) {
    return (
      <GameBackground backgroundImage={gameCode === 'PPT' ? backgroundPPT : backgroundTheNumber} />
    );
  }

  return !tournament ? <Loading /> : (
    <GameBackground backgroundImage={gameCode === 'PPT' ? backgroundPPT : backgroundTheNumber}>
      <Content>
        {renderContent()}
      </Content>
    </GameBackground>
  );
}
