export function isValidRut(rutValue: string): boolean {
  if (!rutValue.match(/^\d{1,2}.\d{3}.\d{3}[-][0-9K]$/)) {
    return false;
  }
  const splitRut = rutValue.replaceAll('.', '').split('-');
  const [rut, checkDigit] = splitRut;

  const secuencia = [2, 3, 4, 5, 6, 7, 2, 3];
  let sum = 0;

  for (let i = rut.length - 1; i >= 0; i -= 1) {
    const d = parseInt(rut.charAt(i), 10);
    sum += d * secuencia[rut.length - (i + 1)];
  }

  const numericCheckDigit = 11 - (sum % 11);
  let correctCheckDigit;

  if (numericCheckDigit === 10) {
    correctCheckDigit = 'K';
  } else if (numericCheckDigit === 11) {
    correctCheckDigit = '0';
  } else {
    correctCheckDigit = numericCheckDigit.toString();
  }

  return checkDigit.toString() === correctCheckDigit;
}

const invalidRutCharacters = /[a-jl-zA-JL-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?]/i;

export function formatRut(rutValue: string): string {
  const plainRut = rutValue.replaceAll('-', '').replaceAll('.', '');
  if (plainRut.length < 2 || invalidRutCharacters.test(plainRut)) {
    return rutValue;
  }
  const rut = plainRut.slice(0, -1);
  const checkDigit = plainRut.slice(-1);
  const formattedRut = Number(rut).toLocaleString('es-CL');
  return `${formattedRut}-${checkDigit}`.toUpperCase();
}
