import { useLayoutEffect, useState } from 'react';

interface WindowSize {
  windowWidth: number;
  windowHeight: number;
}

function useWindowSize() {
  const [size, setSize] = useState<WindowSize>({ windowWidth: 0, windowHeight: 0 });
  useLayoutEffect(() => {
    function updateSize() {
      window.setTimeout(() => {
        setSize({
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        });
      }, 200);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

export default useWindowSize;
