import React, { useEffect } from 'react';
import {
  Col, Image, Row,
} from 'antd';
import styled from 'styled-components';
import CustomBackgroundModal, { ModalButton } from '../CustomBackgroundModal';
import modalBackground from '../../../../assets/modals/backgrounds/yellow.png';
import modalBackgroundPortrait from '../../../../assets/modals/backgrounds/yellow-mobile.png';
import title from '../../../../assets/modals/texts/ganaste.png';
import smilingBomb from '../../../../assets/smiling-bomb.png';
import { TournamentParticipation } from '../../../../types/base';

export const PrizeContainer = styled.div`
  line-height: 1.5715;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  height: 40px;
  padding: 6.4px 40px;
  font-size: 16px;
  border-radius: 40px;
  background: var(--primary-5);
  border: 1px solid var(--text-primary);
  font-weight: bold;
  top: -15px;


  @media only screen and (max-height: 576px) and (orientation: landscape){
    height: 30px;
    padding: 4.8px 30px;
    font-size: 12px;
    top: -5px;
  };

  @media only screen and (max-height: 400px) and (orientation: landscape) {
    height: 20px;
    padding: 3.2px 20px;
    font-size: 10px;
    line-height: 1.28;
    top: -5px;
  };
`;

type TheNumberWinnerModalProps = {
  userParticipation: TournamentParticipation;
  action: () => void;
};

export default function TheNumberWinnerModal({
  userParticipation, action,
}: TheNumberWinnerModalProps) {
  useEffect(() => {
    // Automatically perform action after 30 seconds
    const timerId = setTimeout(() => {
      action();
    }, 30000);
    return () => clearTimeout(timerId);
  }, []);

  return (
    <CustomBackgroundModal
      onClose={action}
      backgroundImage={modalBackground}
      backgroundImagePortrait={modalBackgroundPortrait}
    >
      <Row justify="center" gutter={[0, { xs: 12, md: 8 }]}>
        <Col span={24}>
          <Image
            className="modal-title-img"
            preview={false}
            src={title}
          />
        </Col>
        <Col span={24}>
          <PrizeContainer>
            <span>
              $
              {userParticipation.amount_earned}
            </span>
          </PrizeContainer>
        </Col>
        <Col span={24}>
          <Image
            className="modal-img"
            preview={false}
            src={smilingBomb}
          />
        </Col>
        <Col
          xs={{ span: 16 }}
          sm={{ span: 24 }}
        >
          NO TE PIERDAS LA SIGUIENTE MESA
        </Col>
        <Col span={24}>
          <ModalButton
            type="primary"
            shape="round"
            size="large"
            onClick={action}
          >
            JUGAR
          </ModalButton>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}
