import { axiosInstance } from './axios-instance';

type RegistrationData = {
  p256dh: string;
  auth: string;
  registration_id: string;
  browser: string;
};

type WebPushDevice = {
  id: number;
  name: string;
  registration_id: string;
  active: boolean;
  browser: string;
};

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function getBrowserName(): string {
  if ('userAgentData' in navigator) {
    // navigator.userAgentData is not available in Firefox and Safari
    // @ts-ignore
    const { userAgentData } = navigator;
    let browserName;
    let browserVersion;
    let chromeVersion = null;
    for (let i = 0; i < userAgentData.brands.length; i += 1) {
      const { brand } = userAgentData.brands[i];
      browserVersion = userAgentData.brands[i].version;
      if (brand.match(/opera|chrome|edge|safari|firefox|msie|trident/i) !== null) {
        if (brand.match(/chrome/i) !== null) {
          // If we have a chrome match, save the match, but try to find another match
          // E.g. Edge can also produce a false Chrome match.
          chromeVersion = browserVersion;
        } else {
          // If this is not a chrome match return immediately
          browserName = brand.slice(brand.indexOf(' ') + 1);
          return browserName;
        }
      }
    }
    // No non-Chrome match was found. If we have a chrome match, return it.
    if (chromeVersion !== null) {
      return 'chrome';
    }
  }
  // If no userAgentData is not present, or if no match via userAgentData was found,
  // try to extract the browser name and version from userAgent
  const { userAgent } = navigator;
  const ua = userAgent; let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE';
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return 'Opera';
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) {
    M.splice(1, 1, tem[1]);
  }
  return M[0];
}

export const subscribeUser = () => new Promise(
  (resolve: (value: WebPushDevice) => void, reject) => {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      navigator.serviceWorker.register('/navigatorPush.service.js').then(() => {
        navigator.serviceWorker.ready.then((sWRegistration) => {
          sWRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_WP_SERVER_KEY!),
          }).then((subscription) => {
            const endpointParts = subscription.endpoint.split('/');
            const registrationId = endpointParts[endpointParts.length - 1];
            const data: RegistrationData = {
              browser: getBrowserName().toUpperCase(),
              p256dh: btoa(
                new Uint8Array(
                  subscription.getKey('p256dh')!,
                ).reduce((previousValue, byte) => previousValue + String.fromCharCode(byte), ''),
              ),
              auth: btoa(
                new Uint8Array(
                  subscription.getKey('auth')!,
                ).reduce((previousValue, byte) => previousValue + String.fromCharCode(byte), ''),
              ),
              registration_id: registrationId,
            };
            axiosInstance.post(
              `${process.env.REACT_APP_API_URL}/device/web/`,
              data,
            ).then((response) => {
              resolve(response.data);
            }).catch((e) => {
              reject(e);
            });
          }).catch((e) => {
            reject(e);
          });
        }).catch((e) => {
          reject(e);
        });
      }).catch((e) => {
        reject(e);
      });
    } else {
      reject(new Error('Notificaciones no habilitadas'));
    }
  },
);
