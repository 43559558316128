import React, { useState } from 'react';
import {
  Button, Form, Input, message, Typography,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FormContainer } from './SignUp';
import { useAuthContext } from '../../AuthProvider';
import { subscribeUser } from '../../utils/push-notifications';

const { Title } = Typography;

interface SignInFormValues {
  username: string;
  password: string;
}

type SignInFormResponseData = {
  detail?: string;
};

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  const onFinish = (values: SignInFormValues) => {
    if (signIn) {
      setLoading(true);
      signIn(values).then(() => {
        subscribeUser().then(() => {
          setLoading(false);
          navigate('/', { replace: true, state: location.state });
        }).catch(() => {
          setLoading(false);
          navigate('/', { replace: true, state: location.state });
        });
      }).catch((e) => {
        const errorMessages = (e.response?.data as SignInFormResponseData);
        if (errorMessages.detail && errorMessages.detail.includes('No active account found with the given credentials')) {
          message.error('Credenciales inválidas');
        } else {
          message.error('Ocurrió un error en la autenticación');
        }
        setLoading(false);
      });
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <FormContainer>
        <Title level={4} style={{ paddingBottom: 15 }}>Iniciar sesión</Title>
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Por favor ingresa un nombre de usuario' }]}
          >
            <Input placeholder="Nombre de usuario" autoCapitalize="off" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Por favor ingresa una contraseña' }]}
          >
            <Input.Password placeholder="Contraseña" />
          </Form.Item>

          <Form.Item style={{ marginBottom: 8 }}>
            <Button type="primary" htmlType="submit" block>
              {loading ? (
                <LoadingOutlined />
              ) : 'INGRESAR'}
            </Button>
          </Form.Item>
        </Form>

        <Link to="/request-password-reset">
          ¿Olvidaste tu contraseña?
        </Link>

        <div>
          ¿No tienes una cuenta aún?
          {' '}
          <Link to="/signup">
            Regístrate
          </Link>
        </div>
      </FormContainer>
    </div>
  );
}

export default SignIn;
