import React, { useEffect } from 'react';
import {
  Col, Image, Row, Typography,
} from 'antd';
import styled from 'styled-components';
import CustomBackgroundModal, { ModalButton } from '../CustomBackgroundModal';
import modalBackground from '../../../../assets/modals/backgrounds/yellow.png';
import modalBackgroundPortrait from '../../../../assets/modals/backgrounds/yellow-mobile.png';
import title from '../../../../assets/modals/texts/eres-el-campeon.png';
import { TournamentParticipation } from '../../../../types/base';
import { PrizeContainer } from '../number/TheNumberWinnerModal';

const { Title } = Typography;

const TitleImage = styled(Image)`
  width: 40%;

  @media only screen and (orientation: portrait) {
    width: 80%;
  }

  @media only screen and (max-height: 576px) and (orientation: landscape) {
    width: 30%;
  }
`;

type PPTWinnerModalProps = {
  userParticipation: TournamentParticipation;
  action: () => void;
};

export default function PPTWinnerModal({
  userParticipation, action,
}: PPTWinnerModalProps) {
  useEffect(() => {
    // Automatically perform action after 30 seconds
    const timerId = setTimeout(() => {
      action();
    }, 30000);
    return () => clearTimeout(timerId);
  }, []);

  return (
    <CustomBackgroundModal
      onClose={action}
      backgroundImage={modalBackground}
      backgroundImagePortrait={modalBackgroundPortrait}
    >
      <Row justify="center" gutter={[0, { xs: 24, md: 8 }]}>
        <Col span={24}>
          <TitleImage
            preview={false}
            src={title}
          />
        </Col>
        <Col xs={{ span: 24 }}>
          <Title
            level={3}
            style={{
              fontFamily: 'Pasti, serif',
            }}
          >
            GANASTE
          </Title>
        </Col>
        <Col span={24}>
          <PrizeContainer>
            <span>
              $
              {userParticipation.amount_earned}
            </span>
          </PrizeContainer>
        </Col>
        <Col
          span={24}
          style={{
            maxWidth: 160,
          }}
        >
          <Title level={5}>
            NO TE PIERDAS LA SIGUIENTE MESA
          </Title>
        </Col>
        <Col span={24}>
          <ModalButton
            type="primary"
            shape="round"
            size="large"
            onClick={action}
          >
            JUGAR
          </ModalButton>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}
