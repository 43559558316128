import React from 'react';
import { Col, Row } from 'antd';
import { Match, Tournament } from '../../types/base';

type RoundsHeaderProps = {
  tournament: Tournament;
  match: Match;
};

export default function RoundsHeader({
  tournament, match,
}: RoundsHeaderProps) {
  return (
    <div style={{
      position: 'fixed',
      top: 'calc(90px / 2)',
      right: 72,
      transform: 'translateY(-50%)',
    }}
    >
      <Row gutter={48} align="middle">
        <Col
          style={{
            color: 'white',
            fontFamily: 'Pasti, serif',
            fontSize: 20,
          }}
        >
          <span>
            VAS EN LA
            {' '}
            {match.round}
            {' '}
            DE
            {' '}
            {tournament.rounds}
          </span>
        </Col>
      </Row>
    </div>
  );
}
