import React, { useEffect, useState } from 'react';
import './App.css';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import WebFont from 'webfontloader';
import Home from './pages/Home';
import MenuLayout from './components/MenuLayout';
import SignUp from './pages/auth/SignUp';
import SignIn from './pages/auth/SignIn';
import ActivateAccount from './pages/auth/ActivateAccount';
import RequestPasswordReset from './pages/auth/RequestPasswordReset';
import PasswordReset from './pages/auth/PasswordReset';
import useWindowSize from './hooks/useWindowSize';
import Splash from './components/Splash';
import ReadTermsAndConditions from './pages/ReadTermsAndConditions';
import Account from './pages/account/Account';
import VerifyEmail from './pages/account/VerifyEmail';
import Lobby from './pages/games/Lobby';
import Game from './pages/games/Game';
import Loading from './components/Loading';
import { useAuthContext } from './AuthProvider';
import ContactUs from './pages/ContactUs';
import Ranking from './pages/games/Ranking';
import TestPopup from './pages/games/TestPopup';

function NotFound() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Redirect to home
    navigate('/', { replace: true, state: location.state });
  });

  return (
    <Loading />
  );
}

export function RedirectToSignIn() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(
      '/signin',
      {
        replace: true,
        state: {
          next: `${location.pathname}${location.search}`,
        },
      },
    );
  }, []);

  return (
    <Loading />
  );
}

function App() {
  const auth = useAuthContext();
  const { windowWidth, windowHeight } = useWindowSize();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ['Montserrat', 'MisuseDisplay', 'Pasti', 'DigitalDismay', 'OpenSans'],
      },
      loading() {
        setLoading(false);
      },
    });
  }, []);

  if (!windowWidth || !windowHeight || loading) {
    return (
      <div className="App">
        <Splash />
      </div>
    );
  }

  if (!auth.token || !auth.user) {
    return (
      <div className="App">
        <MenuLayout isLoggedIn={false}>
          <Routes>
            <Route path="/" element={<Home windowWidth={windowWidth} windowHeight={windowHeight} isLoggedIn={false} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/activate/:uid/:token" element={<ActivateAccount />} />
            <Route path="/request-password-reset" element={<RequestPasswordReset />} />
            <Route path="/password-reset/:uid/:token" element={<PasswordReset />} />
            <Route path="/terms-and-conditions" element={<ReadTermsAndConditions />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/jqzolgjhga" element={<TestPopup />} />
            <Route path="*" element={<RedirectToSignIn />} />
          </Routes>
        </MenuLayout>
      </div>
    );
  }
  return (
    <div className="App">
      <MenuLayout>
        <Routes>
          <Route path="/" element={<Home windowWidth={windowWidth} windowHeight={windowHeight} />} />
          <Route path="/account" element={<Account />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/verify-email/:uid/:token" element={<VerifyEmail />} />
          <Route path="/terms-and-conditions" element={<ReadTermsAndConditions />} />
          <Route path="/games/:gameCode" element={<Lobby />} />
          <Route path="/games/:gameCode/play/:tournamentId" element={<Game />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MenuLayout>
    </div>
  );
}

export default App;
