import { useEffect, useState } from 'react';
import { Moment } from 'moment';

const getReturnValues = (countdown: number | null) => {
  if (!countdown || countdown < 0) {
    return {
      minutes: '0',
      seconds: '00',
    };
  }
  const minutes = Math.floor(countdown / (1000 * 60));
  const seconds = Math.floor((countdown % (1000 * 60)) / 1000);

  return {
    minutes: String(minutes),
    seconds: String(seconds).padStart(2, '0'),
  };
};

const useCountdown = (targetDate: Moment | null, getDatetime: () => Date) => {
  const countdownDate = targetDate?.toDate().getTime();

  const [countDown, setCountDown] = useState<number | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countdownDate ? countdownDate - getDatetime().getTime() : null);
    }, 1000);

    return () => clearInterval(interval);
  }, [countdownDate]);

  const seconds = countDown ? Math.max(Math.floor(countDown / 1000), 0) : undefined;

  return {
    clockCountdown: getReturnValues(countDown),
    seconds,
  };
};

export { useCountdown };
