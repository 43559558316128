import React, { useEffect } from 'react';
import { Moment } from 'moment';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { useCountdown } from '../../hooks/useCountdown';
import { useServerDateTimeContext } from '../../ServerDateTimeProvider';
import clock from '../../assets/sounds/clock.mp3';
import { useSoundContext } from '../SoundContext';

const Timer = styled.div`
  background-color: var(--black);
  position: relative;
  width: 160px;
  height: auto;
  padding: 9px 5px 5px 10px;
  clip-path: polygon(
    0 8px,
    8px 0,
    calc(100% - 8px) 0,
    100% 8px,
    100% calc(100% - 8px),
    calc(100% - 8px) 100%,
    8px 100%,
    0% calc(100% - 8px),
    0% 8px
  );
  
  @media only screen and (max-width: 768px) {
    width: 140px;
    padding: 9px 5px 5px 10px;
  }

  @media only screen and (max-height: 800px) and (orientation: portrait) {
    width: 120px;
    padding: 10px 3px 6px 7px;
  };

  &.rock-paper-scissors-timer {
    @media only screen and (max-height: 800px) and (orientation: portrait) {
      width: 88px;
      padding: 12px 3px 10px 6px;
      clip-path: polygon(
        0 6px,
        6px 0,
        calc(100% - 6px) 0,
        100% 6px,
        100% calc(100% - 6px),
        calc(100% - 6px) 100%,
        6px 100%,
        0% calc(100% - 6px),
        0% 6px
      );
    };
  }
  
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    width: 120px;
    padding: 12px 4px 8px 8px;
  };
`;

const CenteredCol = styled(Col)`
  color: var(--white);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  font-family: DigitalDismay, serif;
  font-size: 100px;
  margin: -20px 0;
  line-height: normal;

  @media only screen and (max-width: 768px) {
    font-size: 90px;
  }

  @media only screen and (max-height: 800px) and (orientation: portrait) {
    font-size: 75px;
  };
  
  &.rock-paper-scissors-timer {
    @media only screen and (max-height: 800px) and (orientation: portrait) {
      font-size: 55px;
    };
  }

  @media only screen and (max-width: 960px) and (orientation: landscape) {
    font-size: 75px;
  };
`;

interface CountdownTimerProps {
  target: Moment | null;
  className?: string | undefined;
}
export function CountdownTimer({ target, className }: CountdownTimerProps) {
  const { getDatetime } = useServerDateTimeContext();
  const { clockCountdown } = useCountdown(target, getDatetime);
  const { minutes, seconds } = clockCountdown;
  const { playSound, stopSound, isPlaying } = useSoundContext();

  useEffect(() => {
    if ((seconds !== '00' || minutes !== '0') && !isPlaying.clock) {
      playSound('clock', clock, true);
    }
    if (seconds === '00' && minutes === '0') {
      stopSound('clock');
    }
  }, [seconds]);

  return (
    <Timer className={className}>
      <Row justify="center" align="middle">
        {Array.from(minutes).map((digit, index) => (
          <CenteredCol span={7} key={`minutes_${index}`} className={className}>
            {digit}
          </CenteredCol>
        ))}
        <CenteredCol span={3} className={className}>
          :
        </CenteredCol>
        {Array.from(seconds).map((digit, index) => (
          <CenteredCol span={7} key={`second_${index}`} className={className}>
            {digit}
          </CenteredCol>
        ))}
      </Row>
    </Timer>
  );
}
