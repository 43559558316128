import { useState } from 'react';
import { axiosInstance } from '../utils/axios-instance';
import { User } from '../types/base';

export default function useUserData() {
  const getUserData = () => {
    const userDataString = localStorage.getItem('userData');
    return userDataString ? JSON.parse(userDataString) : null;
  };

  const [userData, setUserData] = useState(getUserData());

  const saveUserData = (data: User) => {
    localStorage.setItem('userData', JSON.stringify(data));
    setUserData(data);
  };

  return {
    setUserData: saveUserData,
    userData,
  };
}

export const fetchUserData = () => new Promise(
  (resolve: (value: User) => void, reject) => {
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/users/me/`,
    ).then((response) => resolve(response.data))
      .catch((e) => {
        reject(e);
      });
  },
);
