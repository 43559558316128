import React, { useState } from 'react';
import {
  Button, Form, Input, message, Typography,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { noAuthAxios } from '../../utils/axios-instance';
import { Token } from '../../types/base';
import { FormContainer } from './SignUp';
import { useAuthContext } from '../../AuthProvider';

const { Title } = Typography;

type NewPasswordFormResponseData = {
  password?: string[];
  new_password?: string[];
};

interface NewPasswordFormValues {
  password: string;
}

function PasswordReset() {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const { setToken } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = (values: NewPasswordFormValues) => {
    setLoading(true);
    noAuthAxios.post(
      `${process.env.REACT_APP_API_URL}/users/reset_password_confirm/`,
      {
        ...values,
        uid,
        token,
      },
    ).then((response) => {
      const userToken: Token = response.data;
      setLoading(false);
      if (setToken) {
        setToken(userToken);
        navigate('/', { replace: true });
      }
    }).catch((e) => {
      const errorMessages = (e.response?.data as NewPasswordFormResponseData);
      if (errorMessages.password) {
        errorMessages.password.forEach((errorMessage) => {
          message.error(errorMessage);
        });
      } else if (errorMessages.new_password) {
        errorMessages.new_password.forEach((errorMessage) => {
          message.error(errorMessage);
        });
      } else if (e.response.status === 400) {
        message.error('Enlace inválido.');
        navigate('/', { replace: true });
      } else {
        message.error('Ocurrió un error, por favor intenta nuevamente.');
      }
      setLoading(false);
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <FormContainer>
        <Title level={4}>Cambiar contraseña</Title>

        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          style={{ paddingTop: 18 }}
        >
          <Form.Item
            label="Nueva contraseña"
            name="new_password"
            rules={[
              { required: true, message: 'Por favor ingresa una contraseña' },
              () => ({
                validator(_, value) {
                  if (value.length < 8) {
                    return Promise.reject(
                      new Error('La contraseña debe tener al menos 8 caracteres'),
                    );
                  } if (!/\d/.test(value)) {
                    return Promise.reject(
                      new Error('La contraseña debe tener al menos un número'),
                    );
                  } if (!/[a-zA-Z]/.test(value)) {
                    return Promise.reject(
                      new Error('La contraseña debe tener al menos una letra'),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirmar nueva contraseña"
            name="re_new_password"
            dependencies={['new_password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Por favor confirma la contraseña',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Las contraseñas no coinciden'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item style={{ marginBottom: 8 }}>
            <Button type="primary" htmlType="submit" block>
              {loading ? (
                <LoadingOutlined />
              ) : 'CREAR CONTRASEÑA'}
            </Button>
          </Form.Item>
        </Form>

      </FormContainer>
    </div>
  );
}

export default PasswordReset;
