export interface Token {
  refresh: string;
  access: string;
}

export interface UserWallet {
  total_balance: number;
  balance: number;
  promotional_balance: number;
  amount_pending_withdrawal: number;
  latest_modification: string;
}

export interface User {
  id: number;
  username: string;
  email: string;
  email_verified: boolean;
  first_name: string;
  last_name: string;
  full_name: string;
  birth_date?: string | null;
  profile_photo_url?: string | null;
  accepted_current_terms_and_conditions: boolean;
  game_stats: GameStats[];
  wallet: UserWallet;
}

export interface GameStats {
  category: string;
  played: number;
  won: number;
}

export interface UserRank {
  id: number;
  position: number;
  score: number;
  user: User;
}

export interface TermsAndConditions {
  id: number;
  content: string;
  valid_from: string;
  created_at: string;
}

export type TransactionType = 'retiro' | 'deposito' | 'cobro por inscripción';
export type TransactionStatus = 'pendiente' | 'completada' | 'fallida';

export interface PaymentGateway {
  id: string;
  name: string;
  code: string;
}

export interface Bank {
  id: string;
  name: string;
}

export interface Transaction {
  id: string;
  amount: number;
  promotional_amount: number;
  new_balance: number;
  new_promotional_balance: number;
  payment_gateway: PaymentGateway;
  type: TransactionType;
  status: TransactionStatus;
  created_at: string;
  pagarapido_payment_link?: string | null;
}

export type GameCode = 'PPT' | 'NUMBER';

export interface Game {
  id: number;
  name: string;
  code: GameCode;
  players_per_round: number;
  tables: GameTable[];
}

export interface GameTable {
  id: number;
  entry_fee: number;
  rounds: number;
  number_of_players: number;
  open_tournament: Tournament;
  expected_total_prize: number;
  is_active: boolean;
}

export interface TournamentParticipationProperties {
  message?: string | null;
  latest_match_status?: MatchStatus;
  latest_match_total_attempts?: number;
  latest_round?: number;
  position?: number | null;
}

export type TournamentParticipationStatus = 'inscrito' | 'saldo insuficiente' | 'eliminado';

export interface TournamentParticipation {
  id: number;
  user: User;
  is_winner: boolean;
  amount_earned: number
  status: TournamentParticipationStatus;
  properties: TournamentParticipationProperties;
}

export interface RoundType {
  round_number: number;
  matches: Match[];
  matches_in_round: number;
}

export interface Bracket {
  left: RoundType[];
  right: RoundType[];
  final: Match | null;
}

export enum TournamentResult {
  OK = 1,
  CLOSEST_NUMBER_WON = 2,
  CANCELLED = 3,
}

export interface Tournament {
  id: string;
  game_code: GameCode;
  created_at: string;
  started_at: string;
  ended_at: string;
  number_of_participants: number;
  required_players: number;
  result: TournamentResult;
  bracket: Bracket;
  rounds: number;
  total_prize: number;
  winners: string[];
}

export type RockPaperScissorsOption = 'rock' | 'paper' | 'scissors';

export interface MatchParticipation {
  id: number;
  user: User;
  is_winner: boolean | null;
  selected_option?: RockPaperScissorsOption | null;
  past_selected_options?: RockPaperScissorsOption[];
  player_selected_option: boolean;
}

export enum MatchStatus {
  OK = 1,
  DRAW = 2,
  WALKOVER = 3,
  NO_PLAYERS = 4,
  NO_WINNERS = 5,
  CLOSEST_NUMBER_WON = 6,
}

export interface MatchProperties {
  winning_number?: number;
  current_attempt?: number;
  remaining_attempts?: number;
  result_message?: string | null;
  status?: MatchStatus;
}

export enum MatchResult {
  COMPLETED = 1,
  REPEAT_MATCH = 2,
}

export interface Match {
  id: number;
  round: number;
  next_match: number | null;
  starts_at: string;
  ends_at: string;
  result: MatchResult | null;
  is_final_match: boolean;
  match_participations: MatchParticipation[];
  properties: MatchProperties;
}
