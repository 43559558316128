import React from 'react';
import { Col, Image, Row } from 'antd';
import CustomBackgroundModal from './CustomBackgroundModal';
import modalBackground from '../../../assets/modals/backgrounds/purple.png';
import modalBackgroundPortrait from '../../../assets/modals/backgrounds/purple-mobile.png';
import title from '../../../assets/modals/texts/jugaste.png';
import anxiousBomb from '../../../assets/anxious-bomb.png';

export default function WaitingForOthersToPlayModal() {
  return (
    <CustomBackgroundModal
      closable={false}
      backgroundImage={modalBackground}
      backgroundImagePortrait={modalBackgroundPortrait}
      className="waiting-for-other-players-modal"
    >
      <Row justify="center" gutter={[0, 24]}>
        <Col span={24}>
          <Image
            className="modal-title-img"
            preview={false}
            src={title}
          />
        </Col>
        <Col span={24}>
          <Image
            className="modal-img"
            preview={false}
            src={anxiousBomb}
          />
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}
