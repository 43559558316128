import React, { useEffect, useState } from 'react';
import {
  Avatar, Button, Col, Layout, Menu, Row, Dropdown, Image,
} from 'antd';
import {
  CloseOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { To } from 'react-router';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';

import { MenuProps } from 'rc-menu';
import logo from '../assets/logo-header.png';
import isotype from '../assets/bomb.png';
import useWindowSize from '../hooks/useWindowSize';
import { User } from '../types/base';
import { DefaultAvatarImage, getDefaultAvatar } from '../pages/account/Profile';
import { menuHeight } from '../constants/ui';
import { useAuthContext } from '../AuthProvider';
import { useSoundContext } from './SoundContext';
import gameMusic from '../assets/sounds/gamemusic.mp3';

const { Header, Content, Sider } = Layout;

interface HeaderContentProps {
  height: number;
}

const HeaderContent = styled.div<HeaderContentProps>`
  //width: calc(100% - 16px);
  height: ${(props) => props.height}px;
  position: relative;


  @media only screen and (max-width: 1099px) {
    width: calc(100% - 16px);
  }
`;

const Logo = styled.img`
  height: 70px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const SoundIconContainer = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 8%;
  right: 3%;
  background-color: #bdbdbd66;
  z-index: 1100;
  @media only screen and (max-width: 767px) {
    height: 35px;
    width: 35px;
    bottom: 6.5%;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 2em;
  color: white;
  margin: auto;

  @media (max-width: 767px) {
    font-size: 1.3em;
  }
`;

interface SideMenuProps {
  width: number
  height: number;
}

const LeftMenu = styled.div<SideMenuProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translateX(-50%) translateY(-50%);
`;

const RightMenu = styled.div<SideMenuProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translateX(-50%) translateY(-50%);
`;

const MenuItem = styled(Button)`
  width: 130px;
  height: 30px;
  background-color: var(--primary-4);
  color: var(--white) !important;
  border: 0;
  text-align: left;
  border-radius: 6px;
  border-left: 12px solid var(--primary-3);
  padding: 4px 4px;
  font-weight: bold;

  :hover, :focus, :active {
    background-color: var(--primary-4);
    color: var(--primary-3) !important;
    border-left: 12px solid var(--primary-3);
  }
`;

const UserMenuContainer = styled.div`
  width: 32px;
  height: ${menuHeight}px;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
`;

const SiderButtonContainer = styled.div`
  width: 52px;
  height: ${menuHeight}px;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
`;

type MenuItemProps = {
  to: To;
  children?: React.ReactNode;
};

function MenuItemLink({ to, children }: MenuItemProps) {
  return (
    <Link to={to}>
      <MenuItem>
        {children}
      </MenuItem>
    </Link>
  );
}

type UserMenuProps = {
  userData: User;
  onClickMenu: MenuClickEventHandler;
};

function UserMenu({ userData, onClickMenu }: UserMenuProps) {
  const userMenuItems: MenuProps['items'] = [
    {
      label: 'Mi cuenta',
      key: 'account',
    },
    {
      label: 'Ranking',
      key: 'ranking',
    },
    {
      label: 'Contacto',
      key: 'contactus',
    },
    {
      label: (
        <span style={{ color: '#ff4d4f' }}>
          <FontAwesomeIcon icon={faArrowRightFromBracket} />
          {' '}
          Cerrar sesión
        </span>
      ),
      key: 'logout',
    },
  ];

  const userMenu: MenuProps = {
    items: userMenuItems,
    onClick: onClickMenu,
  };

  return (
    <Dropdown menu={userMenu}>
      <Avatar
        src={userData?.profile_photo_url ? (
          <Image
            preview={false}
            src={userData?.profile_photo_url}
          />
        ) : <DefaultAvatarImage src={getDefaultAvatar(userData.id)} />}
        icon={<UserOutlined style={{ color: 'var(--text-secondary)' }} />}
        style={{ backgroundColor: 'var(--primary-4)' }}
      />
    </Dropdown>
  );
}

function SoundMenu() {
  const { toggleMute, isMuted } = useSoundContext();
  const handleOnClickSound = () => {
    toggleMute();
  };
  return (
    <SoundIconContainer onClick={handleOnClickSound}>
      {isMuted ? <StyledFontAwesomeIcon icon={faVolumeMute} />
        : <StyledFontAwesomeIcon icon={faVolumeUp} />}
    </SoundIconContainer>
  );
}

type MenuLayoutProps = {
  isLoggedIn?: boolean;
  children: JSX.Element,
};

export default function MenuLayout({
  isLoggedIn = true,
  children,
}: MenuLayoutProps) {
  // const [defaultSelectedMenuItem, setDefaultSelectedMenuItem] = useState([]);
  const { user, signOut } = useAuthContext();
  const navigate = useNavigate();
  const { windowWidth } = useWindowSize();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[]>([]);
  const location = useLocation();
  const { playSound, isPlaying } = useSoundContext();

  const menuItems = [
    { label: 'JUEGOS', key: 'games', path: '/' },
    { label: 'CONDICIONES', key: 'termsAndConditions', path: '/terms-and-conditions' },
    ...(!isLoggedIn ? [
      { label: 'REGISTRO', key: 'signUp', path: '/signup' },
      { label: 'INGRESAR', key: 'signIn', path: '/signin' },
    ] : [
      { label: 'MI CUENTA', key: 'account', path: '/account' },
      { label: 'RANKING', key: 'ranking', path: '/ranking' },
    ]),
    { label: 'CONTACTO', key: 'contactus', path: '/contactus' },
  ];

  useEffect(() => {
    if (menuItems.length > 0) {
      const currentPath = (
        location.pathname.endsWith('/')
                && location.pathname !== '/' ? location.pathname.slice(0, -1)
          : location.pathname
      );
      const newSelectedItem = menuItems.find(
        (item) => currentPath === item.path,
      );
      if (newSelectedItem) {
        setSelectedMenuKeys([newSelectedItem.key]);
      } else {
        setSelectedMenuKeys([]);
      }
    }
  }, [location]);

  useEffect(() => {
    if (isLoggedIn && !isPlaying.clock) {
      playSound('gameMusic', gameMusic, true);
    }
  }, [isLoggedIn]);

  const onClickMenu: MenuClickEventHandler = ({ key }) => {
    if (key === 'logout' && signOut) {
      signOut();
    } else {
      const newSelectedItem = menuItems.find(
        (item) => item.key === key,
      );
      if (newSelectedItem) {
        navigate(newSelectedItem.path);
        setSelectedMenuKeys([key]);
      }
    }
  };

  const defaultHeader = (
    <HeaderContent height={menuHeight}>
      <LeftMenu width={280} height={menuHeight}>
        <Row justify="space-between" align="middle">
          <Col>
            <MenuItemLink to="/">JUEGOS</MenuItemLink>
          </Col>
          <Col>
            <MenuItemLink to="/terms-and-conditions">CONDICIONES</MenuItemLink>
          </Col>
        </Row>
      </LeftMenu>

      <Logo src={logo} onClick={() => navigate('/')} />

      {isLoggedIn ? (
        <UserMenuContainer>
          <UserMenu userData={user!} onClickMenu={onClickMenu} />
        </UserMenuContainer>
      ) : (
        <RightMenu width={420} height={menuHeight}>
          <Row justify="space-between" align="middle">
            <Col>
              <MenuItemLink to="/signup">REGISTRO</MenuItemLink>
            </Col>
            <Col>
              <MenuItemLink to="/signin">INGRESAR</MenuItemLink>
            </Col>
            <Col>
              <MenuItemLink to="/contactus">CONTACTO</MenuItemLink>
            </Col>
          </Row>
        </RightMenu>
      )}
    </HeaderContent>
  );

  const smallScreenHeader = (
    <HeaderContent height={menuHeight}>
      <Logo
        src={isotype}
        onClick={() => navigate('/')}
        style={{
          left: location.pathname === '/' ? '50%' : '10%',
        }}
      />
      <SiderButtonContainer>
        <Button
          type="link"
          size="large"
          onClick={() => setCollapsed(false)}
          style={{ color: 'var(--white)', fontSize: 20 }}
        >
          <MenuOutlined />
        </Button>
      </SiderButtonContainer>
    </HeaderContent>
  );

  const smallScreenMenu = (
    <>
      <Header>
        <Row justify="end">
          <Col>
            <Button
              type="link"
              onClick={() => setCollapsed(true)}
              style={{ color: 'var(--white)', fontSize: 20 }}
            >
              <CloseOutlined />
            </Button>
          </Col>
        </Row>
      </Header>

      <Menu
        theme="light"
        mode="inline"
        selectedKeys={selectedMenuKeys}
        items={[
          ...menuItems,
          ...(isLoggedIn ? [{
            icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
            label: 'Cerrar sesión',
            key: 'logout',
          }] : []),
        ]}
        onClick={(props) => {
          onClickMenu(props);
          setCollapsed(true);
        }}
      />
    </>
  );

  return (
    <Layout style={{ minHeight: '100svh' }}>
      <Layout>
        <Header>
          {windowWidth && windowWidth >= 1100 ? defaultHeader : smallScreenHeader}
        </Header>
        <Content>
          {children}
          <SoundMenu />
        </Content>
      </Layout>

      {windowWidth && windowWidth < 1100 && (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={280}
          collapsedWidth={0}
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
            height: '100svh',
            position: 'fixed',
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >
          {smallScreenMenu}
        </Sider>
      )}
    </Layout>
  );
}
