import React from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import { ModalProps } from 'antd/lib/modal/Modal';
import { menuHeight } from '../../../constants/ui';

interface CustomModalProps {
  backgroundImage: string;
  backgroundImagePortrait: string;
}

export const CustomModal = styled(Modal)<CustomModalProps>`
  top: ${menuHeight / 2}px !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: transparent;
  background-image: url(${(props) => props.backgroundImage});
  background-size: 100% 100%;
  width: 800px !important;
  height: 480px;
  
  .ant-modal-content {
    background-color: transparent;
    box-shadow: unset;
    margin: 20px 45px 45px;
    height: calc(100% - 65px);
  }

  .ant-modal-body {
    font-family: "Montserrat", serif;
    font-size: 13px;
    font-weight: bold;
    white-space: initial;
    text-align: center;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h1 {
    font-size: 54px; !important;
    margin: 0 !important;
  }
  h3 {
    margin: 0 !important;
  }
  h5 {
    margin: 0 !important;
  }

  @media only screen and (max-height: 576px) and (orientation: landscape) {
    max-width: calc(100% - 30px);
    height: calc(100% - 90px - 20px);
    width: auto !important;
    aspect-ratio: 1.6;
    
    .ant-modal-content {
      height: calc(100% - 40px);
      margin: 10px 10px 30px;
    }
    
    .ant-modal-body {
      padding: 10px;
    }
    h3 {
      font-size: 16px !important;
    }
    h5 {
      font-size: 12px !important;
    }
  };

  @media only screen and (max-height: 470px) and (orientation: landscape) {
    h1 {
      font-size: 42px; !important;
    }
  };

  @media only screen and (max-height: 400px) and (orientation: landscape) {
    h1 {
      font-size: 32px; !important;
    }
    h3 {
      font-size: 12px !important;
    }
    h5 {
      font-size: 9px !important;
    }
  };

  @media only screen and (orientation: portrait) {
    background-image: url(${(props) => props.backgroundImagePortrait});
    max-width: calc(100% - 30px);
    max-height: calc(100% - 90px - 20px);
    width: auto !important;
    height: auto;
    aspect-ratio: 0.58;
  };
  
  @media only screen and (max-width : 576px) and (orientation: portrait) {
    .ant-modal-content {
      height: calc(100% - 50px);
      margin: 20px 10px 30px;
    }

    h1 {
      font-size: 28px !important;
    }
    h3 {
      font-size: 18px !important;
    }
    h5 {
      font-size: 14px !important;
    }
  };
`;

interface ModalButtonProps {
  backgroundColor?: string;
  color?: string;
  paddingHorizontal?: number;
}

export const ModalButton = styled(Button)<ModalButtonProps>`
  &.ant-btn-round.ant-btn-lg {
    background: ${(props) => props.backgroundColor || '#df1d2a'};
    color:  ${(props) => props.color || 'var(--primary-3)'};
    font-size: 14px !important;
    padding-left: ${(props) => props.paddingHorizontal || 40}px;
    padding-right: ${(props) => props.paddingHorizontal || 40}px;
  }

  @media only screen and (max-height: 576px) and (orientation: landscape) {
    &.ant-btn-round.ant-btn-lg {
      height: 34px;
      padding: 3.2px 25px;
      font-size: 12px !important;
      line-height: 1.19;
    }
  };
  
  @media only screen and (max-height: 400px) and (orientation: landscape) {
    &.ant-btn-round.ant-btn-lg {
      height: 24px;
      padding: 3.2px 20px;
      font-size: 12px !important;
      line-height: 1.28;
    }
  };
  
  @media only screen and (max-height: 600px) and (orientation: portrait) {
    &.ant-btn-round.ant-btn-lg {
      height: 24px;
      padding: 3.2px 10px;
      font-size: 10px !important;
      line-height: 1.28;
    }
  };
`;

interface CustomBackgroundModalProps extends ModalProps {
  backgroundImage: string;
  backgroundImagePortrait: string;
  onClose?: () => void;
}

export default function CustomBackgroundModal({ onClose, ...props }: CustomBackgroundModalProps) {
  return (
    <CustomModal
      open
      centered
      closable
      onCancel={onClose}
      maskClosable={false}
      mask={false}
      footer={null}
      {...props}
    />
  );
}

interface PlainImageModalProps {
  backgroundImage: string;
}

export const PlainImageModal = styled(Modal)<PlainImageModalProps>`
  top: ${menuHeight / 2}px !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: transparent;
  background-image: url(${(props) => props.backgroundImage});
  background-size: 100% 100%;
  width: 500px !important;
  height: 300px;
  
  .ant-modal-content {
    background-color: transparent;
    box-shadow: unset;
    margin: 25px 45px;
    height: calc(100% - 90px);
  }
  
  .ant-modal-body {
    font-family: "Montserrat", serif;
    font-size: 13px;
    font-weight: bold;
    white-space: initial;
    text-align: center;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-height: 576px) and (orientation: landscape) {
    max-width: calc(100% - 30px);
    height: calc(100% - 90px - 20px);
    width: auto !important;
    aspect-ratio: 1.66;
    
    .ant-modal-content {
      height: calc(100% - 50px);
      margin: 10px 10px 40px;
    }
  };

  @media only screen and (max-width: 500px) and (orientation: portrait) {
    width: calc(100% - 30px);
    max-height: calc(100% - 90px - 20px);
    height: auto;
    aspect-ratio: 1.66;
    
    .ant-modal-content {
      height: calc(100% - 50px);
      margin: 10px 10px 40px;
    }
  };
`;

interface SmallBackgroundModalProps extends ModalProps {
  backgroundImage: string;
  onClose: () => void;
}

export function SmallBackgroundModal({ onClose, ...props }: SmallBackgroundModalProps) {
  return (
    <PlainImageModal
      open
      centered
      closable
      onCancel={onClose}
      maskClosable={false}
      mask={false}
      footer={null}
      {...props}
    />
  );
}
