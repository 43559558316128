import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';
export const renderDateTimeWithFormat = (value: string, format: string = dateFormat) => {
  moment.locale('es');
  const date = moment(value);
  return date.format(format);
};

export const renderDate = (value: string | null) => {
  if (!value) return null;
  return renderDateTimeWithFormat(value, dateFormat);
};
