import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

interface ContainerProps {
  height?: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: ${(props) => props.height || 'calc(100vh - 64px - 64px)'};
`;

interface LoadingProps {
  height?: string;
}

export default function Loading({ height }: LoadingProps) {
  return (
    <Container height={height}>
      <LoadingOutlined style={{ margin: 'auto', fontSize: 40, color: 'var(--accent-color)' }} />
    </Container>
  );
}
