import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { menuHeight } from '../../constants/ui';
import background from '../../assets/background-ppt-1.png';
import { useAuthContext } from '../../AuthProvider';
import { axiosInstance } from '../../utils/axios-instance';

type GameHeaderProps = {
  headerImage: string;
};

export default function GameHeader({
  headerImage,
}: GameHeaderProps) {
  const { user, setUser } = useAuthContext();

  useEffect(() => {
    // Fetch user data on render
    axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/users/me/`,
    ).then((response) => {
      setUser(response.data);
    });
  }, []);

  return (
    <div style={{
      position: 'fixed',
      top: 'calc(90px / 2)',
      right: 90,
      transform: 'translateY(-50%)',
    }}
    >
      <Row gutter={32} align="middle">
        <Col
          style={{
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
            backgroundImage: `url(${headerImage || background})`,
            height: menuHeight - 30,
            minWidth: '180px',
          }}
        />
        <Col
          style={{
            color: 'white',
            fontSize: 22,
            height: menuHeight - 30,
            lineHeight: `${menuHeight - 30}px`,
          }}
        >
          <span style={{ fontWeight: 'bold' }}>SALDO</span>
          <span>{` $${user?.wallet.total_balance.toLocaleString('es-CL')}`}</span>
        </Col>
      </Row>
    </div>
  );
}
