import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { History, Update } from 'history';

export const useLocationListener = (callback: (args: Update) => void) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  useEffect(() => {
    const listener = (update: Update) => {
      callback(update);
    };

    return navigator.listen(listener);
  }, [callback, navigator]);
};
