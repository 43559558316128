import React from 'react';
import { Moment } from 'moment';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useCountdown } from '../../hooks/useCountdown';
import { useServerDateTimeContext } from '../../ServerDateTimeProvider';

const { Title } = Typography;

const CountdownTimerContainer = styled.div`  
  font-family: "OpenSans", serif;
  font-weight: 300;
  background-color: #f5eb3e;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  border-top: 3px solid var(--text-primary);
  border-bottom: 3px solid var(--text-primary);
  border-radius: 28px;
  line-height: 1.87;
  width: 300px !important;
  height: auto;
  box-shadow: 0 24px 0 0 #b7b537, 0 27px 0 0 var(--text-primary);

  position: absolute;
  top: calc(50% - 12px);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;

  h1.ant-typography {
    font-size: 190px;
    margin: -25px 0 0;
    font-weight: normal;
  }

  h4.ant-typography {
    font-size: 22px;
    margin: 25px 0 0;
    font-weight: normal;
  }
  
  @media only screen and (max-height: 800px) and (orientation: portrait) {
    top: calc(50% - 10px);
    min-width: unset;
    width: 240px !important;
    box-shadow: 0 20px 0 0 #b7b537, 0 23px 0 0 var(--text-primary);
    
    .ant-row {
      row-gap: 0 !important;
    }
    
    h1.ant-typography {
      font-size: 160px;
    }

    h4.ant-typography {
      font-size: 18px;
    }
  }
  
  @media only screen and (max-height: 380px) {
    top: calc(50% - 10px);
    min-width: unset;
    width: 200px !important;
    box-shadow: 0 17px 0 0 #b7b537, 0 20px 0 0 var(--text-primary);
    
    .ant-row {
      row-gap: 0 !important;
    }
    
    h1.ant-typography {
      font-size: 130px;
      margin: -20px 0 0;
    }

    h4.ant-typography {
      font-size: 15px;
      margin: 20px 0 0;
    }
  }
`;

interface PreCountdownTimerProps {
  target: Moment;
}
export function PreCountdownTimer({ target }: PreCountdownTimerProps) {
  const { getDatetime } = useServerDateTimeContext();
  const { seconds } = useCountdown(target, getDatetime);

  return seconds !== undefined ? (
    <CountdownTimerContainer>
      <Title
        level={4}
        style={{ fontFamily: 'Pasti, serif' }}
      >
        EL JUEGO COMENZARÁ EN:
      </Title>
      <Title>{seconds}</Title>
    </CountdownTimerContainer>
  ) : <div />;
}
