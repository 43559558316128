import React from 'react';
import styled from 'styled-components';

import backgroundTheNumber from '../../assets/background-thenumber.png';
import GameBackground from '../../components/games/GameBackground';
import TheNumberWinnerModal from '../../components/games/modals/number/TheNumberWinnerModal';

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  font-family: "Pasti", serif;
`;

export default function TestPopup() {
  return (
    <GameBackground backgroundImage={backgroundTheNumber}>
      <Content>
        <TheNumberWinnerModal
          userParticipation={{
            id: 1,
            amount_earned: 1000,
            user: {
              id: 1,
              username: 'user',
              email: 'email@email.com',
              email_verified: true,
              full_name: '',
              first_name: '',
              last_name: '',
              accepted_current_terms_and_conditions: true,
              game_stats: [],
              wallet: {
                total_balance: 0,
                balance: 0,
                promotional_balance: 0,
                amount_pending_withdrawal: 0,
                latest_modification: '',
              },
            },
            is_winner: true,
            status: 'inscrito',
            properties: {},
          }}
          action={() => {}}
        />
      </Content>
    </GameBackground>
  );
}
