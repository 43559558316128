import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Statistic,
  Table,
  Tabs,
  Typography,
} from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { LoadingOutlined } from '@ant-design/icons';

import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { axiosInstance } from '../../utils/axios-instance';
import { Bank, Transaction, User } from '../../types/base';
import Loading from '../../components/Loading';
import {
  defaultPagination,
  defaultParams,
  handleTableChange,
  renderAmount,
} from '../../utils/tables';
import { renderDateTimeWithFormat } from '../../utils/dates';
import useWindowSize from '../../hooks/useWindowSize';
import { fetchUserData } from '../../hooks/useUserData';
import { isValidRut } from '../../utils/rut';
import { RutInput } from '../../components/RutInput';
import { useAuthContext } from '../../AuthProvider';

const { Paragraph, Text } = Typography;

const MIN_WITHDRAWAL_AMOUNT = 500;

interface FetchUserTransactionsParams {
  limit?: number;
  offset?: number;
}

interface TransactionModalFormProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

interface DepositValues {
  amount: string;
  coupon: string;
}

interface DepositModalFormProps extends TransactionModalFormProps {
  includePromotionalCode?: boolean;
}

function DepositModalForm({
  showModal,
  setShowModal,
  includePromotionalCode = false,
}: DepositModalFormProps) {
  const [depositForm] = Form.useForm();
  const [loadingDeposit, setLoadingDeposit] = useState<boolean>(false);

  const makeDeposit = (values: DepositValues) => {
    setLoadingDeposit(true);
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/transactions/deposit/`,
      values,
    ).then((response) => {
      const transaction = response.data;
      if (transaction.pagarapido_payment_link) {
        window.location.replace(transaction.pagarapido_payment_link);
      }
      setLoadingDeposit(false);
    }).catch((e) => {
      const errorMessages = (e.response?.data as RedeemCouponFormResponseData);
      if (errorMessages.detail) {
        message.error(errorMessages.detail);
      } else {
        message.error('Ocurrió un error, por favor intenta nuevamente');
      }
      setLoadingDeposit(false);
    });
  };

  return (
    <Modal
      open={showModal}
      footer={null}
      onCancel={() => {
        setShowModal(false);
        depositForm.resetFields();
      }}
      width={400}
    >
      <Form
        form={depositForm}
        onFinish={makeDeposit}
        size="small"
      >
        <div style={{ margin: 10 }}>
          <Row gutter={[8, 24]} align="middle">
            <Col span={24}>
              <Text strong>Monto de carga:</Text>
            </Col>
            <Col span={24}>
              <Form.Item
                name="amount"
                style={{ margin: 0 }}
              >
                <InputNumber addonBefore="$" min={1} placeholder="Ingresa un monto" style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            {includePromotionalCode && (
              <>
                <Col span={24}>
                  <Text strong>Código promocional:</Text>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="coupon"
                    style={{ margin: 0 }}
                  >
                    <Input placeholder="Ingresa el código promocional" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={24}>
              <Button type="primary" htmlType="submit" block>
                {loadingDeposit ? (
                  <LoadingOutlined />
                ) : 'CARGAR SALDO'}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}

interface RedeemCouponModalFormProps extends TransactionModalFormProps {
  onComplete: () => void;
}

interface CouponValues {
  code: string;
}

type RedeemCouponFormResponseData = {
  detail?: string;
};

function RedeemCouponModalForm({
  showModal,
  setShowModal,
  onComplete,
}: RedeemCouponModalFormProps) {
  const [redeemCouponForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const redeemCoupon = (values: CouponValues) => {
    setLoading(true);
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/coupons/${values.code}/redeem_coupon/`,
    ).then(() => {
      setLoading(false);
      onComplete();
      setShowModal(false);
    }).catch((e) => {
      const errorMessages = (e.response?.data as RedeemCouponFormResponseData);
      if (e.response.status === 404) {
        message.error('Cupón inválido');
      } else if (errorMessages.detail) {
        message.error(errorMessages.detail);
      } else {
        message.error('Ocurrió un error, por favor intenta nuevamente');
      }
      setLoading(false);
    });
  };

  return (
    <Modal
      open={showModal}
      footer={null}
      onCancel={() => {
        setShowModal(false);
        redeemCouponForm.resetFields();
      }}
      width={400}
    >
      <Form
        form={redeemCouponForm}
        onFinish={redeemCoupon}
        size="small"
      >
        <div style={{ margin: 10 }}>
          <Row gutter={[8, 24]} align="middle">
            <Col span={24}>
              <Text strong>Código promocional:</Text>
            </Col>
            <Col span={24}>
              <Form.Item
                name="code"
                style={{ margin: 0 }}
              >
                <Input placeholder="Ingresa el código promocional" style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Button type="primary" htmlType="submit" block>
                {loading ? (
                  <LoadingOutlined />
                ) : 'CANJEAR'}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}

interface WithdrawalValues {
  amount: string;
  rut: string;
  name: string;
  bank: number;
  account_number: string;
}

type WithdrawalFormResponseData = {
  user?: string[];
  amount?: string[];
  properties?: string[];
};

interface WithdrawalModalFormProps extends TransactionModalFormProps {
  userData: User;
  banks: Bank[];
  onComplete: () => void;
}

function WithdrawalModalForm({
  showModal, setShowModal, userData, onComplete, banks,
}: WithdrawalModalFormProps) {
  const [withdrawalForm] = Form.useForm();
  const [loadingWithdrawal, setLoadingWithdrawal] = useState<boolean>(false);
  const [completedRequest, setCompletedRequest] = useState<boolean>(false);

  const requestWithdrawal = (values: WithdrawalValues) => {
    setLoadingWithdrawal(true);
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/transactions/request_withdrawal/`,
      {
        amount: -values.amount,
        properties: {
          bank_account_details: {
            name: values.name,
            rut: values.rut?.replaceAll('.', ''),
            bank_id: values.bank,
            account_number: values.account_number,
          },
        },
      },
    ).then((response) => {
      const transaction: Transaction = response.data;
      if (transaction.id) {
        setCompletedRequest(true);
      }
      setLoadingWithdrawal(false);
    }).catch((e) => {
      const errorMessages = (e.response?.data as WithdrawalFormResponseData);
      if (errorMessages.user) {
        errorMessages.user.forEach((errorMessage) => message.error(errorMessage));
      } else if (errorMessages.amount) {
        errorMessages.amount.forEach((errorMessage) => message.error(errorMessage));
      } else if (errorMessages.properties) {
        errorMessages.properties.forEach((errorMessage) => message.error(errorMessage));
      } else {
        message.error('Ocurrió un error, por favor intenta nuevamente');
      }
      setLoadingWithdrawal(false);
    });
  };

  return (
    <Modal
      open={showModal}
      footer={null}
      onCancel={() => {
        setShowModal(false);
        if (completedRequest) {
          onComplete();
          setCompletedRequest(false);
        }
        withdrawalForm.resetFields();
      }}
      width={400}
    >
      {completedRequest ? (
        <div style={{ textAlign: 'center' }}>
          <Paragraph>
            <Text type="secondary">
              Se realizó la solicitud de retiro de saldo.
            </Text>
          </Paragraph>
          <Paragraph>
            <Text type="secondary">
              La transacción toma algunos minutos, te
              notificaremos por correo electrónico cuando esté completada.
            </Text>
          </Paragraph>
        </div>
      ) : (
        <Form
          form={withdrawalForm}
          onFinish={requestWithdrawal}
          size="small"
        >
          <div style={{ margin: 10 }}>
            <Row gutter={[8, 24]} align="middle">
              <Col span={24}>
                <Text strong>Monto a retirar:</Text>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="amount"
                  style={{ margin: 0 }}
                >
                  <InputNumber
                    addonBefore="$"
                    min={MIN_WITHDRAWAL_AMOUNT}
                    max={userData.wallet.balance}
                    placeholder="Ingresa un monto"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>Datos de la cuenta:</Text>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="rut"
                  style={{ margin: 0 }}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (!isValidRut(value)) {
                          return Promise.reject(new Error('El RUT ingresado es inválido'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <RutInput placeholder="RUT" style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="name"
                  style={{ margin: 0 }}
                >
                  <Input placeholder="Nombre y apellido" style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="bank"
                  style={{ margin: 0 }}
                >
                  <Select
                    placeholder="Banco"
                    fieldNames={{ label: 'name', value: 'id' }}
                    options={banks}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="account_number"
                  style={{ margin: 0 }}
                >
                  <Input placeholder="Nº de Cuenta" style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Button type="primary" htmlType="submit" block>
                  {loadingWithdrawal ? (
                    <LoadingOutlined />
                  ) : 'SOLICITAR RETIRO'}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Modal>
  );
}

const ButtonsContainer = styled.div`
  .ant-btn {
    @media only screen and (max-width: 390px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 350px) {
      font-size: 10px;
    }
    @media only screen and (max-width: 645px) and (min-width: 576px) and (orientation: landscape) {
      font-size: 10px;
    }
  }
`;

function Wallet() {
  const { windowWidth } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, setUser } = useAuthContext();
  const [banks, setBanks] = useState<Bank[]>([]);
  const [userTransactions, setUserTransactions] = useState<Transaction[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [fetchParams, setFetchParams] = useState<FetchUserTransactionsParams>(defaultParams);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [showDepositForm, setShowDepositForm] = useState<boolean>(false);
  const [showDepositWithCouponForm, setShowDepositWithCouponForm] = useState<boolean>(false);
  const [showRedeemCouponForm, setShowRedeemCouponForm] = useState<boolean>(false);
  const [showWithdrawalForm, setShowWithdrawalForm] = useState<boolean>(false);
  const [transactionBalanceType, setTransactionBalanceType] = useState<string>('normal');
  const [loadingTransactions, setLoadingTransactions] = useState<boolean>(false);

  const fetchUserTransactions = (
    newPagination: TablePaginationConfig,
    params: FetchUserTransactionsParams = {},
  ) => new Promise(
    (resolve: (value: Transaction[]) => void, reject) => {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/transactions/`,
        {
          params: {
            ...params,
            balance_type: transactionBalanceType,
          },
        },
      ).then((response) => {
        setUserTransactions(response.data.results);
        setPagination({
          ...newPagination,
          total: response.data.count,
        });
        setFetchParams(params);
        resolve(response.data.results);
      }).catch((e) => {
        Modal.error({
          title: 'Ocurrió un error al obtener los datos',
          content: 'Lo sentimos, ocurrió un error al obtener los datos. Por favor intenta nuevamente.',
        });
        reject(e);
      });
    },
  );

  const checkPendingWithdrawals = () => new Promise(
    (resolve: (value: Transaction) => void, reject) => {
      axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/transactions/check_user_pending_withdrawals_status/`,
      ).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        Modal.error({
          title: 'Ocurrió un error al obtener los datos',
          content: 'Lo sentimos, ocurrió un error al obtener los datos. Por favor intenta nuevamente.',
        });
        reject(e);
      });
    },
  );

  const fetchInitialData = () => {
    checkPendingWithdrawals().then(() => {
      fetchUserTransactions(pagination, fetchParams).then(() => {
        fetchUserData().then((newUserData) => {
          setUser(newUserData);
          axiosInstance.get(
            `${process.env.REACT_APP_API_URL}/transactions/banks/`,
          ).then((response) => {
            setBanks(response.data);
            setLoadingData(false);
          }).catch(() => {
            Modal.error({
              title: 'Ocurrió un error al obtener los datos',
              content: 'Lo sentimos, ocurrió un error al obtener los datos. Por favor intenta nuevamente.',
            });
          });
        });
      });
    });
  };

  useEffect(() => {
    if (searchParams.get('transaction')) {
      const transactionId = searchParams.get('transaction');
      axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/transactions/${transactionId}/confirm_deposit_status/`,
      ).then((response) => {
        const transaction: Transaction = response.data;
        if (transaction.status === 'completada') {
          message.success('Se realizó correctamente la carga');
        } else {
          message.warning('No se realizó la carga');
        }
        setSearchParams({ tab: 'wallet' });
        fetchInitialData();
      }).catch(() => {
        message.error('Ocurrió un error, por favor intenta nuevamente');
      });
    } else {
      fetchInitialData();
    }
  }, [searchParams]);

  useEffect(() => {
    setLoadingTransactions(true);
    fetchUserTransactions(
      defaultPagination,
      defaultParams,
    ).then(() => setLoadingTransactions(false));
  }, [transactionBalanceType]);

  const columns = [
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
      render: renderAmount,
    },
    {
      title: 'Saldo',
      dataIndex: 'new_balance',
      key: 'new_balance',
      render: (value: number) => `$${value}`,
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: string) => renderDateTimeWithFormat(value, 'DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const promotionalBalanceColumns = [
    {
      title: 'Monto',
      dataIndex: 'promotional_amount',
      key: 'promotional_amount',
      render: renderAmount,
    },
    {
      title: 'Saldo',
      dataIndex: 'new_promotional_balance',
      key: 'new_promotional_balance',
      render: (value: number) => `$${value}`,
    },
    {
      title: 'Fecha',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: string) => renderDateTimeWithFormat(value, 'DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const onTableChange = (
    newPagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: (SorterResult<Transaction> | SorterResult<Transaction>[]),
  ) => {
    handleTableChange(
      newPagination,
      filters,
      sorter,
      (pagConfig, params) => {
        fetchUserTransactions(pagConfig, params).then(() => setLoadingData(false));
      },
    );
  };

  const tabs = [
    {
      label: 'Saldo',
      key: 'normal',
      children: (
        <>
          <PageHeader
            className="section-header"
            title="Historial de transacciones"

          />
          {loadingTransactions ? <Loading height="40px" /> : (
            <Table
              rowKey="id"
              dataSource={userTransactions}
              columns={columns}
              pagination={pagination}
              onChange={onTableChange}
              scroll={windowWidth < 992 ? { x: true } : undefined}
            />
          )}
        </>
      ),
    },
    {
      label: 'Saldo promocional',
      key: 'promotional',
      children: (
        <>
          <PageHeader
            className="section-header"
            title="Historial de transacciones"

          />
          {loadingTransactions ? <Loading height="40px" /> : (
            <Table
              rowKey="id"
              dataSource={userTransactions}
              columns={promotionalBalanceColumns}
              pagination={pagination}
              onChange={onTableChange}
              scroll={windowWidth < 992 ? { x: true } : undefined}
            />
          )}
        </>
      ),
    },
  ];

  return loadingData || !windowWidth || !user ? <Loading height="60px" /> : (
    <div>
      <Card>
        <Row gutter={16}>
          <Col span={12}>
            <Statistic title="Saldo actual" value={`$${user.wallet.balance}`} />
          </Col>
          <Col span={12}>
            <Statistic title="Saldo promocional" value={`$${user.wallet.promotional_balance}`} />
          </Col>
          {user.wallet.amount_pending_withdrawal > 0 && (
            <Col span={8}>
              <Statistic
                title="Monto pendiente retiro"
                value={`$${user.wallet.amount_pending_withdrawal}`}
              />
            </Col>
          )}
        </Row>
        <ButtonsContainer>
          <Row gutter={[0, 16]} justify="center" style={{ marginTop: 16 }}>
            <Col span={24}>
              <Button
                onClick={() => {
                  setShowDepositForm(true);
                }}
                block
              >
                CARGAR SALDO
              </Button>
            </Col>
            <Col span={24}>
              <Button
                onClick={() => {
                  setShowDepositWithCouponForm(true);
                }}
                block
              >
                CARGAR SALDO CON PROMOCIÓN
              </Button>
            </Col>
            <Col span={24}>
              <Button
                onClick={() => {
                  setShowRedeemCouponForm(true);
                }}
                block
              >
                CANJEAR PROMOCIÓN
              </Button>
            </Col>
            <Col span={24}>
              <Button
                onClick={() => {
                  if (user.wallet.balance < MIN_WITHDRAWAL_AMOUNT) {
                    message.error(`El monto mínimo de retiro es $${MIN_WITHDRAWAL_AMOUNT}`);
                  } else {
                    setShowWithdrawalForm(true);
                  }
                }}
                block
              >
                RETIRAR SALDO
              </Button>
            </Col>
          </Row>
        </ButtonsContainer>
      </Card>

      <Tabs
        items={tabs}
        activeKey={transactionBalanceType}
        onChange={(activeKey) => {
          setTransactionBalanceType(activeKey);
        }}
        style={{ marginTop: 30 }}
      />

      <DepositModalForm
        showModal={showDepositForm}
        setShowModal={setShowDepositForm}
      />

      <DepositModalForm
        showModal={showDepositWithCouponForm}
        setShowModal={setShowDepositWithCouponForm}
        includePromotionalCode
      />

      <RedeemCouponModalForm
        showModal={showRedeemCouponForm}
        setShowModal={setShowRedeemCouponForm}
        onComplete={() => {
          fetchInitialData();
        }}
      />

      <WithdrawalModalForm
        showModal={showWithdrawalForm}
        setShowModal={setShowWithdrawalForm}
        userData={user}
        banks={banks}
        onComplete={() => {
          fetchInitialData();
        }}
      />
    </div>
  );
}

export default Wallet;
