import { useEffect, useState } from 'react';

import { noAuthAxios } from '../utils/axios-instance';

export type IServerDateTimeContext = {
  serverTimeDifference: number | null,
  getDatetime: () => Date,
};

export const useServerDateTime: () => IServerDateTimeContext = () => {
  const [serverTimeDifference, setServerTimeDifference] = useState<number | null>(null);

  function updateServerTimeDifference() {
    noAuthAxios.get(
      'https://date.neolab-cl.workers.dev/',
    ).then((response) => {
      const dateString: string = response.data;
      const localDateTime = new Date();
      const serverDateTime = new Date(dateString);
      setServerTimeDifference(serverDateTime.getTime() - localDateTime.getTime());
    });
  }

  function getDatetime() {
    if (serverTimeDifference) {
      return new Date(Date.now() + serverTimeDifference);
    }
    return new Date();
  }

  useEffect(() => {
    updateServerTimeDifference();

    const interval = setInterval(() => {
      updateServerTimeDifference();
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  return {
    serverTimeDifference,
    getDatetime,
  };
};
