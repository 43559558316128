import React, { useRef, useState } from 'react';
import {
  Button, Card, Carousel, Col, message, Row, Tooltip, Typography,
} from 'antd';
import type { CarouselRef } from 'antd/lib/carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import redBox from '../../assets/red-box.png';
import { menuHeight } from '../../constants/ui';
import arrowButtonLeft from '../../assets/arrow-button-left.png';
import arrowButtonRight from '../../assets/arrow-button-right.png';
import goldenButton from '../../assets/golden-button.png';
import { GameTable, Tournament } from '../../types/base';
import { renderNumericalValue } from '../../utils/tables';
import { axiosInstance } from '../../utils/axios-instance';
import { subscribeUser } from '../../utils/push-notifications';
import { useSoundContext } from '../SoundContext';
import play from '../../assets/sounds/play.mp3';

const { Title } = Typography;

const TableCard = styled(Card)`
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(${redBox});
  max-width: calc(100% - 20px);
  width: calc(calc(calc(calc(100vh - ${menuHeight}px - 40px) / 3) - 20px) * 1.8);
  height: calc(calc(calc(100vh - ${menuHeight}px - 40px) / 3) - 20px);

  @supports (height: 100svh) {
    width: calc(calc(calc(calc(100svh - ${menuHeight}px - 40px) / 3) - 20px) * 1.8);
    height: calc(calc(calc(100svh - ${menuHeight}px - 40px) / 3) - 20px);
  }

  margin: 10px auto;
  border: 0;
  font-family: "Pasti", serif;
  position: relative;

  .ant-card-body {
    width: 100%;
    height: 100%;
    padding: 0;
    position: relative;
  }

  .table-buttons > .ant-col:first-child {
    height: 65px;
  }

  .table-buttons > .ant-col:last-child {
    height: 65px;
  }

  @media only screen and (max-height: 768px) {
    h2.ant-typography {
      font-size: 20px;
    }

    .table-buttons > .ant-col:first-child {
      height: 55px;
    }

    .table-buttons > .ant-col:last-child {
      height: 55px;
    }
  }

  @media only screen and (max-height: 620px) {
    width: 350px;
    height: calc(350px / 1.8);
  }

  @media only screen and (max-width: 992px) and (orientation: portrait) {
    height: auto;
    aspect-ratio: 1.8;
  }

`;

const TotalPrize = styled.div`
  font-size: 20px;
  color: var(--black);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: transparent;
  background-image: url(${goldenButton});
  min-width: 150px;
  height: 36px;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  line-height: 1.87;
  margin-bottom: 30px;

  @media only screen and (max-height: 796px) {
    margin-bottom: 10px;
  }

  @media only screen and (max-height: 768px) {
    width: 70px;
    height: 30px;
    top: 15px;
    font-size: 18px;
  }

  @media only screen and (max-height: 620px) {
    margin-bottom: 25px;
  }
`;

interface ArrowButtonProps {
  direction: 'left' | 'right';
}

const ArrowButton = styled(Button)<ArrowButtonProps>`
  width: 50px;
  height: 50px;
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(${(props) => (props.direction === 'left' ? arrowButtonLeft : arrowButtonRight)});
  border: 0;
  position: absolute;
  top: 50%;
  left: ${(props) => (props.direction === 'left' ? '20px' : 'calc(100% - 20px)')};
  transform: translate(-50%, -50%);

  :hover, :focus, :active {
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(${(props) => (props.direction === 'left' ? arrowButtonLeft : arrowButtonRight)});
  }

  @media only screen and (max-height: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const CardContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 6% 12%;
`;

interface TableInfoButtonProps {
  color: string;
}

const TableInfoButton = styled(Button)<TableInfoButtonProps>`
  width: 80px;
  height: 35px;
  background: #aa262c;
  background: ${(props) => props.color};
  color: white;
  border: 2px solid black;
  font-size: 20px;
  margin-bottom: 5px;
  position: relative;
  border-radius: 12px;
  line-height: 1;
  padding-left: 0;
  padding-right: 0;

  :hover, :focus, :active {
    background: ${(props) => props.color};
    color: white;
    border: 2px solid black;
  }

  @media only screen and (max-height: 768px) {
    width: 60px;
    height: 30px;
    font-size: 15px;
    line-height: 1;
  }
`;

const TableButtonShadow = styled.div`
  content: '';
  position: absolute !important;
  width: 60px !important;
  height: 10px !important;
  margin-left: 5px !important;
  margin-right: 5px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  bottom: -5px;
  left: 0;
  z-index: -1;
  filter: blur(2px);

  @media only screen and (max-height: 768px) {
    width: 50px !important;
  }
`;

interface RoundButtonProps {
  color: string;
}

const RoundButton = styled(Button)<RoundButtonProps>`
  width: 55px;
  height: 55px;
  background: #aa262c;
  background: ${(props) => props.color};
  color: white;
  border: 2px solid black;
  font-size: 20px;
  line-height: 1.05;
  margin-bottom: 5px;
  position: relative;

  :hover, :focus, :active {
    background: ${(props) => props.color};
    color: white;
    border: 2px solid black;
  }

  @media only screen and (max-height: 768px) {
    width: 40px;
    height: 40px;
    font-size: 18px;
    line-height: 0.55;
  }
`;

const RoundButtonShadow = styled.div`
  content: '';
  position: absolute !important;
  width: 45px !important;
  height: 10px !important;
  margin-left: 5px !important;
  margin-right: 5px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  bottom: -5px;
  left: 0;
  z-index: -1;
  filter: blur(2px);

  @media only screen and (max-height: 768px) {
    width: 30px !important;
  }
`;

export interface TableGroup {
  entry_fee: number;
  tables: GameTable[];
}

interface GameTableProps {
  gameTable: GameTable;
  onJoinTournament: (tournament: Tournament) => void;
}

type JoinTournamentResponseData = {
  detail?: string;
};

function GameTableContent({ gameTable, onJoinTournament }: GameTableProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { playSound } = useSoundContext();

  const joinTournament = (tournamentId: string, registrationId?: string | undefined) => {
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/games/tournaments/${tournamentId}/join_tournament/`,
      {
        registration_id: registrationId,
      },
    ).then((response) => {
      const tournament = response.data;
      onJoinTournament(tournament);
    }).catch((e) => {
      const errorMessages = (e.response?.data as JoinTournamentResponseData);
      if (errorMessages.detail) {
        message.error(errorMessages.detail);
      } else {
        message.error('Ocurrió un error, por favor intenta nuevamente');
      }
      setLoading(false);
    });
  };

  const subscribeUserAndJoinTournament = (tournamentId: string) => {
    setLoading(true);
    subscribeUser().then((webPushDevice) => {
      joinTournament(tournamentId, webPushDevice.registration_id);
    }).catch(() => {
      joinTournament(tournamentId);
    });
  };

  return (
    <div key={gameTable.id}>
      <Row justify="center">
        <Tooltip
          placement="bottom"
          color="rgba(231, 231, 23, 0.9)"
          title="Premio de la mesa"
          autoAdjustOverflow
        >
          <TotalPrize>
            <span>
              $
              {renderNumericalValue(gameTable.expected_total_prize)}
            </span>
          </TotalPrize>
        </Tooltip>
      </Row>
      <Row justify="center">
        <Title level={2}>
          {gameTable.rounds > 1
            ? `${gameTable.rounds} rondas`
            : `${gameTable.number_of_players} jugadores`}
        </Title>
      </Row>
      <Row justify="center" align="bottom" gutter={8} className="table-buttons">
        <Col>
          <Tooltip
            placement="left"
            color="rgba(231, 231, 23, 0.85)"
            title="Monto de entrada"
            autoAdjustOverflow
          >
            <TableInfoButton
              color="#5aae74"
              size="large"
            >
              $
              {gameTable.entry_fee}
              <TableButtonShadow />
            </TableInfoButton>
          </Tooltip>
        </Col>
        <Col>
          <RoundButton
            id="playButton"
            color="var(--primary-2)"
            shape="circle"
            size="large"
            onClick={() => {
              playSound('play', play);
              setTimeout(() => {
                subscribeUserAndJoinTournament(gameTable.open_tournament.id);
              }, 700);
            }}
          >
            {loading ? <LoadingOutlined /> : <FontAwesomeIcon icon={faPlay} />}
            <RoundButtonShadow />
          </RoundButton>
        </Col>
        <Col>
          <Tooltip
            placement="right"
            color="rgba(231, 231, 23, 0.85)"
            title="Participantes de la mesa"
            autoAdjustOverflow
          >
            <TableInfoButton
              color="#cc3d49"
              size="large"
            >
              {gameTable.open_tournament.number_of_participants}
              /
              {gameTable.number_of_players}
              <TableButtonShadow />
            </TableInfoButton>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
}

interface TableSelectorProps {
  tableGroup: TableGroup;
  onJoinTournament: (tournament: Tournament) => void;
}

export default function TableSelector({ tableGroup, onJoinTournament }: TableSelectorProps) {
  const carousel = useRef<CarouselRef>(null);

  return (
    <TableCard>
      <CardContent>
        <Carousel
          ref={carousel}
          dots={false}
        >
          {tableGroup.tables.map((gameTable) => (
            <GameTableContent
              key={gameTable.entry_fee}
              gameTable={gameTable}
              onJoinTournament={onJoinTournament}
            />
          ))}
        </Carousel>

      </CardContent>
      <ArrowButton
        shape="circle"
        size="large"
        direction="left"
        onClick={() => {
          carousel.current?.prev();
        }}
      >
                &nbsp;
      </ArrowButton>
      <ArrowButton
        shape="circle"
        size="large"
        direction="right"
        onClick={() => {
          carousel.current?.next();
        }}
      >
                &nbsp;
      </ArrowButton>
    </TableCard>
  );
}
