import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Layout, Modal, Typography,
} from 'antd';
import DOMPurify from 'dompurify';

import { TermsAndConditions } from '../types/base';
import Loading from '../components/Loading';
import { noAuthAxios } from '../utils/axios-instance';
import { renderDate } from '../utils/dates';

const { Header } = Layout;
const { Paragraph, Text, Title } = Typography;

const ClearLayout = styled(Layout)`
  text-align: left;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
  background-color: transparent;
  .ant-layout, .ant-layout-header {
    background: transparent;
  }
`;

export default function ReadTermsAndConditions() {
  const [termsAndConditions, setTermsAndConditions] = useState<TermsAndConditions>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  useEffect(() => {
    noAuthAxios.get(
      `${process.env.REACT_APP_API_URL}/users/terms_and_conditions/`,
    ).then((response) => {
      setIsLoading(false);
      setTermsAndConditions(response.data);
    }).catch((e) => {
      setIsLoading(false);
      setError(e);
    });
  }, []);

  useEffect(() => {
    if (error && !showErrorModal) {
      setShowErrorModal(true);
      Modal.error({
        title: 'Ha ocurrido un error',
        content: 'Por favor intenta refrescando la pagina.',
        centered: true,
        onOk() {
          window.location.reload();
          setShowErrorModal(false);
        },
      });
    }
  }, [error]);

  return (
    <ClearLayout>
      <Header style={{ height: 'auto' }}>
        <Title
          level={3}
          style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}
        >
          Términos y Condiciones
        </Title>
      </Header>
      <Layout>
        {isLoading || !termsAndConditions ? <Loading /> : (
          <>
            <Text style={{ marginBottom: 20, textAlign: 'center' }}>
              Vigentes desde el:
              {' '}
              {renderDate(termsAndConditions.valid_from)}
            </Text>

            <Paragraph style={{ justifyContent: 'space-evenly' }}>
              <div
                style={{ fontFamily: 'sans-serif' }}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(termsAndConditions.content, {FORBID_ATTR: ['style']}) }}
              />
            </Paragraph>
          </>
        )}
      </Layout>
    </ClearLayout>
  );
}
