import React from 'react';
import { Modal, Row, Typography } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

interface CustomModalProps {
  primaryColor: string;
  secondaryColor: string;
}

export const CustomModal = styled(Modal)<CustomModalProps>`
  font-family: "Montserrat", serif;
  font-size: 20px;
  background-color: ${(props) => props.primaryColor};
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  border-top: 3px solid var(--text-primary);
  border-bottom: 3px solid var(--text-primary);
  border-radius: 28px;
  line-height: 1.87;
  box-shadow: 0 24px 0 0 ${(props) => props.secondaryColor}, 0 27px 0 0 var(--text-primary);
  width: auto !important;
  
  .ant-modal-content {
    background-color: transparent;
    box-shadow: unset;
    max-width: 450px;
    margin: auto;
  }
  
  .ant-modal-body {
    font-size: 18px;
    font-weight: bold;
    white-space: initial;
    text-align: center;
    padding: 0;
    margin: 10px 40px 50px;
  }
`;

type InsufficientFundsModalProps = {
  onClose: () => void;
};

export default function InsufficientFundsModal({ onClose }: InsufficientFundsModalProps) {
  return (
    <CustomModal
      open
      centered
      closable
      onCancel={onClose}
      mask={false}
      footer={null}
      primaryColor="#dee55d"
      secondaryColor="#b4ca34"
    >
      <Row justify="center" style={{ paddingTop: 40 }}>
        <Title level={3}>NO SE PUDO REALIZAR EL COBRO</Title>
      </Row>
      <Row justify="center">
        Para poder inscribirte carga saldo en tu billetera e intenta nuevamente.
      </Row>
    </CustomModal>
  );
}
