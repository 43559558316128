import React, {
  createContext, useContext, useState, ReactNode, useEffect, useRef, useCallback, useMemo,
} from 'react';
import click from '../assets/sounds/click.mp3';

interface AudioTrack {
  id: string;
  audio: HTMLAudioElement;
  loop: boolean;
}

interface ISoundContext {
  isPlaying: { [key: string]: boolean };
  isMuted: boolean;
  playSound: (id: string, soundFile: string, loop?: boolean) => void;
  stopSound: (id: string) => void;
  toggleMute: () => void;
}

const SoundContext = createContext<ISoundContext | undefined>(undefined);

export const useSoundContext = (): ISoundContext => {
  const context = useContext(SoundContext);
  if (!context) {
    throw new Error('useSoundContext must be used within a SoundProvider');
  }
  return context;
};

interface SoundProviderProps {
  children: ReactNode;
}

export function SoundProvider({ children }: SoundProviderProps) {
  const [isPlaying, setIsPlaying] = useState<{ [key: string]: boolean }>({});
  const [isMuted, setIsMuted] = useState<boolean>(() => {
    const savedMuteState = localStorage.getItem('isMuted');
    return savedMuteState ? JSON.parse(savedMuteState) : false;
  });
  const audioTracksRef = useRef<AudioTrack[]>([]);

  const playSound = useCallback((id: string, soundFile: string, loop = false) => {
    // Detener el audio anterior si existe
    const existingTrackIndex = audioTracksRef.current.findIndex((track) => track.id === id);
    if (existingTrackIndex !== -1) {
      const existingTrack = audioTracksRef.current[existingTrackIndex];
      if (existingTrack.audio instanceof HTMLAudioElement) {
        existingTrack.audio.pause();
        existingTrack.audio.currentTime = 0;
      }
    }

    // Crear y configurar el nuevo audio
    const newAudio = new Audio(soundFile);
    newAudio.loop = loop;

    // Reproducir en silencio si está muteado
    newAudio.muted = isMuted;

    // Añadir manejador para eliminar del buffer cuando termine
    newAudio.onended = () => {
      // Eliminar el audio del buffer y el estado
      audioTracksRef.current = audioTracksRef.current.filter((track) => track.id !== id);
      setIsPlaying((prev) => {
        const { [id]: removedTrack, ...rest } = prev;
        return rest;
      });
    };

    // Reproducir el audio (aunque esté silenciado)
    newAudio.play();

    // Actualizar el mapa de pistas de audio
    const newTrack = { id, audio: newAudio, loop };
    if (existingTrackIndex !== -1) {
      audioTracksRef.current[existingTrackIndex] = newTrack;
    } else {
      audioTracksRef.current.push(newTrack);
    }

    setIsPlaying((prev) => ({ ...prev, [id]: true }));
  }, [isMuted]);

  const stopSound = useCallback((id: string) => {
    const trackIndex = audioTracksRef.current.findIndex((track) => track.id === id);
    if (trackIndex !== -1) {
      const track = audioTracksRef.current[trackIndex];
      if (track.audio instanceof HTMLAudioElement) {
        track.audio.pause();
        track.audio.currentTime = 0;
        setIsPlaying((prev) => ({ ...prev, [id]: false }));
      }
    }
  }, []);

  const toggleMute = useCallback(() => {
    const audioTracksAux = [...audioTracksRef.current];
    setIsMuted((prevMuted) => {
      const newMutedState = !prevMuted;
      audioTracksRef.current.forEach((track, index) => {
        if (track.audio instanceof HTMLAudioElement) {
          audioTracksAux[index].audio.muted = newMutedState;
          // No se pausa la reproducción cuando se mutea
          if (!newMutedState && track.audio.paused && isPlaying[track.id]) {
            audioTracksAux[index].audio.play();
          }
        }
      });
      audioTracksRef.current = [...audioTracksAux];
      localStorage.setItem('isMuted', JSON.stringify(newMutedState));
      return newMutedState;
    });
  }, [isPlaying]);

  useEffect(() => {
    const audioTracksAux = [...audioTracksRef.current];
    audioTracksRef.current.forEach((track, index) => {
      if (track.audio instanceof HTMLAudioElement) {
        audioTracksAux[index].audio.muted = isMuted;
        if (isMuted) {
          audioTracksAux[index].audio.pause();
        } else if (isPlaying[track.id] && track.audio.paused) {
          audioTracksAux[index].audio.play();
        }
      }
    });
    audioTracksRef.current = [...audioTracksAux];
  }, [isMuted, isPlaying]);

  const handleButtonClick = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const targetClassName = typeof target.className !== 'object' ? target.className : '';
    if ((target.closest('button') || target.closest('span') || targetClassName.includes('tabs')
        || targetClassName.includes('input')) && target.id !== 'playButton') {
      playSound('click', click);
    }
  }, [playSound]);

  useEffect(() => {
    document.addEventListener('click', handleButtonClick, true);
    return () => {
      document.removeEventListener('click', handleButtonClick, true);
    };
  }, [handleButtonClick]);

  const contextValue = useMemo(() => ({
    isPlaying, isMuted, playSound, stopSound, toggleMute,
  }), [isPlaying, isMuted, playSound, stopSound, toggleMute]);

  return (
    <SoundContext.Provider value={contextValue}>
      {children}
    </SoundContext.Provider>
  );
}
