import React, { useEffect } from 'react';
import {
  Col, Image, Row, Typography,
} from 'antd';
import styled from 'styled-components';
import CustomBackgroundModal, { ModalButton } from '../CustomBackgroundModal';
import modalBgNoWinners from '../../../../assets/modals/backgrounds/red.png';
import modalBgPortraitNoWinners from '../../../../assets/modals/backgrounds/red-mobile.png';
import modalBgFinal from '../../../../assets/modals/backgrounds/purple.png';
import modalBgPortraitFinal from '../../../../assets/modals/backgrounds/purple-mobile.png';
import modalBgDefault from '../../../../assets/modals/backgrounds/orange.png';
import modalBgPortraitDefault from '../../../../assets/modals/backgrounds/orange-mobile.png';
import titleNoWinners from '../../../../assets/modals/texts/ambos-pierden.png';
import titleDefault from '../../../../assets/modals/texts/perdiste.png';
import titleFinal from '../../../../assets/modals/texts/ups-casi.png';
import { MatchStatus, Tournament, TournamentParticipation } from '../../../../types/base';

const { Title } = Typography;

const TitleImage = styled(Image)`
  width: 60%;

  @media only screen and (orientation: portrait) {
    width: 100%;
  }
`;

const TitleFinalImage = styled(Image)`
  width: 40%;

  @media only screen and (orientation: portrait) {
    width: 80%;
  }
`;

const TitleDefaultImage = styled(Image)`
  width: 40%;

  @media only screen and (orientation: portrait) {
    width: 80%;
  }
`;

type PPTNoWinnersModalProps = {
  action: () => void;
};

function PPTNoWinnersModal({ action }: PPTNoWinnersModalProps) {
  return (
    <CustomBackgroundModal
      onClose={action}
      backgroundImage={modalBgNoWinners}
      backgroundImagePortrait={modalBgPortraitNoWinners}
    >
      <Row justify="center" gutter={[0, { xs: 24, md: 8 }]}>
        <Col span={24}>
          <TitleImage
            preview={false}
            src={titleNoWinners}
          />
        </Col>
        <Col
          span={24}
          style={{
            maxWidth: 420,
          }}
        >
          <Title
            level={3}
            style={{
              color: 'white',
            }}
          >
            EMPATARON LA CANTIDAD MÁXIMA POSIBLE.
          </Title>
        </Col>
        <Col span={24}>
          <ModalButton
            type="primary"
            shape="round"
            size="large"
            backgroundColor="#f5ee30"
            color="#83301a"
            paddingHorizontal={20}
            onClick={action}
          >
            JUGAR SIGUIENTE TORNEO
          </ModalButton>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}

type PPTLoserFinalModalProps = {
  action: () => void;
};

function PPTLoserFinalModal({ action }: PPTLoserFinalModalProps) {
  return (
    <CustomBackgroundModal
      onClose={action}
      backgroundImage={modalBgFinal}
      backgroundImagePortrait={modalBgPortraitFinal}
    >
      <Row justify="center" gutter={[0, { xs: 24, md: 16 }]}>
        <Col span={24}>
          <TitleFinalImage
            preview={false}
            src={titleFinal}
          />
        </Col>
        <Col span={24}>
          <Title level={3} style={{ color: 'white' }}>ESTUVISTE DEMASIADO CERCA,</Title>
          <Title level={3} style={{ color: 'white' }}>DALE CON OTRA!</Title>
        </Col>
        <Col span={24}>
          <ModalButton
            type="primary"
            shape="round"
            size="large"
            backgroundColor="#f5ee30"
            color="#83301a"
            paddingHorizontal={20}
            onClick={action}
          >
            JUGAR DE NUEVO
          </ModalButton>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}

type PPTLoserDefaultModalProps = {
  action: () => void;
};

function PPTLoserDefaultModal({ action }: PPTLoserDefaultModalProps) {
  return (
    <CustomBackgroundModal
      onClose={action}
      backgroundImage={modalBgDefault}
      backgroundImagePortrait={modalBgPortraitDefault}
    >
      <Row justify="center" gutter={[0, { xs: 24, md: 8 }]}>
        <Col span={24}>
          <TitleDefaultImage
            preview={false}
            src={titleDefault}
          />
        </Col>
        <Col span={24}>
          <Title level={3}>INTÉNTALO DE NUEVO,</Title>
          <Title level={3}>¡TU PUEDES!</Title>
        </Col>
        <Col span={24}>
          <ModalButton
            type="primary"
            shape="round"
            size="large"
            backgroundColor="#f5ee30"
            color="#83301a"
            paddingHorizontal={20}
            onClick={action}
          >
            JUGAR DE NUEVO
          </ModalButton>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}

type PPTLoserModalProps = {
  tournament: Tournament;
  userParticipation: TournamentParticipation;
  action: () => void;
};

export default function PPTLoserModal({ ...props }: PPTLoserModalProps) {
  const { tournament, userParticipation, action } = props;

  useEffect(() => {
    // Automatically perform action after 30 seconds
    const timerId = setTimeout(() => {
      action();
    }, 30000);
    return () => clearTimeout(timerId);
  }, []);

  if (userParticipation.properties.latest_match_status === MatchStatus.NO_WINNERS) {
    return (
      <PPTNoWinnersModal {...props} />
    );
  }

  return userParticipation.properties.latest_round === tournament.rounds ? (
    <PPTLoserFinalModal {...props} />
  ) : (
    <PPTLoserDefaultModal {...props} />
  );
}
