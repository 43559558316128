import React, { useEffect, useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'antd';
import styled from 'styled-components';
import { CloseOutlined, LockFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import YouTube, { YouTubePlayer } from 'react-youtube';
import redBox from '../assets/red-box.png';
import rockPaperScissors from '../assets/rock-paper-scissors.png';
import theNumber from '../assets/the-number.png';
import { GameCode } from '../types/base';
import { menuHeight } from '../constants/ui';

const PlayButton = styled(Button)`
  background-color: transparent;
  background-size: 280px 110px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url(${redBox});
  width: 280px;
  height: 110px;
  border: 0;
  font-family: "MisuseDisplay", serif;
  font-size: 22px;

  :hover, :focus, :active {
    background-color: transparent;
    background-size: 280px 110px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(${redBox});
    animation: pulse 0.5s;
    color: var(--text-primary);

    @media only screen and (max-width : 991px) {
      background-size: 140px 55px;
    }
  }

  @media only screen and (max-width : 991px) {
    background-size: 140px 55px;
    width: 140px;
    height: 55px;
    font-size: 14px;
    line-height: 1;
  }
`;

interface GameSectionContainerProps {
  windowWidth: number
  windowHeight: number;
}

const GameSectionContainer = styled.div<GameSectionContainerProps>`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  width: calc(100vw / 2);
  height: calc(100vh - ${menuHeight}px);

  @supports (height: 100svh) {
    height: calc(100svh - ${menuHeight}px);
  }

  @media only screen and (orientation: portrait){
    width: ${(props) => props.windowWidth}px;
    height: ${(props) => (props.windowHeight - menuHeight) / 2}px;
  }
`;

const GameButtons = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 280px;

  @media only screen and (max-width: 991px) {
    width: 140px;
    bottom: 20px;
  }
`;

interface LockScreenProps {
  windowWidth: number
  windowHeight: number;
}

const LockScreen = styled.div<LockScreenProps>`
  position: absolute;
  background: #00000038;

  @media only screen and (orientation: landscape) {
    width: calc(${(props) => props.windowWidth / 2}px);
    height: calc(${(props) => props.windowHeight - menuHeight}px);
  }

  @media only screen and (orientation: portrait) {
    width: ${(props) => props.windowWidth}px;
    height: ${(props) => (props.windowHeight - menuHeight) / 2}px;
  }
`;

const Lock = styled(LockFilled)`
  color: var(--background-primary);
  font-size: 150px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  
  @media only screen and (orientation: landscape) {
    bottom: calc(50% - 150px);
    width: 150px;
    height: 150px;
  }

  @media only screen and (orientation: portrait) {
    font-size: 80px;
    width: 80px;
    height: 80px;
    bottom: calc(50% - 80px);
  }
`;

const GameSectionCol = styled(Col)`
  @media only screen and (orientation: landscape) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media only screen and (orientation: portrait) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

type GameSectionProps = {
  windowWidth: number;
  windowHeight: number;
  gameCode: GameCode;
  backgroundImage: string;
  isLoggedIn: boolean;
  showTutorial: () => void;
};

function GameSection({
  gameCode, backgroundImage, isLoggedIn, showTutorial, ...props
}: GameSectionProps) {
  const navigate = useNavigate();

  const goToSignIn = () => {
    navigate('/signin');
  };

  return (
    <GameSectionContainer style={{ backgroundImage: `url(${backgroundImage})` }} {...props}>
      <GameButtons>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <PlayButton onClick={() => {
              navigate(`/games/${gameCode}`);
            }}
            >
              Jugar
            </PlayButton>
          </Col>
          <Col span={24}>
            <PlayButton onClick={() => {
              showTutorial();
            }}
            >
              Como
              <br />
              jugar
            </PlayButton>
          </Col>
        </Row>
      </GameButtons>
      {!isLoggedIn && (
        <LockScreen onClick={goToSignIn} {...props}>
          <Lock />
        </LockScreen>
      )}
      <GameButtons>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <PlayButton onClick={() => {
              showTutorial();
            }}
            >
              Como
              <br />
              jugar
            </PlayButton>
          </Col>
        </Row>
      </GameButtons>
    </GameSectionContainer>
  );
}

type HomeState = {
  next?: string;
};

type HomeProps = {
  windowWidth: number;
  windowHeight: number;
  isLoggedIn?: boolean;
};

export default function Home({ isLoggedIn = true, ...props }: HomeProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [showPPTTutorial, setShowPPTTutorial] = useState(false);
  const [showNumberTutorial, setShowNumberTutorial] = useState(false);
  const [pptVideo, setPPTVideo] = useState<YouTubePlayer>(null);
  const [theNumberVideo, setTheNumberVideo] = useState<YouTubePlayer>(null);

  useEffect(() => {
    const state = location.state as HomeState;
    if (state?.next) {
      navigate(state.next, { replace: true });
    }
  }, [location.state]);

  return (
    <div>
      <Row justify="center">
        <GameSectionCol>
          <GameSection
            gameCode="PPT"
            backgroundImage={rockPaperScissors}
            isLoggedIn={isLoggedIn}
            showTutorial={() => setShowPPTTutorial(true)}
            {...props}
          />
        </GameSectionCol>
        <GameSectionCol>
          <GameSection
            gameCode="NUMBER"
            backgroundImage={theNumber}
            showTutorial={() => setShowNumberTutorial(true)}
            isLoggedIn={isLoggedIn}
            {...props}
          />
        </GameSectionCol>
      </Row>
      <Modal
        centered
        open={showPPTTutorial}
        onCancel={() => {
          setShowPPTTutorial(false);
          pptVideo?.stopVideo();
        }}
        footer={null}
        width={315}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        bodyStyle={{ backgroundColor: 'transparent', padding: 0, height: 560 }}
      >
        <YouTube
          videoId="7rgKN9xpE8Q"
          title="Cómo jugar The Number"
          opts={{
            width: 315,
            height: 560,
          }}
          onReady={(event) => {
            setPPTVideo(event.target);
          }}
        />
      </Modal>

      <Modal
        centered
        open={showNumberTutorial}
        onCancel={() => {
          setShowNumberTutorial(false);
          theNumberVideo?.stopVideo();
        }}
        footer={null}
        width={315}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        bodyStyle={{ backgroundColor: 'transparent', padding: 0, height: 560 }}
      >
        <YouTube
          videoId="oZTzDlPqrb0"
          title="Cómo jugar Piedra, Papel o Tijera"
          opts={{
            width: 315,
            height: 560,
          }}
          onReady={(event) => {
            setTheNumberVideo(event.target);
          }}
        />
      </Modal>
    </div>
  );
}
