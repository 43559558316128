import React, { useEffect } from 'react';
import { message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import Loading from '../../components/Loading';
import { Token } from '../../types/base';
import { noAuthAxios } from '../../utils/axios-instance';
import { useAuthContext } from '../../AuthProvider';
import { subscribeUser } from '../../utils/push-notifications';

function ActivateAccount() {
  const { setToken } = useAuthContext();
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const onActivateAccount = (userToken: Token) => {
    if (setToken) {
      setToken(userToken);
    }
    message.success('Se activó tu cuenta');
    navigate('/');
  };

  useEffect(() => {
    if (uid && token) {
      noAuthAxios.post(
        `${process.env.REACT_APP_API_URL}/users/activation/`,
        { uid, token },
      ).then((response) => {
        const userToken: Token = response.data;
        subscribeUser().then(() => {
          onActivateAccount(userToken);
        }).catch(() => {
          onActivateAccount(userToken);
        });
      }).catch((e) => {
        if (e.response.status === 403) {
          navigate('/');
        } else if (e.response.status === 400) {
          message.error('Enlace inválido');
        }
        navigate('/signin');
      });
    }
  }, []);

  return (
    <Loading />
  );
}

export default ActivateAccount;
