import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import _ from 'lodash';

export const defaultPagination = {
  current: 1,
  pageSize: 5,
};

export const defaultParams = {
  limit: 5,
  offset: 0,
};

interface FetchResultsParams {
  limit?: number;
  offset?: number;
  ordering?: string;
  search?: string;
}

export function handleTableChange<T>(
  newPagination: TablePaginationConfig,
  filters: Record<string, FilterValue | null>,
  sorter: (SorterResult<T> | SorterResult<T>[]),
  fetchResults: (pagination: TablePaginationConfig, params: FetchResultsParams) => void,
) {
  const pageSize = newPagination.pageSize || defaultPagination.pageSize;
  const current = newPagination.current || defaultPagination.current;

  const sortField = Array.isArray(sorter) ? sorter[0].columnKey : sorter.columnKey;
  const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
  const ordering = sortOrder ? `${sortOrder === 'descend' ? '-' : ''}${sortField}` : undefined;

  const filterParams = filters ? _.fromPairs(
    Object.keys(filters).filter(
      (value: string) => filters[value] !== null,
    ).map(
      (value: string) => [`${value}__in`, filters[value]!.join(',')],
    ),
  ) : {};

  fetchResults(newPagination, {
    limit: pageSize,
    offset: (current - 1) * pageSize,
    ordering,
    ...filterParams,
  });
}

export function renderAmount(value: number) {
  const absoluteValue = Math.abs(value);
  const sign = value >= 0 ? '+' : '-';
  return `${sign}$${absoluteValue}`;
}

export function renderNumericalValue(value: number) {
  return Number(value).toLocaleString('es-CL', { maximumFractionDigits: 2 });
}
