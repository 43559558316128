import { message, Upload } from 'antd';
import { RcFile } from 'rc-upload/lib/interface';

const acceptedSizeMB = 2;

export default function validateFileSize(file: RcFile) {
  const isAcceptedSize = file.size / 1024 / 1024 < acceptedSizeMB;
  if (!isAcceptedSize) {
    message.error(`El archivo debe pesar menos de ${acceptedSizeMB}MB`);
  }
  return isAcceptedSize ? true : Upload.LIST_IGNORE;
}
