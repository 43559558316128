import React, { useState } from 'react';
import { Col, Row, Typography } from 'antd';
import CustomBackgroundModal from '../CustomBackgroundModal';
import modalBackground from '../../../../assets/modals/backgrounds/orange.png';
import modalBackgroundPortrait from '../../../../assets/modals/backgrounds/orange-mobile.png';

const { Title } = Typography;

type PPTMatchModalProps = {
  remainingAttempts: number;
};

export default function PPTMatchDrawModal({ remainingAttempts }: PPTMatchModalProps) {
  const [open, setOpen] = useState<boolean>(true);

  return (
    <CustomBackgroundModal
      backgroundImage={modalBackground}
      backgroundImagePortrait={modalBackgroundPortrait}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Row justify="center" gutter={[0, { xs: 24, md: 8 }]}>
        <Col span={24}>
          <Title
            level={3}
            style={{
              color: 'white',
            }}
          >
            LES QUEDAN
            {' '}
            {remainingAttempts}
            {' '}
            EMPATES
          </Title>
        </Col>
      </Row>
    </CustomBackgroundModal>
  );
}
