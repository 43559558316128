import Lottie from 'react-lottie';
import {
  Col, Row, Typography,
} from 'antd';
import React from 'react';
import loadingAnimation from '../../../assets/animations/loading.json';
import { SmallBackgroundModal } from './CustomBackgroundModal';
import background from '../../../assets/modals/backgrounds/small-yellow.png';

const { Title } = Typography;

interface WaitingForPlayersProps {
  numberOfPlayers: number;
  requiredPlayers: number;
  onClose: () => void;
}

export default function WaitingForPlayersModal({
  numberOfPlayers, requiredPlayers, onClose,
}: WaitingForPlayersProps) {
  return (
    <SmallBackgroundModal
      onClose={onClose}
      backgroundImage={background}
      style={{
        fontFamily: 'Pasti, serif',
        fontWeight: 'bold',
      }}
    >
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col span={24} style={{ height: '80%' }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: loadingAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            isClickToPauseDisabled
            style={{
              position: 'absolute',
              width: '120%',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
              overflow: 'visible',
            }}
          />
          <div style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 18,
            margin: 'auto',
            fontFamily: 'Montserrat',
          }}
          >
            {numberOfPlayers}
            /
            {requiredPlayers}
          </div>
        </Col>
        <Col
          span={24}
          style={{
            height: '40%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Title level={5}>
            ESPERANDO QUE SE COMPLETEN LOS JUGADORES
          </Title>
        </Col>
      </Row>
    </SmallBackgroundModal>
  );
}
