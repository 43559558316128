import React, { useState } from 'react';
import {
  Form, Input, Button, Typography, Select, message,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FormContainer } from './auth/SignUp';
import { noAuthAxios } from '../utils/axios-instance';
import { useAuthContext } from '../AuthProvider';

const { Paragraph, Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

type ContactFormValues = {
  name?: string;
  email: string;
  content_type: string;
  content: string;
};

function ContactUs() {
  const [saving, setSaving] = useState<boolean>(false);
  const [signUpComplete, setSignUpComplete] = useState<boolean>(false);
  const [reportUser, setReportUser] = useState<boolean>(false);

  const auth = useAuthContext();

  let initialValues = {};
  if (auth.user) {
    initialValues = {
      name: auth.user.full_name,
      email: auth.user.email,
    };
  }
  const onSelectContact = (value: string) => {
    if (value === 'reportar') {
      setReportUser(true);
    } else {
      setReportUser(false);
    }
  };
  const onFinish = (values: ContactFormValues) => {
    setSaving(true);
    noAuthAxios.post(
      `${process.env.REACT_APP_API_URL}/users/contact_form/`,
      values,
    ).then(() => {
      setSignUpComplete(true);
      setSaving(false);
    }).catch(() => {
      message.error('Ocurrió un error. Pruebe nuevamente por favor');
      setSaving(false);
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <FormContainer style={{ width: '700px' }}>
        <Title level={4} style={{ paddingBottom: 15 }}>Formulario de contacto</Title>
        {signUpComplete ? (
          <>
            <Paragraph type="secondary">
              ¡Gracias! Hemos recibido su sugerencia.
            </Paragraph>
            <Button
              type="primary"
              onClick={() => setSignUpComplete(false)}
            >
              Enviar otra sugerencia o reclamo
            </Button>
          </>
        ) : (
          <Form
            name="basic"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Nombre / Apellido"
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Correo electrónico"
              name="email"
              rules={[{ required: true, message: 'Por favor ingresa un correo' }]}
            >
              <Input autoCapitalize="off" type="email" />
            </Form.Item>

            <Form.Item label="Tipo de contacto" name="content_type" rules={[{ required: true }]}>
              <Select
                placeholder="Seleccione una opción"
                onSelect={onSelectContact}
                onClear={() => setReportUser(false)}
                style={{ textAlign: 'left' }}
                allowClear
              >
                <Option value="sugerencia">Sugerencia</Option>
                <Option value="reclamo">Reclamo</Option>
                <Option value="reportar">Reportar jugador</Option>
              </Select>
            </Form.Item>
            { reportUser && (
              <Form.Item
                label="Nombre de usuario del jugador a reportar"
                name="reported_user"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            )}

            <Form.Item label="Mensaje" name="content" rules={[{ required: true }]}>
              <TextArea rows={6} />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
              >
                {saving ? (
                  <LoadingOutlined />
                ) : 'ENVIAR'}
              </Button>
            </Form.Item>
          </Form>
        )}
      </FormContainer>
    </div>
  );
}

ContactUs.defaultProps = {
  email: '',
  full_name: '',
};

export default ContactUs;
