import React, { useEffect, useRef } from 'react';
import { Avatar, Image } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import avatarUnknown from '../../assets/avatars/avatar-unknown.png';
import { DefaultAvatarImage, getDefaultAvatar } from '../../pages/account/Profile';
import {
  Match, MatchParticipation, RoundType, Tournament,
} from '../../types/base';
import useWindowSize from '../../hooks/useWindowSize';

interface ParticipantContainerProps {
  isFinal?: boolean;
}
const ParticipantContainer = styled.div<ParticipantContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.isFinal ? '100%' : 'calc(100% / 2)')};
  width: ${(props) => (props.isFinal ? 'calc(100% / 2)' : '100%')};
  top: 50%;
  pointer-events: none;
`;

type ParticipantProps = {
  matchParticipation?: MatchParticipation;
  numberOfParticipants: number;
  isFinal?: boolean
};

function Participant({
  matchParticipation, numberOfParticipants, isFinal = false,
}: ParticipantProps) {
  let multiplier = 1.0;
  if (numberOfParticipants < 16) {
    multiplier = 2;
  } else if (numberOfParticipants < 32) {
    multiplier = 0.75;
  }
  return (
    <ParticipantContainer isFinal={isFinal}>
      <Avatar
        src={matchParticipation?.user.profile_photo_url ? (
          <Image
            preview={false}
            src={matchParticipation.user.profile_photo_url}
          />
        ) : (
          <DefaultAvatarImage
            src={
              matchParticipation?.user
                ? getDefaultAvatar(matchParticipation.user!.id)
                : avatarUnknown
            }
          />
        )}
        size={{
          md: 30 * multiplier, lg: 30 * multiplier, xl: 40 * multiplier, xxl: 50 * multiplier,
        }}
        icon={<UserOutlined style={{ color: 'var(--text-secondary)' }} />}
        style={{ backgroundColor: 'var(--primary-4)' }}
      />
    </ParticipantContainer>
  );
}

interface MatchContainerProps {
  matchesInRound: number;
}

const MatchContainer = styled.article<MatchContainerProps>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100% / ${(props) => props.matchesInRound})
`;

type BracketMatchProps = {
  match?: Match;
  matchesInRound: number;
  numberOfParticipants: number;
};

function BracketMatch({ match, matchesInRound, numberOfParticipants }: BracketMatchProps) {
  return (
    <MatchContainer matchesInRound={matchesInRound}>
      <Participant
        matchParticipation={match?.match_participations[0]}
        numberOfParticipants={numberOfParticipants}
      />
      <Participant
        matchParticipation={match?.match_participations[1]}
        numberOfParticipants={numberOfParticipants}
      />
    </MatchContainer>
  );
}

interface RoundContainerProps {
  totalRounds: number;
  side?: 'left' | 'right';
  isFinal?: boolean;
}

const RoundContainer = styled.section<RoundContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 0 20px;
  float: ${(props) => props.side || 'left'};
  width: calc(100% / ${(props) => props.totalRounds});
  position: relative;

  ${(props) => props.isFinal && `
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  `}
`;

type RoundProps = {
  round: RoundType;
  side: 'left' | 'right';
  totalRounds: number;
  numberOfParticipants: number;
};

function Round({
  round, side, totalRounds, numberOfParticipants,
}: RoundProps) {
  const matchesInRound = round.matches_in_round;
  return (
    <RoundContainer totalRounds={totalRounds} side={side}>
      {Array.from(Array(matchesInRound).keys()).map((matchIndex) => (
        <BracketMatch
          key={`${round.round_number}-${matchIndex}-${side}`}
          match={round.matches[matchIndex]}
          matchesInRound={matchesInRound}
          numberOfParticipants={numberOfParticipants}
        />
      ))}
    </RoundContainer>
  );
}

const FinalMatchContainer = styled(MatchContainer)`
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

type FinalRoundProps = {
  match?: Match | null;
  totalRounds: number;
  numberOfParticipants: number;
};

function FinalRound({ match, totalRounds, numberOfParticipants }: FinalRoundProps) {
  return (
    <RoundContainer totalRounds={totalRounds} isFinal className="final">
      <FinalMatchContainer matchesInRound={1}>
        <Participant
          matchParticipation={match?.match_participations[0]}
          numberOfParticipants={numberOfParticipants}
          isFinal
        />
        <Participant
          matchParticipation={match?.match_participations[1]}
          numberOfParticipants={numberOfParticipants}
          isFinal
        />
      </FinalMatchContainer>
    </RoundContainer>
  );
}

type RegionProps = {
  rounds: RoundType[];
  side: 'right' | 'left';
  totalRounds: number;
  numberOfParticipants: number;
};

function Region({ rounds, side, ...props }: RegionProps) {
  const roundNumbers = rounds.map((round) => round.round_number);

  return (
    <div className={side === 'right' ? 'region region-right' : 'region'}>
      {roundNumbers.map((roundNumber) => (
        <Round
          key={`${side}-${roundNumber}`}
          round={rounds.find((round) => round.round_number === roundNumber)!}
          side={side}
          {...props}
        />
      ))}
    </div>
  );
}

type TournamentBracketProps = {
  tournament: Tournament;
};

type ElementPosition = {
  x: number;
  y: number;
};

export function TournamentBracket({ tournament }: TournamentBracketProps) {
  const { windowWidth, windowHeight } = useWindowSize();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const tournamentRef = useRef<HTMLDivElement>(null);

  function calculatePosition(element: Element): ElementPosition {
    const parentClientRect = tournamentRef.current!.getBoundingClientRect();
    const elementClientRect = element.getBoundingClientRect();

    return {
      x: elementClientRect.left - parentClientRect.left + elementClientRect.width / 2,
      y: elementClientRect.top - parentClientRect.top + elementClientRect.height / 2,
    };
  }

  useEffect(() => {
    if (canvasRef.current && tournamentRef.current) {
      const canvas = canvasRef.current;
      canvas.width = tournamentRef.current.clientWidth;
      canvas.height = tournamentRef.current.clientHeight;
      const ctx = canvas.getContext('2d')!;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const finalMatch = tournamentRef.current.querySelector('section.final');

      if (finalMatch) {
        const firstParticipant = calculatePosition(
          finalMatch!.getElementsByTagName('span').item(0)!,
        );
        const secondParticipant = calculatePosition(
          finalMatch!.getElementsByTagName('span').item(1)!,
        );
        ctx.beginPath();
        ctx.moveTo(firstParticipant.x, firstParticipant.y);
        ctx.lineTo(secondParticipant.x, secondParticipant.y);
        ctx.stroke();
      }

      tournamentRef.current.querySelectorAll('.region').forEach((region) => {
        const side = region.classList.contains('region-right') ? 'right' : 'left';
        region.querySelectorAll('section').forEach((round) => {
          const nextMatches = round.nextElementSibling?.getElementsByTagName('article');
          round.querySelectorAll('article').forEach((match, matchIndex) => {
            const firstStart = calculatePosition(match.getElementsByTagName('span').item(0)!);
            const secondStart = calculatePosition(match.getElementsByTagName('span').item(1)!);

            let end;
            if (nextMatches && nextMatches.length) {
              const nextMatch = nextMatches.item(Math.floor(matchIndex / 2))!;
              const sectionIndex = matchIndex % 2;
              if (nextMatch.getElementsByTagName('span').item(sectionIndex)) {
                end = calculatePosition(nextMatch.getElementsByTagName('span').item(sectionIndex)!);
              }
            } else if (finalMatch) {
              end = calculatePosition(
                finalMatch!.getElementsByTagName('span').item(side === 'left' ? 0 : 1)!,
              );
            }

            if (end) {
              const midPointX = (firstStart.x + end.x) / 2;

              ctx.beginPath();
              ctx.moveTo(firstStart.x, firstStart.y);
              ctx.lineTo(midPointX, firstStart.y);
              ctx.lineTo(midPointX, secondStart.y);
              ctx.lineTo(secondStart.x, secondStart.y);
              ctx.stroke();

              ctx.beginPath();
              ctx.moveTo(midPointX, end.y);
              ctx.lineTo(end.x, end.y);
              ctx.stroke();
            }
          });
        });
      });
    }
  }, [tournament, canvasRef, windowWidth, windowHeight]);

  return (
    <div ref={tournamentRef} className="tournament">
      <canvas className="bracket-canvas" ref={canvasRef} />

      <Region
        rounds={tournament.bracket.left}
        side="left"
        totalRounds={tournament.rounds}
        numberOfParticipants={tournament.number_of_participants}
      />

      <FinalRound
        match={tournament.bracket.final}
        totalRounds={tournament.rounds}
        numberOfParticipants={tournament.number_of_participants}
      />

      <Region
        rounds={tournament.bracket.right}
        side="right"
        totalRounds={tournament.rounds}
        numberOfParticipants={tournament.number_of_participants}
      />
    </div>
  );
}
